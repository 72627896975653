import { MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGauge,
  faUsers,
  faCheckToSlot,
  faMobileButton,
  faFileExcel,
  faCalendarCheck,
  faListCheck,
  faBook,
  faCalendarAlt,
  faCalendarDay,
  faClock,
  faGraduationCap,
  faCalendarWeek,
  faPeopleGroup,
} from '@fortawesome/free-solid-svg-icons';
import {
  globalPaths,
  devicesPaths,
  genericPaths,
  incidencePaths,
  kardexPaths,
  reportPaths,
  usersPaths,
} from './routePaths';
import { AdminRole, EmployeeRole, SupportRole, UserRoles } from './enums/roles';
import Contract from 'types/contract';

export const DashboardItem = {
  label: <Link to={globalPaths.basePath}>Dashboard</Link>,
  key: globalPaths.basePath,
  icon: <FontAwesomeIcon icon={faGauge} />,
};

export const EmployeesItem = {
  label: <Link to={usersPaths.basePath}>Empleados</Link>,
  key: usersPaths.basePath,
  icon: <FontAwesomeIcon icon={faUsers} />,
};

export const ReportsItem = {
  label: 'Reportes',
  key: reportPaths.basePath,
  icon: <FontAwesomeIcon icon={faFileExcel} />,
  children: [
    {
      label: (
        <Link to={`${reportPaths.basePath}/${reportPaths.checks}`}>
          Chequeos
        </Link>
      ),
      key: `${reportPaths.basePath}/${reportPaths.checks}`,
      icon: <FontAwesomeIcon icon={faCheckToSlot} />,
    },
    {
      label: (
        <Link to={`${reportPaths.basePath}/${reportPaths.events}`}>
          Eventos
        </Link>
      ),
      key: `${reportPaths.basePath}/${reportPaths.events}`,
      icon: <FontAwesomeIcon icon={faCalendarCheck} />,
    },
    {
      label: (
        <Link to={`${reportPaths.basePath}/${reportPaths.incidences}`}>
          Incidencias
        </Link>
      ),
      key: `${reportPaths.basePath}/${reportPaths.incidences}`,
      icon: <FontAwesomeIcon icon={faListCheck} />,
    },
  ],
};

export const subMenuKeys = ['chequeos', 'eventos', 'incidencias']; // solo para keys de sub menú como las de reportes

export const IncidencesCatalogueItem = {
  label: (
    <Link to={`${incidencePaths.basePath}/${incidencePaths.catalogue}`}>
      Catálogo de incidencias
    </Link>
  ),
  key: `${incidencePaths.basePath}/${incidencePaths.catalogue}`,
  icon: <FontAwesomeIcon icon={faBook} />,
};

export const DevicesItem = {
  label: <Link to={devicesPaths.basePath}>Dispositivos</Link>,
  key: devicesPaths.basePath,
  icon: <FontAwesomeIcon icon={faMobileButton} />,
};

export const SelfKardexItem = {
  label: <Link to={`${kardexPaths.base}/${kardexPaths.self}`}>Mi kardex</Link>,
  key: `${kardexPaths.base}/${kardexPaths.self}`,
  icon: <FontAwesomeIcon icon={faCalendarAlt} />,
};

export const SelfChecksItem = {
  label: <Link to={`/${genericPaths.employeeSelfChecks}`}>Mis chequeos</Link>,
  key: `/${genericPaths.employeeSelfChecks}`,
  icon: <FontAwesomeIcon icon={faCheckToSlot} />,
};

export const GlobalHolidaysList = {
  label: <Link to={`/${globalPaths.holidaysList}`}>Días festivos</Link>,
  key: `/${globalPaths.holidaysList}`,
  icon: <FontAwesomeIcon icon={faCalendarAlt} />,
};

export const HolidaysItem = {
  label: 'Días festivos',
  key: globalPaths.holidaysList,
  icon: <FontAwesomeIcon icon={faCalendarAlt} />,
  children: [
    {
      label: (
        <Link to={`/${globalPaths.holidaysList}`}>Lista de días festivos</Link>
      ),
      key: `/${globalPaths.holidaysList}`,
      icon: <FontAwesomeIcon icon={faCalendarDay} />,
    },
    {
      label: (
        <Link to={`/${globalPaths.holidays}`}>Catálogo de días festivos</Link>
      ),
      key: `/${globalPaths.holidays}`,
      icon: <FontAwesomeIcon icon={faBook} />,
    },
  ],
};

export const ScheduleItem = {
  label: 'Horarios',
  key: 'schedule',
  icon: <FontAwesomeIcon icon={faClock} />,
  children: [
    {
      label: <Link to={`/${globalPaths.careers}`}>Carreras</Link>,
      key: 'careers',
      icon: <FontAwesomeIcon icon={faGraduationCap} />,
    },
    {
      label: <Link to={`/${globalPaths.cycles}`}>Ciclos</Link>,
      key: 'cycles',
      icon: <FontAwesomeIcon icon={faCalendarWeek} />,
    },
    {
      label: <Link to={`/${globalPaths.groups}`}>Grupos</Link>,
      key: 'groups',
      icon: <FontAwesomeIcon icon={faPeopleGroup} />,
    },
    {
      label: <Link to={`/${globalPaths.subjects}`}>Materias</Link>,
      key: 'subjects',
      icon: <FontAwesomeIcon icon={faCalendarWeek} />,
    },
  ],
};

export const AdminRoleMenuItems: MenuProps['items'] = [
  DashboardItem,
  EmployeesItem,
  ReportsItem,
  IncidencesCatalogueItem,
  DevicesItem,
  SelfKardexItem,
  HolidaysItem,
];

export const SupportRoleMenuItems: MenuProps['items'] = [
  DevicesItem,
  SelfKardexItem,
  GlobalHolidaysList,
];

export const EmployeeRoleMenuItems: MenuProps['items'] = [
  SelfKardexItem,
  GlobalHolidaysList,
];

export const getMenuItems = (
  userRole: UserRoles,
  contracts: Contract[],
  isSchedulesModuleEnabled: string
) => {
  const hasHourlyContracts = contracts.some(
    (contract) => contract.workDayKind === 'por horas'
  );
  const isScheduleItemEnabled = JSON.parse(isSchedulesModuleEnabled);
  switch (userRole) {
    case AdminRole:
      if (isScheduleItemEnabled) {
        return [...AdminRoleMenuItems, ScheduleItem];
      }
      return AdminRoleMenuItems;
    case SupportRole:
      return SupportRoleMenuItems;
    case EmployeeRole:
      if (hasHourlyContracts) {
        const menuItems = [...EmployeeRoleMenuItems, SelfChecksItem];
        return menuItems;
      }
      return EmployeeRoleMenuItems;
    default:
      return EmployeeRoleMenuItems;
  }
};
