import { Modal, Form, Select, TimePicker, Button, Row, Col } from 'antd';
import { DeleteFilled, PlusOutlined } from '@ant-design/icons';
import { GroupScheduleModalProps } from 'interfaces/schedule';
import { dataWeekDays } from 'constants/WeekFormat';

export const GroupScheduleModal = ({
  isModalOpen,
  modalTitle,
  userOptions,
  scheduleToEdit,
  form,
  handleSubmit,
  handleCancel,
}: GroupScheduleModalProps) => {
  return (
    <Modal
      title={modalTitle}
      open={isModalOpen}
      onOk={() => form.submit()}
      okText="Guardar horario"
      onCancel={handleCancel}
      width={600}
      data-cy="group-schedule-modal"
    >
      <Form onFinish={handleSubmit} form={form}>
        <Form.Item label="Docente" name="teacherId">
          <Select
            data-cy="select-teacher-field"
            placeholder="Selecciona el docente a asignar"
            options={userOptions}
            value={scheduleToEdit?.teacherId}
          />
        </Form.Item>
        <Form.List name="schedule">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Form.Item className="form-list" label="Día:" key={field.key}>
                  <Row justify="space-between">
                    <Col xs={22}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'dayOfWeek']}
                        noStyle
                        rules={[
                          {
                            required: true,
                            message:
                              'Por favor, selecciona el día de la semana',
                            type: 'number',
                          },
                        ]}
                        hasFeedback
                      >
                        <Select
                          placeholder="Selecciona un día"
                          data-cy="select-day-field"
                        >
                          {dataWeekDays.map((day) => (
                            <Select.Option
                              key={day.dayNumber}
                              value={day.dayNumber}
                            >
                              {day.dayName}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Row className="mt-3" gutter={10}>
                        <Col xs={12}>
                          <Form.Item
                            {...field}
                            className="mb-0"
                            label="Hora inicial:"
                            name={[field.name, 'initialHour']}
                            rules={[
                              {
                                required: true,
                                message: 'Por favor, ingresa la hora inicial',
                              },
                            ]}
                            hasFeedback
                          >
                            <TimePicker
                              data-cy="initial-hour-field"
                              format="HH:mm"
                              minuteStep={10}
                              placeholder="Selecciona la hora inicial"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={12}>
                          <Form.Item
                            {...field}
                            className="mb-0"
                            name={[field.name, 'endHour']}
                            label="Hora final:"
                            rules={[
                              {
                                required: true,
                                message: 'Por favor, ingresa la hora final',
                              },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  const initialHour = getFieldValue([
                                    'schedule',
                                    field.name,
                                    'initialHour',
                                  ]);
                                  if (
                                    !value ||
                                    !initialHour ||
                                    value.isAfter(initialHour)
                                  ) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error(
                                      'La hora final debe ser después de la hora inicial.'
                                    )
                                  );
                                },
                              }),
                            ]}
                            hasFeedback
                          >
                            <TimePicker
                              data-cy="end-hour-field"
                              format="HH:mm"
                              minuteStep={10}
                              placeholder="Selecciona la hora final"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={1}>
                      <DeleteFilled
                        data-cy="remove-schedule-day"
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                      />
                    </Col>
                  </Row>
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  data-cy="add-schedule-btn"
                  type="dashed"
                  className="add-btn"
                  onClick={() => add()}
                  icon={<PlusOutlined />}
                >
                  Añadir horario
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};
