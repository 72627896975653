import { FrontendConfig } from '../../interfaces/config';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getFrontendConfig } from 'api/config.api';
import { SliceStatus } from 'constants/enums/slices';
import { defaultFrontConfig } from 'constants/frontConfig';

export interface FrontConfigSlice {
  config: FrontendConfig;
  status: SliceStatus;
}

const initialState: FrontConfigSlice = {
  config: { ...defaultFrontConfig },
  status: SliceStatus.Empty,
};

export const doGetFrontConfig = createAsyncThunk(
  'get/frontConfig',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getFrontendConfig();
      return response.data;
    } catch (error) {
      return await rejectWithValue(error);
    }
  }
);

const configSlice = createSlice({
  name: 'frontConfig',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetFrontConfig.pending, (state) => {
      state.status = SliceStatus.Updating;
    });
    builder.addCase(doGetFrontConfig.fulfilled, (state, action) => {
      state.config = action.payload;
      state.status = SliceStatus.Fulfilled;
    });
  },
});

export default configSlice.reducer;
