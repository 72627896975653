import { httpApi } from 'api/http.api';
import Contract, { ContractKind, ContractResponse } from 'types/contract';
import { ResponseGeneral } from 'types';
import { NewScheduleInterval } from '../types/schedule';
import { AxiosResponse } from 'axios';

const controller = `contract`;

type UploadContractsProps = {
  idFile: string;
  startDate: Date;
  endDate?: Date;
  contractKind: string;
};

type InvalidEmployeeSchedule = NewScheduleInterval & {
  employeeId: string;
};

export type UploadContractsResponse = {
  msg: string;
  invalidEmployeeIds?: string[];
  invalidEmployeeSchedules?: InvalidEmployeeSchedule[];
};

export const createOne = (
  contract: Contract
): Promise<AxiosResponse<Contract>> =>
  httpApi.post<Contract>(`${controller}`, { ...contract }).then((res) => res);

export const updateOne = (
  contract: Contract
): Promise<AxiosResponse<ContractResponse>> =>
  httpApi
    .put<ContractResponse>(`${controller}/${contract._id}`, { ...contract })
    .then((res) => res);

export const getAll = (): Promise<AxiosResponse<Contract[]>> =>
  httpApi.get<Contract[]>(`${controller}`, {}).then((res) => res);

export const getOne = (id: string): Promise<AxiosResponse<Contract>> =>
  httpApi.get<Contract>(`${controller}/${id}`, {}).then((res) => res);

export const getEmployeeContracts = (
  employeeId: string
): Promise<AxiosResponse<Contract[]>> =>
  httpApi
    .get<Contract[]>(`${controller}/getEmployeeContracts/${employeeId}`, {})
    .then((res) => res);

export const deleteOne = (
  id: string
): Promise<AxiosResponse<ResponseGeneral>> =>
  httpApi.delete<ResponseGeneral>(`${controller}/${id}`, {}).then((res) => res);

export const uploadContracts = ({
  idFile,
  startDate,
  endDate,
  contractKind,
}: UploadContractsProps): Promise<AxiosResponse<UploadContractsResponse>> => {
  return httpApi
    .post<UploadContractsResponse>(`${controller}/upload/${idFile}`, {
      startDate,
      endDate: endDate ?? undefined,
      contractKind,
    })
    .then((res) => res);
};

// Contract kinds

export const getActiveContractKinds = (): Promise<
  AxiosResponse<ContractKind[]>
> =>
  httpApi
    .get<ContractKind[]>(`${controller}/getContractKinds?status=active`, {})
    .then((res) => res);
