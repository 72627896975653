import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { TableDeletePopOver } from 'components/TableDeletePopOver/TableDeletePopOver';
import { DrawerModeListColumnProps } from 'interfaces/global.interfaces';
import { dateForDisplay } from 'libs/dateFormaterPetition';
import { Cycle } from 'types/cycle';
import dayjs from 'dayjs';
import { TableEditButton } from 'components/TableEditButton/TableEditButton';

export const CycleListColumns = ({
  onDelete,
  form,
  onOpenEditDrawer,
  setItemToEditId,
}: DrawerModeListColumnProps) => {
  const openDrawer = (formValues: Cycle) => {
    setItemToEditId(formValues._id);
    const newFinalDate = dayjs(formValues.finalDate);
    const newInitialDate = dayjs(formValues.initialDate);
    const modifiedValues = {
      ...formValues,
      dates: [newInitialDate, newFinalDate],
    };
    form.setFieldsValue(modifiedValues);
    onOpenEditDrawer(true);
  };

  const columns: ColumnsType<Cycle> = [
    {
      title: 'Clave',
      dataIndex: 'code',
      key: 'code',
      width: 150,
    },
    {
      title: 'F. Inicio',
      dataIndex: 'initialDate',
      key: 'initialDate',
      render: (i: string) => dateForDisplay(i, true),
    },
    {
      title: 'F. Final',
      dataIndex: 'finalDate',
      key: 'finalDate',
      render: (i: string) => dateForDisplay(i, true),
    },
    {
      title: 'Estatus',
      dataIndex: 'status',
      key: 'status',
      render: (c: boolean) => (
        <>
          {c && <CheckCircleOutlined />}
          {!c && <CloseCircleOutlined />}
        </>
      ),
      width: 250,
    },
    {
      title: 'Opciones',
      align: 'center',
      width: 150,
      render: (_, record: Cycle) => {
        return (
          <div className="option-buttons">
            <TableEditButton
              dataCy={`edit-cycle-${record.code}`}
              onClick={() => {
                openDrawer(record);
              }}
            />
            <TableDeletePopOver
              id={record._id}
              deleteHandler={onDelete}
              dataCy={`delete-cycle-${record.code}`}
            />
          </div>
        );
      },
    },
  ];
  return columns;
};
