import { Holiday, HolidaysByMonth } from 'interfaces/holidays.interfaces';
import { initialHolidaysByMonthValues } from 'constants/holidays';

export const orderHolidaysByMonth = (holidays: Holiday[]) => {
  const orderedHolidays: HolidaysByMonth[] = JSON.parse(
    JSON.stringify(initialHolidaysByMonthValues)
  );
  holidays.forEach((holiday) => {
    const date = new Date(holiday.date as string);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    orderedHolidays.forEach((monthHolidays) => {
      if (month === monthHolidays.monthNumber) {
        monthHolidays.holidays.push({
          dayOfMonth: day,
          description: holiday.desc,
        });
      }
    });
  });
  return orderedHolidays;
};
