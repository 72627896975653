import { PageTitle } from 'components/common/PageTitle/PageTitle';
import { NewContract } from 'components/employees/Contracts/NewContract';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { User } from '../../../types';
import { useEffect, useState } from 'react';
import { doList as userDoList } from 'store/slices/userSlice';
import * as Sentry from '@sentry/react';
import { Alert, Col, Row, Skeleton } from 'antd';
import { SliceStatus } from 'constants/enums/slices';

const NewContractPage = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const { users, status: userStateStatus } = useAppSelector(
    (state) => state.user
  );
  const [employee, setEmployee] = useState<User | null>();

  useEffect(() => {
    if (userStateStatus === SliceStatus.Empty) {
      dispatch(userDoList());
    } else if (userStateStatus === SliceStatus.Fulfilled && params.id) {
      const currentUser: User | undefined = users
        .filter((c) => c && c._id === params.id)
        .at(0);
      if (currentUser) {
        setEmployee(currentUser);
      } else {
        Sentry.captureMessage(
          'Not able to find a employee to assign a contract'
        );
      }
    }
  }, [params, userStateStatus, dispatch, employee, users]);

  return (
    <>
      <PageTitle>{`Nuevo contrato`}</PageTitle>
      {userStateStatus === `updating` && (
        <Row>
          <Col xs={24}>
            <Skeleton active />
          </Col>
        </Row>
      )}
      {userStateStatus === `fulfilled` && employee === null && (
        <Alert type="error" message={`Error al cargar el empleado`} />
      )}
      {userStateStatus === `fulfilled` && employee && (
        <NewContract user={employee} />
      )}
    </>
  );
};

export default NewContractPage;
