import {
  Button,
  Col,
  Input,
  InputRef,
  Modal,
  Row,
  Space,
  Spin,
  Table,
  TableProps,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useRef, useState } from 'react';
import ModalReportContent from '../checks/ModalReportContent';
import dayjs from 'dayjs';
import { AllEventListType } from 'types/events';
import { dateForDisplay } from 'libs/dateFormaterPetition';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { getEventsList } from 'api/event.api';
import { getEventsReport } from 'api/reports.api';
import { FilterConfirmProps } from 'antd/es/table/interface';
import Highlighter from 'react-highlight-words';
import { ReportFilter } from '../elements/ReportFilter';
import { ReportFilters } from 'types/reports';
import {
  getEndOfDate,
  getStartEndOfMonth,
  getStartOfDate,
  getStartEndOfMonthDayjs,
} from 'hooks/getStartEndOfDate';
import { defaultContractType } from 'constants/ReportConstants';
import * as Sentry from '@sentry/react';
import { pageSize } from 'constants/global';
import { ApiError } from 'types';
import statusMessages from 'statusMessages';
import notificationHook from 'hooks/notificationHook';

type EventListProps = {
  events: AllEventListType[];
  pages: number;
};

type DataIndex = keyof AllEventListType;

const EventsList = ({ events, pages }: EventListProps) => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(null);
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(null);
  const [eventsData, setEventsData] = useState<AllEventListType[]>([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const [employee, setEmployee] = useState('');
  const [contractType, setContractType] = useState(defaultContractType);
  const { startOfMonth, endOfMonth } = getStartEndOfMonth();
  const { dayjsStartOfMonth, dayjsEndOfMonth } = getStartEndOfMonthDayjs();
  const [loadingTable, setLoadingTable] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const tableProps: TableProps<AllEventListType> = {
    bordered: true,
    tableLayout: 'fixed',
    size: 'small',
    pagination: {
      position: ['bottomCenter'],
      pageSize: pageSize,
      total: totalPages,
      onChange: (page) => {
        setCurrentPage(page);
      },
    },
    className: 'table-ps',
  };

  useEffect(() => {
    setEventsData(events);
    setTotalPages(pages);
  }, [events, pages]);

  useEffect(() => {
    setStartDate(dayjsStartOfMonth);
    setEndDate(dayjsEndOfMonth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilterOptions = useCallback(() => {
    const filterOptions: ReportFilters = {
      from: startDate ? getStartOfDate(startDate) : startOfMonth,
      to: endDate ? getEndOfDate(endDate) : endOfMonth,
      employee: employee !== '' ? employee : undefined,
      contractType: contractType !== '' ? contractType : undefined,
    };
    return filterOptions;
  }, [contractType, employee, endDate, endOfMonth, startDate, startOfMonth]);

  const search = useCallback(() => {
    setLoadingTable(true);
    const filterOptions = getFilterOptions();
    getEventsList(filterOptions, currentPage, pageSize).then((res) => {
      setEventsData(res.data.data);
      setTotalPages(res.data.total);
      setLoadingTable(false);
    });
  }, [getFilterOptions, currentPage]);

  useEffect(() => {
    search();
  }, [startDate, endDate, employee, contractType, search]);

  const updateEvents = () => {
    search();
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): ColumnType<AllEventListType> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Buscar ${dataIndex}`}
          value={selectedKeys[0] ? selectedKeys[0].toString() : ''}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reiniciar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      return String(record[dataIndex])
        .toLowerCase()
        .includes((value as string).toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns: ColumnsType<AllEventListType> = [
    {
      title: 'ID Empleado',
      key: 'idEmployee',
      dataIndex: 'idEmployee',
      render: (idEmployee) => <>{idEmployee ?? 'No Registrado'}</>,
      sorter: (a, b) => Number(a.idEmployee) - Number(b.idEmployee),
      sortDirections: ['descend', 'ascend'],
      ...getColumnSearchProps('idEmployee'),
    },
    {
      title: 'Apellido(s)',
      key: 'lastName',
      dataIndex: 'lastName',
      ...getColumnSearchProps('lastName'),
    },
    {
      title: 'Nombre(s)',
      key: 'name',
      dataIndex: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Tipo de contrato',
      key: 'contractType',
      dataIndex: 'contractType',
    },
    {
      title: 'Entrada',
      key: 'in',
      dataIndex: 'in',
      render: (inDate) => (inDate ? dateForDisplay(inDate) : ''),
      sorter: (a, b) =>
        new Date(a.inDate).getTime() - new Date(b.inDate).getTime(),
    },
    {
      title: 'Dispositivo Entrada',
      key: 'in_device',
      dataIndex: 'in_device',
      render: (outDevice) => (outDevice ? outDevice : 'No identificado'),
      ...getColumnSearchProps('inDevice'),
    },
    {
      title: 'Salida',
      key: 'out',
      dataIndex: 'out',
      render: (outDate) => (outDate ? dateForDisplay(outDate) : ''),
      sorter: (a, b) =>
        (a.outDate ? new Date(a.outDate).getTime() : 1) -
        (b.outDate ? new Date(b.outDate).getTime() : 2),
    },
    {
      title: 'Dispositivo Salida',
      key: 'out_device',
      dataIndex: 'out_device',
      render: (outDevice) => (outDevice ? outDevice : 'No identificado'),
      ...getColumnSearchProps('outDevice'),
    },
  ];

  const showModal = () => {
    setOpen(true);
  };

  const handleStartDateChange = (date: dayjs.Dayjs | null) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: dayjs.Dayjs | null) => {
    setEndDate(date);
  };

  const handleContractTypeChange = (value: string) => {
    setContractType(value);
  };

  const generateEventsReport = async () => {
    setConfirmLoading(true);
    try {
      const filterOptions = getFilterOptions();
      const data = await getEventsReport(filterOptions);
      setConfirmLoading(false);
      setOpen(false);
      const fileName = `Reporte_eventos_${filterOptions.contractType}_${filterOptions.from}_al_${filterOptions.to}.xlsx`;
      const archivoUrl = window.URL.createObjectURL(new Blob([data.data]));
      const link = document.createElement('a');
      link.href = archivoUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      const currentStatus = (error as ApiError).statusCode;
      setConfirmLoading(false);
      setOpen(false);
      notificationHook({
        type: 'error',
        message: statusMessages.report[
          currentStatus ? currentStatus : 500
        ] as string,
      });
      Sentry.captureException(error);
    }
  };

  const handleEmployeeChange = (employee: string) => {
    setEmployee(employee);
  };

  const resetFilters = () => {
    setStartDate(dayjsStartOfMonth);
    setEndDate(dayjsEndOfMonth);
    setEmployee('');
    setContractType(defaultContractType);
  };

  return (
    <>
      <Row>
        <Col xs={24}>
          <h2 className="title">Reporte de eventos</h2>
          <ReportFilter
            startDate={startDate}
            endDate={endDate}
            employee={employee}
            contractType={contractType}
            handleStartDateChange={handleStartDateChange}
            handleEndDateChange={handleEndDateChange}
            updateData={updateEvents}
            resetFilters={resetFilters}
            showModal={showModal}
            handleEmployeeChange={handleEmployeeChange}
            handleContractTypeChange={handleContractTypeChange}
          />
          <Spin spinning={loadingTable} tip="Cargando...">
            <Table
              {...tableProps}
              dataSource={eventsData}
              columns={columns}
              id="checksTable"
              rowKey={'_id'}
            />
          </Spin>
        </Col>
      </Row>
      <Modal
        title="Descarga de Reporte"
        open={open}
        onOk={generateEventsReport}
        okText="Descargar"
        cancelText="Cancelar"
        destroyOnClose
        confirmLoading={confirmLoading}
        onCancel={() => {
          setOpen(false);
        }}
      >
        <ModalReportContent
          startDate={startDate}
          endDate={endDate}
          employee={employee}
          contractType={contractType}
        />
      </Modal>
    </>
  );
};

export default EventsList;
