import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getAllCareers,
  newCareer,
  editCareer,
  removeCareer,
} from 'api/careers.api';
import { SliceStatus } from 'constants/enums/slices';
import { Career, UpdateCareerPayload } from 'types/careers';
import notificationHook from 'hooks/notificationHook';

export interface CareerSlice {
  careers: Career[];
  listCareerStatus: SliceStatus;
  careerStatus: SliceStatus;
}

const initialState: CareerSlice = {
  careers: [],
  listCareerStatus: SliceStatus.Empty,
  careerStatus: SliceStatus.Empty,
};

export const doListCareers = createAsyncThunk(
  'career/list',
  async (_, { rejectWithValue }) => {
    try {
      return await getAllCareers();
    } catch (error) {
      return await rejectWithValue(error);
    }
  }
);

export const doNewCareer = createAsyncThunk(
  'career/create',
  async (CareerCreatePayload: Career, { rejectWithValue }) => {
    try {
      await newCareer(CareerCreatePayload);
      return await getAllCareers();
    } catch (error) {
      return await rejectWithValue(error);
    }
  }
);

export const doEditCareer = createAsyncThunk(
  'career/edit',
  async (CareerEditPayload: UpdateCareerPayload, { rejectWithValue }) => {
    try {
      const response = await editCareer(CareerEditPayload);
      const usedGroupSpecialties = response.data.usedGroupSpecialties;
      const usedSubjectSpecialties = response.data.usedSubjectSpecialties;
      if (usedGroupSpecialties && usedGroupSpecialties.length > 0) {
        notificationHook({
          message:
            'Las siguientes especialidades no pueden eliminarse porque están relacionadas a un grupo:',
          description: usedGroupSpecialties.join(', '),
          type: 'warning',
        });
        throw {
          message: 'No se pueden eliminar especialidades asociadas a un grupo',
          statusCode: 400,
        };
      }
      if (usedSubjectSpecialties && usedSubjectSpecialties.length > 0) {
        notificationHook({
          message:
            'Las siguientes especialidades no pueden eliminarse porque están relacionadas a una materia:',
          description: usedSubjectSpecialties.join(', '),
          type: 'warning',
        });
        throw {
          message:
            'No se pueden eliminar especialidades asociadas a una materia',
          statusCode: 418,
        };
      }
      return await getAllCareers();
    } catch (error) {
      return await rejectWithValue(error);
    }
  }
);

export const doRemoveCareer = createAsyncThunk(
  'careers/remove',
  async (id: string, { rejectWithValue }) => {
    try {
      await removeCareer(id);
      return await getAllCareers();
    } catch (error: unknown) {
      return rejectWithValue(error);
    }
  }
);

const careersSlice = createSlice({
  name: 'careers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doListCareers.pending, (state) => {
      state.listCareerStatus = SliceStatus.Updating;
    });
    builder.addCase(doListCareers.fulfilled, (state, action) => {
      state.careers = action.payload;
      state.listCareerStatus = SliceStatus.Fulfilled;
    });
    builder.addCase(doListCareers.rejected, (state) => {
      state.listCareerStatus = SliceStatus.Error;
    });
    builder.addCase(doRemoveCareer.pending, (state) => {
      state.careerStatus = SliceStatus.Updating;
    });
    builder.addCase(doRemoveCareer.fulfilled, (state, action) => {
      state.careers = action.payload;
      state.careerStatus = SliceStatus.Fulfilled;
    });
    builder.addCase(doRemoveCareer.rejected, (state) => {
      state.careerStatus = SliceStatus.Error;
    });
    builder.addCase(doNewCareer.pending, (state) => {
      state.careerStatus = SliceStatus.Updating;
    });
    builder.addCase(doNewCareer.fulfilled, (state, action) => {
      state.careers = action.payload;
      state.careerStatus = SliceStatus.Fulfilled;
    });
    builder.addCase(doNewCareer.rejected, (state) => {
      state.careerStatus = SliceStatus.Error;
    });
    builder.addCase(doEditCareer.pending, (state) => {
      state.careerStatus = SliceStatus.Updating;
    });
    builder.addCase(doEditCareer.fulfilled, (state, action) => {
      state.careers = action.payload;
      state.careerStatus = SliceStatus.Fulfilled;
    });
    builder.addCase(doEditCareer.rejected, (state) => {
      state.careerStatus = SliceStatus.Error;
    });
  },
});

export default careersSlice.reducer;
