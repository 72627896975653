import 'styles/sass/spaces.scss';
import 'styles/sass/globals.scss';

import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import RequireAuth from './RequireAuth';

import DashboardPage from 'pages/dashboard/DashboardPage';
import ForgotPasswordPage from 'pages/auth/ForgotPasswordPage';
import LoginPage from 'pages/auth/LoginPage';
import LockPage from 'pages/auth/LockPage';
import NewPasswordPage from 'pages/auth/NewPasswordPage';
import ConfirmUserPage from 'pages/auth/ConfirmUserPage';
import LogOut from 'pages/auth/LogOut';
import UserListPage from 'pages/users/UserListPage';
import UserNewPage from 'pages/users/UserNewPage';
import UserUploadPage from 'pages/users/UserUploadPage';
import UserDetailPage from 'pages/users/UserDetailPage';
import UserEditPage from 'pages/users/UserEditPage';
import UserContracts from 'pages/users/contracts/EmployeeContractsPage';
import NewContract from 'pages/users/contracts/NewContractPage';
import ChecksPage from 'pages/reports/checks/ChecksPage';
import { DevicesPage } from 'pages/devices/DevicesPage';
import ContractDetailPage from 'pages/users/contracts/ContractDetailPage';
import EditContractPage from 'pages/users/contracts/EditContractPage';
import IncidencesRegistersPage from 'pages/incidences/IncidencesRegistersPage';
import { IncidenceNewForm } from 'components/incidences/IncidenceNewForm';
import { DeviceDetailPage } from 'pages/devices/DeviceDetailPage';
import EventsPage from 'pages/reports/events/EventsPage';
import {
  authPaths,
  contractsPaths,
  globalPaths,
  devicesPaths,
  genericPaths,
  incidencePaths,
  kardexPaths,
  reportPaths,
  usersPaths,
} from 'constants/routePaths';
import UploadContractsPage from 'pages/contracts/UploadContractsPage';
import IncidencesCategoriesPage from 'pages/incidences/categories/IncidencesCategoryPage';
import IncidenceCategoryNewPage from 'pages/incidences/categories/IncidencesCategoryNewPage';
import IncidenceCategoryEditPage from 'pages/incidences/categories/incidencesCategoryEditPage';
import ContractSchedulesPage from 'pages/users/contracts/Schedules/ContractSchedulesPage';
import ReconcileIncidencesPage from 'pages/incidences/ReconcileIncidencesPage';
import KardexPage from 'pages/kardex/KardexPage';
import { NotFoundPage } from 'pages/auth/NotFoundPage';
import {
  AuthLayout,
  AuthProtectedLayout,
  RoleProtectedLayout,
} from 'components/auth/ProtectedLayouts/ProtectedLayouts';
import {
  AdminRole,
  AllRoles,
  EmployeeRole,
  SupportRole,
} from 'constants/enums/roles';
import { EmployeeSelfChecksPage } from 'pages/employees/EmployeeSelfChecksPage';
import CyclesPage from 'pages/cycles/CyclesPage';
import CareersPage from 'pages/careers/CareersPage';
import { HolidaysPage } from 'pages/holidays/HolidaysPage';
import { HolidaysListPage } from 'pages/holidays/HolidaysListPage';
import GroupsPage from 'pages/groups/GroupsPage';
import SubjectsPage from 'pages/subjects/SubjectsPage';
import UploadPhotos from 'components/employees/profile/UploadPhotos';
import { GroupsSchedulePage } from 'pages/groups/GroupsSchedulePage';

export const AppRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={authPaths.basePath} element={<AuthLayout />}>
          <Route path={authPaths.login} element={<LoginPage />} />
          <Route path={authPaths.logout} element={<LogOut />} />
          <Route path={authPaths.confirm} element={<ConfirmUserPage />} />
          <Route
            path={authPaths.lock}
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
          <Route
            path={authPaths.forgotPassword}
            element={<ForgotPasswordPage />}
          />
          <Route path={authPaths.newPassword} element={<NewPasswordPage />} />
        </Route>
        <Route
          path={globalPaths.basePath}
          element={<RoleProtectedLayout allowedRoles={[AdminRole]} />} // Admin global paths
        >
          <Route index element={<DashboardPage />} />
          <Route path={globalPaths.cycles} element={<CyclesPage />} />
          <Route path={globalPaths.careers} element={<CareersPage />} />
          <Route path={globalPaths.holidays} element={<HolidaysPage />} />
          <Route path={globalPaths.groups} element={<GroupsPage />} />
          <Route
            path={`${globalPaths.groupsSchedule}/:id`}
            element={<GroupsSchedulePage />}
          />
          <Route path={globalPaths.subjects} element={<SubjectsPage />} />
          <Route path={globalPaths.uploadPhotos} element={<UploadPhotos />} />
        </Route>
        <Route
          path={globalPaths.basePath}
          element={<RoleProtectedLayout allowedRoles={AllRoles} />} // All roles global paths
        >
          <Route
            path={globalPaths.holidaysList}
            element={<HolidaysListPage />}
          />
        </Route>
        <Route
          path={usersPaths.basePath}
          element={<RoleProtectedLayout allowedRoles={[AdminRole]} />}
        >
          <Route index element={<UserListPage />} />
          <Route
            path={`${usersPaths.editUser}/:id`}
            element={<UserEditPage />}
          />
          <Route
            path={`${usersPaths.userDetail}/:id`}
            element={<UserDetailPage />}
          />
          <Route
            path={`${usersPaths.userContracts}/:id`}
            element={<UserContracts />}
          />
          <Route
            path={`${usersPaths.userCreateContract}/:id`}
            element={<NewContract />}
          />
          <Route path={usersPaths.newUser} element={<UserNewPage />} />
          <Route path={usersPaths.uploadUsers} element={<UserUploadPage />} />
          <Route
            path={`${usersPaths.contractSchedules}/:contractId/:employeeId`}
            element={<ContractSchedulesPage />}
          />
        </Route>
        <Route
          path={contractsPaths.basePath}
          element={<RoleProtectedLayout allowedRoles={[AdminRole]} />}
        >
          <Route
            path={`${contractsPaths.contractDetail}/:id`}
            element={<ContractDetailPage />}
          />
          <Route
            path={`${contractsPaths.editContract}/:id`}
            element={<EditContractPage />}
          />
          <Route
            path={contractsPaths.uploadContracts}
            element={<UploadContractsPage />}
          />
        </Route>
        <Route
          path={reportPaths.basePath}
          element={<RoleProtectedLayout allowedRoles={[AdminRole]} />}
        >
          <Route path={reportPaths.checks} element={<ChecksPage />} />
          <Route path={reportPaths.events} element={<EventsPage />} />
          <Route
            path={reportPaths.incidences}
            element={<IncidencesRegistersPage />}
          />
        </Route>
        <Route
          path={incidencePaths.basePath}
          element={<RoleProtectedLayout allowedRoles={[AdminRole]} />}
        >
          <Route path={incidencePaths.new} element={<IncidenceNewForm />} />
          <Route path={incidencePaths.catalogue}>
            <Route index element={<IncidencesCategoriesPage />} />
            <Route
              path={incidencePaths.catalogueNew}
              element={<IncidenceCategoryNewPage />}
            />
            <Route
              path={`${incidencePaths.catalogueEdit}/:id`}
              element={<IncidenceCategoryEditPage />}
            />
          </Route>
          <Route
            path={`${incidencePaths.reconcile}/:employeeId`}
            element={<ReconcileIncidencesPage />}
          />
        </Route>
        <Route
          path={devicesPaths.basePath}
          element={
            <RoleProtectedLayout allowedRoles={[AdminRole, SupportRole]} />
          }
        >
          <Route index element={<DevicesPage />} />
          <Route
            path={devicesPaths.deviceDetail}
            element={<DeviceDetailPage />}
          />
        </Route>
        <Route path={kardexPaths.base} element={<AuthProtectedLayout />}>
          <Route path={':requestedEmployeeId'} element={<KardexPage />} />
          <Route path={`${kardexPaths.self}`} element={<KardexPage />} />
        </Route>
        {/* Generic routes */}
        <Route
          path={genericPaths.employeeSelfChecks}
          element={<RoleProtectedLayout allowedRoles={[EmployeeRole]} />}
        >
          <Route index element={<EmployeeSelfChecksPage />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};
