import { ContractKind } from 'types/contract';

interface GetContractKindTermProps {
  contractKinds: ContractKind[];
  kindId: string;
}

export const getContractKindTerm = ({
  contractKinds,
  kindId,
}: GetContractKindTermProps) => {
  const kind = contractKinds.find((kind) => kind._id === kindId);
  if (kind) {
    return {
      term: kind.term,
      workDayKind: kind.workDayKind,
    };
  }
};
