import { httpApi } from 'api/http.api';
import { AxiosResponse } from 'axios';
import {
  Career,
  UpdateCareerPayload,
  Specialty,
  UpdateCareerResponse,
} from 'types/careers';
import { ResponseGeneral } from 'types';

const controller = `career`;

//GET
export const getAllCareers = (): Promise<Career[]> =>
  httpApi.get<Career[]>(`${controller}`).then((res) => res?.data);
export const getAllSpecialties = (): Promise<AxiosResponse<Specialty[]>> =>
  httpApi.get<Specialty[]>(`${controller}/getSpecialties`).then((res) => res);

//DELETE
export const removeCareer = (id: string): Promise<ResponseGeneral> =>
  httpApi
    .delete<ResponseGeneral>(`${controller}/${id}`)
    .then((res) => res?.data);

//PUT
export const editCareer = ({
  payload,
  id,
}: UpdateCareerPayload): Promise<AxiosResponse<UpdateCareerResponse>> =>
  httpApi
    .put<UpdateCareerResponse>(`${controller}/${id}`, { ...payload })
    .then((res) => res);

//POST
export const newCareer = (payload: Career): Promise<Career> =>
  httpApi
    .post<Career>(`${controller}/`, { ...payload })
    .then((res) => res?.data);
