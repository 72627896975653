import { Button } from 'antd';
import { EditFilled } from '@ant-design/icons';

interface TableEditButtonProps {
  onClick: React.MouseEventHandler;
  id?: string;
  dataCy?: string;
}

export const TableEditButton = ({
  onClick,
  id = '',
  dataCy = '',
}: TableEditButtonProps) => {
  return (
    <div id="edit-button">
      <Button data-cy={dataCy} id={id} onClick={onClick}>
        <EditFilled />
      </Button>
    </div>
  );
};
