import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  deleteHoliday,
  editHoliday,
  getHolidays,
  newHoliday,
} from 'api/holiday.api';
import { SliceStatus } from 'constants/enums/slices';
import { Holiday } from 'interfaces/holidays.interfaces';

export interface HolidayConfigSlice {
  holidaysList: Holiday[];
  holidaysListStatus: SliceStatus;
  holidayStatus: SliceStatus;
}

const initialState: HolidayConfigSlice = {
  holidaysList: [],
  holidaysListStatus: SliceStatus.Empty,
  holidayStatus: SliceStatus.Empty,
};

export const doNewHoliday = createAsyncThunk(
  'holiday/new',
  async (Holiday: Holiday, { rejectWithValue }) => {
    try {
      await newHoliday(Holiday);
      return await getHolidays();
    } catch (error) {
      return rejectWithValue(false);
    }
  }
);

export const doListHolidays = createAsyncThunk(
  'holiday/list',
  async (year?: number) => await getHolidays(year)
);

export const doDeleteHoliday = createAsyncThunk(
  'holiday/delete',
  async (id: string, { rejectWithValue }) => {
    try {
      await deleteHoliday(id);
      return await getHolidays();
    } catch (error) {
      return rejectWithValue(false);
    }
  }
);

export const doEditHoliday = createAsyncThunk(
  'holiday/edit',
  async (holiday: Holiday, { rejectWithValue }) => {
    try {
      await editHoliday(holiday);
      return await getHolidays();
    } catch (error) {
      return rejectWithValue(false);
    }
  }
);

const holidaySlice = createSlice({
  name: 'holiday',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doNewHoliday.pending, (state) => {
      state.holidayStatus = SliceStatus.Updating;
    });
    builder.addCase(doNewHoliday.fulfilled, (state, action) => {
      state.holidaysList = action.payload;
      state.holidayStatus = SliceStatus.Fulfilled;
    });
    builder.addCase(doNewHoliday.rejected, (state) => {
      state.holidayStatus = SliceStatus.Error;
    });
    builder.addCase(doListHolidays.pending, (state) => {
      state.holidaysListStatus = SliceStatus.Updating;
    });
    builder.addCase(doListHolidays.fulfilled, (state, action) => {
      state.holidaysList = action.payload;
      state.holidaysListStatus = SliceStatus.Fulfilled;
    });
    builder.addCase(doListHolidays.rejected, (state) => {
      state.holidaysListStatus = SliceStatus.Error;
    });
    builder.addCase(doDeleteHoliday.pending, (state) => {
      state.holidayStatus = SliceStatus.Updating;
    });
    builder.addCase(doDeleteHoliday.fulfilled, (state, action) => {
      state.holidaysList = action.payload;
      state.holidayStatus = SliceStatus.Fulfilled;
    });
    builder.addCase(doDeleteHoliday.rejected, (state) => {
      state.holidayStatus = SliceStatus.Error;
    });
    builder.addCase(doEditHoliday.pending, (state) => {
      state.holidayStatus = SliceStatus.Updating;
    });
    builder.addCase(doEditHoliday.fulfilled, (state, action) => {
      state.holidaysList = action.payload;
      state.holidayStatus = SliceStatus.Fulfilled;
    });
    builder.addCase(doEditHoliday.rejected, (state) => {
      state.holidayStatus = SliceStatus.Error;
    });
  },
});

export default holidaySlice.reducer;
