import { PageTitle } from 'components/common/PageTitle/PageTitle';
import IncidenceCategories from 'components/incidences/category/IncidenceCategories';
import { useEffect, useState } from 'react';
import { Button, Col, Result, Row, Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';
import { IncidenceCategory } from 'types/incidences';
import { getCategories } from 'api/incidence.api';
import { globalPaths } from 'constants/routePaths';

const IncidencesCategoriesPage = () => {
  const [categoryList, setCategoriesList] = useState<
    IncidenceCategory[] | null
  >([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getCategories()
      .then((res) => {
        setCategoriesList(res.data);
        setLoading(false);
      })
      .catch(() => {
        setCategoriesList(null);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <PageTitle>{`Categorias de Incidencias`}</PageTitle>
      {loading && (
        <Row>
          <Col xs={24}>
            <Skeleton active />
          </Col>
        </Row>
      )}
      {categoryList && <IncidenceCategories categories={categoryList} />}
      {!loading && !categoryList && (
        <Result
          status="error"
          title="Hubo un error al cargar el catálogo"
          subTitle="Por favor vuelve a intentarlo más tarde."
          extra={[
            <Button
              type="primary"
              key="back"
              onClick={() => navigate(globalPaths.basePath)}
            >
              Regresar
            </Button>,
            <Button key="reload" onClick={() => window.location.reload()}>
              Reintentar
            </Button>,
          ]}
        />
      )}
    </>
  );
};

export default IncidencesCategoriesPage;
