import { HolidaysByMonth } from 'interfaces/holidays.interfaces';

export const initialHolidaysByMonthValues: HolidaysByMonth[] = [
  {
    month: 'Enero',
    monthNumber: 1,
    holidays: [],
  },
  {
    month: 'Febrero',
    monthNumber: 2,
    holidays: [],
  },
  {
    month: 'Marzo',
    monthNumber: 3,
    holidays: [],
  },
  {
    month: 'Abril',
    monthNumber: 4,
    holidays: [],
  },
  {
    month: 'Mayo',
    monthNumber: 5,
    holidays: [],
  },
  {
    month: 'Junio',
    monthNumber: 6,
    holidays: [],
  },
  {
    month: 'Julio',
    monthNumber: 7,
    holidays: [],
  },
  {
    month: 'Agosto',
    monthNumber: 8,
    holidays: [],
  },
  {
    month: 'Septiembre',
    monthNumber: 9,
    holidays: [],
  },
  {
    month: 'Octubre',
    monthNumber: 10,
    holidays: [],
  },
  {
    month: 'Noviembre',
    monthNumber: 11,
    holidays: [],
  },
  {
    month: 'Diciembre',
    monthNumber: 12,
    holidays: [],
  },
];
