import { PageTitle } from 'components/common/PageTitle/PageTitle';
import { DevicesList } from '../../components/devices/DevicesList';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { doList } from 'store/slices/devicesSlice';
import { SliceStatus } from 'constants/enums/slices';

export const DevicesPage = () => {
  const dispatch = useAppDispatch();
  const { status: deviceSliceStatus } = useAppSelector((state) => state.device);

  if (deviceSliceStatus === SliceStatus.Empty) {
    dispatch(doList());
  }

  return (
    <>
      <PageTitle>{`Dispositivos`}</PageTitle>
      <DevicesList />
    </>
  );
};
