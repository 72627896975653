export default {
  200: 'Solicitud procesada exitosamente',
  // Códigos de estado erróneos (Parte del cliente)
  400: 'Solicitud incorrecta: Los datos enviados son inválidos',
  401: 'Credenciales inválidas. Por favor, inicie sesión nuevamente.',
  403: 'Permiso denegado. Su cuenta no tiene los privilegios necesarios para esta operación.',
  404: 'Algún recurso solicitado no existe o no está disponible.',
  420: 'Faltan recursos necesarios para la operación.',
  422: 'Los datos enviados no cumplen con el formato esperado.',
  447: 'El id no es válido.',
  // Códigos de estado erróneos (Parte del servidor)
  500: 'Hubo un error con el servidor.',
  516: 'Error en la base de datos.',
};
