import { format, parseISO } from 'date-fns';
import { format as formatTZ } from 'date-fns-tz';
import { es } from 'date-fns/locale';
import { GetTodayFormattedDateProps } from 'interfaces/global.interfaces';

// Formatea la fecha tal cual viene de la base de datos
export const formatDate = (date: string) => {
  const onlyDate = date.split('T')[0];
  const formattedDate = format(parseISO(onlyDate), 'dd/MM/yyyy');
  return formattedDate;
};

export const getTodayFormattedDate = ({
  format,
}: GetTodayFormattedDateProps) => {
  const now = new Date();
  const today = formatTZ(now, format, {
    timeZone: 'America/Mexico_City',
  });
  return today;
};

export const getTodayWeekdayName = () => {
  const now = new Date();
  const todayWeekdayName = formatTZ(now, 'EEEE', {
    timeZone: 'America/Mexico_City',
    locale: es,
  });
  return todayWeekdayName;
};

export const getFormattedDate = (
  date: Date,
  format?:
    | 'yyyy-MM-dd'
    | 'dd-MM-yyyy'
    | 'yyyy/MM/dd'
    | 'dd/MM/yyyy'
    | 'dd/MMMM/yyyy'
) => {
  const formattedDate = formatTZ(date, format ? format : 'yyyy-MM-dd', {
    timeZone: 'America/Mexico_City',
    locale: es,
  });
  return formattedDate;
};
