import { differenceInMinutes } from 'date-fns';
import humanizeDuration from 'humanize-duration';

export const getPassedMinutesFromDate = (date: Date) => {
  const now = new Date();
  const passedMinutes = differenceInMinutes(now, date);
  return passedMinutes;
};

export const getTextualPassedTime = (minutes: number) => {
  // Convert minutes to milliseconds
  const milliseconds = minutes * 60 * 1000;
  // Convert duration to redeable text
  const textualPassedTime = humanizeDuration(milliseconds, {
    language: 'es',
    units: ['y', 'mo', 'd', 'h', 'm'],
    round: true,
  });
  return textualPassedTime;
};
