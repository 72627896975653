import { PageTitle } from 'components/common/PageTitle/PageTitle';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button, Col, Result, Row, Skeleton } from 'antd';
import { Incidence } from 'types/incidences';
import { getIncidencesList } from 'api/incidence.api';
import IncidencesList from 'components/incidences/IncidenceList';
import { getStartEndOfMonth } from 'hooks/getStartEndOfDate';
import { defaultContractType } from 'constants/ReportConstants';
import { globalPaths } from 'constants/routePaths';
import { defaultPaginationOptions } from 'constants/global';

const IncidencesRegistersPage = () => {
  const navigate = useNavigate();
  const [incidences, setIncidences] = useState<Incidence[] | null>([]);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { startOfMonth, endOfMonth } = getStartEndOfMonth();
  const [totalPages, setTotalPages] = useState(0);
  const { page, limit } = defaultPaginationOptions;

  useEffect(() => {
    setLoading(true);
    const filterOptions = {
      from: startOfMonth,
      to: endOfMonth,
      contractType: defaultContractType,
    };
    getIncidencesList(filterOptions, page, limit)
      .then((res) => {
        if (res.data.data !== undefined) {
          setIncidences(res.data.data);
        } else {
          setIncidences([]);
        }
        setTotalPages(res.data.total);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setIncidences(null);
        setIsError(true);
      });
  }, [endOfMonth, startOfMonth, limit, page]);

  return (
    <>
      <PageTitle>{`Reporte de Incidencias`}</PageTitle>
      {loading ? (
        <Row>
          <Col xs={24}>
            <Skeleton active />
          </Col>
        </Row>
      ) : (
        <>
          {!isError ? (
            <>
              {incidences && (
                <IncidencesList incidences={incidences} pages={totalPages} />
              )}
            </>
          ) : (
            <Result
              status="error"
              title="Hubo un error al cargar los datos"
              subTitle="Por favor vuelve a intentarlo más tarde."
              extra={[
                <Button
                  type="primary"
                  key="back"
                  onClick={() => navigate(globalPaths.basePath)}
                >
                  Regresar
                </Button>,
                <Button key="reload" onClick={() => window.location.reload()}>
                  Reintentar
                </Button>,
              ]}
            />
          )}
        </>
      )}
    </>
  );
};

export default IncidencesRegistersPage;
