import { Card, Col, Row } from 'antd';
import {
  ActiveContractsCardProps,
  ContractsCountCardProps,
} from 'interfaces/contract.interfaces';
import { ContractKind } from 'types/contract';

export const ActiveContracts = ({
  contractKinds,
}: ActiveContractsCardProps) => {
  const ContractsCount = ({
    contractTypeTxt,
    contractCount,
  }: ContractsCountCardProps) => (
    <div className="contracts-count-card text-center">
      <span className="contracts-number">{contractCount}</span>
      <div className="contracts-type-txt">{contractTypeTxt}</div>
    </div>
  );

  return (
    <div id="active-contracts">
      <Card
        title={<h3 className="sub-title">Contratos activos</h3>}
        className="card"
      >
        <Row>
          <Col className="contracts-wrapper-dashboard" xs={24}>
            {contractKinds &&
              contractKinds.map((contract: ContractKind) => (
                <div key={`contract-kinds-${contract._id}`}>
                  <ContractsCount
                    contractCount={contract.count}
                    contractTypeTxt={contract.name}
                  />
                </div>
              ))}
          </Col>
        </Row>
      </Card>
    </div>
  );
};
