import { Button, Col, Result, Row, Skeleton } from 'antd';
import { getContractSchedules } from 'api/schedule.api';
import { PageTitle } from 'components/common/PageTitle/PageTitle';
import { ContractSchedules } from 'components/employees/Contracts/Schedules/ContractSchedules';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ContractSchedule } from 'types/contract';

const ContractSchedulesPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { contractId, employeeId } = params;
  const [contractSchedules, setContractSchedules] = useState<
    ContractSchedule[] | null
  >();
  const [loading, setLoading] = useState(true);

  const getContractSchedulesList = useCallback(() => {
    if (contractId) {
      getContractSchedules(contractId)
        .then((res) => {
          setContractSchedules(res.data);
          setLoading(false);
        })
        .catch(() => {
          setContractSchedules(null);
          setLoading(false);
        });
    }
  }, [contractId]);

  useEffect(() => {
    getContractSchedulesList();
  }, [getContractSchedulesList]);

  return (
    <div id="contract-schedules-page">
      <PageTitle>{`Contrato - horarios`}</PageTitle>
      {contractId && employeeId && (
        <>
          {loading && (
            <Row>
              <Col xs={24}>
                <Skeleton active />
              </Col>
            </Row>
          )}
          {!loading && contractSchedules && (
            <ContractSchedules
              schedules={contractSchedules}
              contractId={contractId}
              employeeId={employeeId}
              getContractSchedulesList={getContractSchedulesList}
            />
          )}
          {!loading && !contractSchedules && (
            <Result
              status="error"
              title="Hubo un error al cargar el horario del contrato"
              subTitle="Por favor vuelve a intentarlo más tarde."
              extra={[
                <Button type="primary" key="back" onClick={() => navigate(-1)}>
                  Regresar
                </Button>,
                <Button key="reload" onClick={() => window.location.reload()}>
                  Reintentar
                </Button>,
              ]}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ContractSchedulesPage;
