import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getGroupSchedule,
  newGroupSchedule,
  editGroupSchedule,
  publishGroupSchedule,
} from 'api/group-schedule.api';
import { SliceStatus } from 'constants/enums/slices';
import { GroupSchedule } from 'types/group-schedule';

export interface GroupSlice {
  groupSchedules: GroupSchedule[];
  groupScheduleStatus: SliceStatus;
  listGroupScheduleStatus: SliceStatus;
}

const initialState: GroupSlice = {
  groupSchedules: [],
  groupScheduleStatus: SliceStatus.Empty,
  listGroupScheduleStatus: SliceStatus.Empty,
};

export const doGroupScheduleList = createAsyncThunk(
  'groupsSchedule/list',
  async (id: string, { rejectWithValue }) => {
    try {
      return await getGroupSchedule(id);
    } catch (error: unknown) {
      return await rejectWithValue(error);
    }
  }
);

export const doCreateGroupSchedule = createAsyncThunk(
  'groupsSchedule/create',
  async (groupSchedulePayload: GroupSchedule, { rejectWithValue }) => {
    try {
      await newGroupSchedule(groupSchedulePayload);
      return await getGroupSchedule(groupSchedulePayload.group);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const doEditGroupSchedule = createAsyncThunk(
  'groupsSchedule/edit',
  async (groupSchedulePayload: GroupSchedule, { rejectWithValue }) => {
    try {
      await editGroupSchedule(groupSchedulePayload);
      return await getGroupSchedule(groupSchedulePayload.group);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const doPublishGroupSchedule = createAsyncThunk(
  'groupsSchedule/publish',
  async (id: string, { rejectWithValue }) => {
    try {
      await publishGroupSchedule(id);
      return await getGroupSchedule(id);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const groupsSlice = createSlice({
  name: 'groupSchedules',
  initialState,
  reducers: {
    clearGroupScheduleList: (state) => {
      state.groupSchedules = [];
      state.listGroupScheduleStatus = SliceStatus.Empty;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doGroupScheduleList.pending, (state) => {
      state.listGroupScheduleStatus = SliceStatus.Updating;
    });
    builder.addCase(doGroupScheduleList.fulfilled, (state, action) => {
      state.groupSchedules = action.payload;
      state.listGroupScheduleStatus = SliceStatus.Fulfilled;
    });
    builder.addCase(doGroupScheduleList.rejected, (state) => {
      state.listGroupScheduleStatus = SliceStatus.Error;
    });
    builder.addCase(doCreateGroupSchedule.pending, (state) => {
      state.groupScheduleStatus = SliceStatus.Updating;
    });
    builder.addCase(doCreateGroupSchedule.fulfilled, (state, action) => {
      state.groupSchedules = action.payload;
      state.groupScheduleStatus = SliceStatus.Fulfilled;
    });
    builder.addCase(doCreateGroupSchedule.rejected, (state) => {
      state.groupScheduleStatus = SliceStatus.Error;
    });
    builder.addCase(doEditGroupSchedule.pending, (state) => {
      state.groupScheduleStatus = SliceStatus.Updating;
    });
    builder.addCase(doEditGroupSchedule.fulfilled, (state, action) => {
      state.groupSchedules = action.payload;
      state.groupScheduleStatus = SliceStatus.Fulfilled;
    });
    builder.addCase(doEditGroupSchedule.rejected, (state) => {
      state.groupScheduleStatus = SliceStatus.Error;
    });
    builder.addCase(doPublishGroupSchedule.pending, (state) => {
      state.groupScheduleStatus = SliceStatus.Updating;
    });
    builder.addCase(doPublishGroupSchedule.fulfilled, (state, action) => {
      state.groupSchedules = action.payload;
      state.groupScheduleStatus = SliceStatus.Fulfilled;
    });
    builder.addCase(doPublishGroupSchedule.rejected, (state) => {
      state.groupScheduleStatus = SliceStatus.Error;
    });
  },
});

export const { clearGroupScheduleList } = groupsSlice.actions;

export default groupsSlice.reducer;
