import { CustomFormItem, FormQuestionTypes } from 'interfaces/form.interfaces';
import { missingFieldRule } from 'constants/global';

export const subjectFormItems: CustomFormItem[] = [
  {
    name: 'name',
    label: 'Nombre de la materia',
    type: FormQuestionTypes.input,
    visibleOnCreate: true,
    visibleOnEdit: true,
    rules: missingFieldRule,
  },
  {
    name: 'code',
    label: 'Clave única',
    type: FormQuestionTypes.input,
    visibleOnCreate: true,
    visibleOnEdit: true,
    rules: missingFieldRule,
  },
  {
    name: 'grade',
    label: 'Grado',
    type: FormQuestionTypes.numberInput,
    visibleOnCreate: true,
    visibleOnEdit: true,
    rules: missingFieldRule,
  },
  {
    name: 'career',
    label: 'Carrera',
    type: FormQuestionTypes.selectCareers,
    visibleOnCreate: true,
    visibleOnEdit: true,
    rules: missingFieldRule,
  },
  {
    name: 'specialties',
    label: 'Especialidades',
    type: FormQuestionTypes.selectMultipleCareerSpecialties,
    visibleOnCreate: true,
    visibleOnEdit: true,
    rules: missingFieldRule,
  },
];
