import { Button, Col, ColorPicker, Form, Input, Row, Tag } from 'antd';
import { useState } from 'react';
import { Color } from 'antd/es/color-picker';
import { AddTagPayload } from 'types/devices';
import { addDeviceTag } from 'api/devices.api';
import { useAppDispatch } from 'hooks/reduxHooks';
import { doList } from 'store/slices/devicesSlice';
import * as Sentry from '@sentry/react';
import notificationHook from 'hooks/notificationHook';
import { ApiError } from 'types';
import statusMessages from 'statusMessages';
import 'styles/sass/devices.scss';

interface AddLabelProps {
  deviceId: string;
}

export const AddLabel = ({ deviceId }: AddLabelProps) => {
  const defaultTagColor = '#51C951';
  const [newTagForm, setNewTagForm] = useState(false);
  const [tagName, setTagName] = useState('Vista previa');
  const [tagColor, setTagColor] = useState(defaultTagColor);
  const [addingTag, setAddingTag] = useState(false);
  const dispatch = useAppDispatch();

  const onChangeTagName = (name: string) => {
    if (name !== '') {
      setTagName(name);
    } else {
      setTagName('Vista previa');
    }
  };

  const onChangeColor = (value: Color, hex: string) => {
    setTagColor(hex);
  };

  const createTag = async () => {
    setAddingTag(true);
    const payload: AddTagPayload = {
      deviceId,
      tagName,
      tagColor,
    };
    await addDeviceTag(payload)
      .then(() => {
        notificationHook({
          type: 'success',
          message: 'Etiqueta agregada con éxito.',
        });
        dispatch(doList());
      })
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          type: 'error',
          message: statusMessages.devices[
            currentStatus ? currentStatus : 500
          ] as string,
        });
        Sentry.captureException(error);
      });
  };

  return (
    <div className="tag-container">
      {newTagForm ? (
        <div className="tag-new-label-container">
          <Tag className="mb-4" color={tagColor}>
            {tagName}
          </Tag>
          <Form
            onFinish={createTag}
            initialValues={{ labelColor: defaultTagColor }}
          >
            <Row gutter={30} justify="space-between">
              <Col>
                <Form.Item
                  name="labelName"
                  label="Nombre de la etiqueta"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Ingresa el nombre de la etiqueta',
                    },
                  ]}
                >
                  <Input
                    type="text"
                    onChange={(e) => {
                      onChangeTagName(e.target.value);
                    }}
                    maxLength={30}
                    placeholder="Vista previa"
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="labelColor" label="Color de la etiqueta">
                  <ColorPicker onChange={onChangeColor} format="hex" />
                </Form.Item>
              </Col>
              <Col>
                <Row gutter={10} justify="end">
                  <Col>
                    <Button
                      type="default"
                      onClick={() => {
                        setNewTagForm(false);
                      }}
                    >
                      Cancelar
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={addingTag}
                    >
                      Agregar
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      ) : (
        <Row>
          <Col xs={24}>
            <Button
              type="primary"
              onClick={() => {
                setNewTagForm(true);
              }}
            >
              Nueva etiqueta
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
};
