import { PageTitle } from 'components/common/PageTitle/PageTitle';
import { EmployeeContracts } from 'components/employees/Contracts/UserContracts';
import { useCallback, useEffect, useState } from 'react';
import Contract from '../../../types/contract';
import * as Sentry from '@sentry/react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Result, Row, Skeleton } from 'antd';
import { getEmployeeContracts } from 'api/contract.api';

const UserContractsPage = () => {
  const [loading, setLoading] = useState(false);
  const [employeeContracts, setEmployeeContracts] = useState<
    Contract[] | null
  >();
  const params = useParams();
  const navigate = useNavigate();

  const searchEmployeeContracts = useCallback(async () => {
    setLoading(true);
    if (params.id) {
      await getEmployeeContracts(params.id)
        .then((res) => {
          setEmployeeContracts(res.data);
        })
        .catch((error) => {
          Sentry.captureException(error as Error);
        });
    }
    setLoading(false);
  }, [params.id]);

  useEffect(() => {
    searchEmployeeContracts();
  }, [searchEmployeeContracts]);

  return (
    <>
      <PageTitle>{`Empleado - Contratos`}</PageTitle>
      {loading && (
        <Row>
          <Col xs={24}>
            <Skeleton active />
          </Col>
        </Row>
      )}
      {!loading && employeeContracts && (
        <EmployeeContracts
          employeeContracts={employeeContracts}
          setter={setEmployeeContracts}
        />
      )}
      {!loading && !employeeContracts && (
        <Result
          status="error"
          title="Hubo un error al cargar los contratos del empleado"
          subTitle="Por favor vuelve a intentarlo más tarde."
          extra={[
            <Button
              type="primary"
              key="back"
              onClick={() => navigate('/usuarios')}
              data-cy="btn-back"
            >
              Regresar
            </Button>,
            <Button
              data-cy="btn-reload"
              key="reload"
              onClick={() => window.location.reload()}
            >
              Reintentar
            </Button>,
          ]}
        />
      )}
    </>
  );
};

export default UserContractsPage;
