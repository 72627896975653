import styled from 'styled-components';
import { Button, DatePicker, Row, Select } from 'antd';
import { H1 } from 'components/common/typography/H1/H1';
import { H2 } from 'components/common/typography/H2/H2';

export const Title = styled(H1)`
  color: #35a0dc !important;
  margin-bottom: 1rem !important;
  margin-top: 1rem;
  font-size: 2rem !important;
  text-align: center;
`;

export const Subtitle = styled(H2)`
  margin-top: 1rem;
  font-size: 1.5rem !important;
  margin-bottom: 1rem !important;
`;

export const TableOptionButton = styled(Button)`
  background-color: #35a0dc !important;
  color: white;
  font-size: 20px;
  height: 40px;
  border-color: #d9d9d9;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  :hover {
    color: #ffb155 !important;
    border-color: #ffb155 !important;
  }
`;

export const PrimaryButton = styled(Button)`
  background-color: #ffb155;
  color: white;
  font-size: 18px;
  font-weight: bold;
  height: 40px;
  border-color: #d9d9d9;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  margin-bottom: 1.5rem;
  margin-top: 1rem;
  width: 100%;
  :hover {
    color: #35a0dc !important;
    border-color: #35a0dc !important;
  }
`;

export const divider = styled.hr`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const SubmitButton = styled(Button)`  
		text-align: center;
		background-color: #35a0dc;
		color: white;
		display: inline-block;
		font-size: 18px;
		font-weight: bold;
		height: 40px;
		min-width: 200px:
		border-color: #d9d9d9;
		box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
		margin-bottom: 1.5rem;
		margin-top: 1rem;        
		width: 100%;    
    :hover {
			color: #ffb155 !important;
			border-color: #ffb155 !important;
    }
`;

export const SimpleSelect = styled(Select)`
  width: 100%;
`;

export const LightGreyText = styled.p`
  color: #c4c4c4;
`;

export const LargeDatePicker = styled(DatePicker)`
  width: 100%;
`;

export const SpacedRow = styled(Row)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const SpacedRow50PX = styled(Row)`
  margin-top: 50px;
  margin-bottom: 50px;
`;
