import { httpApi } from 'api/http.api';
import { AxiosResponse } from 'axios';
import { LoginWithGoogleRequest } from 'store/slices/authSlice';
import { User } from 'types';

const controller = `auth`;

export interface AuthData {
  email: string;
  password: string;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface SecurityCodePayload {
  code: string;
}

export interface NewPasswordData {
  newPassword: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface LoginResponse {
  token: string;
  user: User;
}

export const me = (): Promise<AxiosResponse<LoginResponse>> =>
  httpApi.get<LoginResponse>(`${controller}/me`, {}).then((res) => res);

export const confirm = (): Promise<AxiosResponse<LoginResponse>> =>
  httpApi.get<LoginResponse>(`${controller}/confirm`, {}).then((res) => res);

export const login = (
  loginPayload: LoginRequest
): Promise<AxiosResponse<LoginResponse>> =>
  httpApi
    .post<LoginResponse>(`${controller}/signin`, { ...loginPayload })
    .then((res) => res);

export const loginWithGoogle = (
  loginPayload: LoginWithGoogleRequest
): Promise<AxiosResponse<LoginResponse>> =>
  httpApi
    .post<LoginResponse>(`${controller}/google-signin`, { ...loginPayload })
    .then((res) => res);

export const resetPassword = (
  resetPasswordPayload: ResetPasswordRequest
): Promise<AxiosResponse<undefined>> =>
  httpApi
    .post<undefined>(`${controller}/forgotPassword`, {
      ...resetPasswordPayload,
    })
    .then((res) => res);

export const verifySecurityCode = (
  securityCodePayload: SecurityCodePayload
): Promise<AxiosResponse<undefined>> =>
  httpApi
    .post<undefined>(`${controller}/verifySecurityCode`, {
      ...securityCodePayload,
    })
    .then((res) => res);

export const setNewPassword = (
  newPasswordData: NewPasswordData
): Promise<AxiosResponse<undefined>> =>
  httpApi
    .post<undefined>(`${controller}/setpassword`, { ...newPasswordData })
    .then((res) => res);

export const impersonate = (
  employee: string
): Promise<AxiosResponse<LoginResponse>> =>
  httpApi
    .get<LoginResponse>(`${controller}/impersonate/${employee}`, {})
    .then((res) => res);
