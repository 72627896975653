import { PageTitle } from 'components/common/PageTitle/PageTitle';
import EventsList from 'components/reports/events/EventsList';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AllEventListType } from 'types/events';
import { Button, Col, Result, Row, Skeleton } from 'antd';
import { getStartEndOfMonth } from 'hooks/getStartEndOfDate';
import { defaultContractType } from 'constants/ReportConstants';
import { globalPaths } from 'constants/routePaths';
import { getEventsList } from 'api/event.api';
import { defaultPaginationOptions } from 'constants/global';

const EventsPage = () => {
  const [eventsData, setEventsData] = useState<AllEventListType[] | null>();
  const navigate = useNavigate();
  const { startOfMonth, endOfMonth } = getStartEndOfMonth();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const { page, limit } = defaultPaginationOptions;

  const firstSearch = () => {
    setIsLoading(true);
    const filterOptions = {
      from: startOfMonth,
      to: endOfMonth,
      contractType: defaultContractType,
    };
    getEventsList(filterOptions, page, limit)
      .then((res) => {
        if (res.data.data !== undefined) {
          setEventsData(res.data.data);
        } else {
          setEventsData([]);
        }
        setTotalPages(res.data.total);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setEventsData(null);
        setIsError(true);
      });
  };

  useEffect(() => {
    firstSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageTitle>{`Reporte de eventos`}</PageTitle>
      {isLoading ? (
        <Row>
          <Col xs={24}>
            <Skeleton active />
          </Col>
        </Row>
      ) : (
        <>
          {!isError ? (
            <>
              {eventsData && (
                <EventsList events={eventsData} pages={totalPages} />
              )}
            </>
          ) : (
            <Result
              status="error"
              title="Hubo un error al cargar los eventos"
              subTitle="Por favor vuelve a intentarlo más tarde."
              extra={[
                <Button
                  type="primary"
                  key="back"
                  onClick={() => navigate(globalPaths.basePath)}
                >
                  Regresar
                </Button>,
                <Button key="reload" onClick={() => window.location.reload()}>
                  Reintentar
                </Button>,
              ]}
            />
          )}
        </>
      )}
    </>
  );
};

export default EventsPage;
