import { Button, Row, Col, Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from 'components/common/PageTitle/PageTitle';

export const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <PageTitle>Página no encontrada</PageTitle>
      <Row className="error-page px-10">
        <Col xs={24}>
          <Result
            status="403"
            title="403"
            subTitle="Lo sentimos, la página que buscas no existe."
            extra={
              <Button
                type="primary"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Regresar
              </Button>
            }
          />
        </Col>
      </Row>
    </>
  );
};
