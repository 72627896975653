import { EditFilled } from '@ant-design/icons';
import { ColumnsCareer } from './CareersListColumns';
import { useAppSelector, useAppDispatch } from 'hooks/reduxHooks';
import { Table } from 'antd';
import { Career } from 'types/careers';
import { useState } from 'react';
import { careerFormItems } from 'constants/forms/careers';
import { SliceStatus } from 'constants/enums/slices';
import { FormDrawer } from 'components/common/Drawer/FormDrawer';
import { doEditCareer, doRemoveCareer } from 'store/slices/careersSlice';
import { useForm } from 'antd/es/form/Form';
import notificationHook from 'hooks/notificationHook';
import statusMessages from 'statusMessages';
import { ApiError } from 'types';
import { TableProps } from 'antd/es/table';
import 'styles/sass/tables.scss';

const tableProps: TableProps<Career> = {
  bordered: true,
  tableLayout: 'fixed',
  size: 'small',
  pagination: { position: ['bottomCenter'], pageSize: 50 },
  className: 'table-ps',
};

export const CareersList = () => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const { careers, careerStatus } = useAppSelector((state) => state.career);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [careerToEditId, setCareerToEditId] = useState<string>();

  const handleDeleteCareer = (id: string) => {
    dispatch(doRemoveCareer(id))
      .unwrap()
      .then(() => {
        notificationHook({
          type: 'success',
          message: 'La carrera se ha eliminado exitosamente',
        });
      })
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          type: 'error',
          message: statusMessages.career[
            currentStatus ? currentStatus : 500
          ] as string,
        });
      });
  };

  const handleEditCareer = async (values: Career) => {
    if (careerToEditId) {
      const payload = { payload: values, id: careerToEditId };
      dispatch(doEditCareer(payload))
        .unwrap()
        .then(() => {
          form.resetFields();
          notificationHook({
            type: 'success',
            message: '¡Se ha editado la carrera con éxito!',
          });
        })
        .catch((error) => {
          const currentStatus = (error as ApiError).statusCode;
          notificationHook({
            message: statusMessages.career[currentStatus ?? 500] as string,
            type: 'error',
          });
        })
        .finally(() => {
          setOpenEditDrawer(false);
        });
    }
  };

  return (
    <div data-cy="careers-list-table">
      <Table
        {...tableProps}
        dataSource={careers}
        columns={ColumnsCareer({
          onDelete: (id) => handleDeleteCareer(id),
          onOpenEditDrawer: (value) => setOpenEditDrawer(value),
          setItemToEditId: (id) => setCareerToEditId(id),
          form,
        })}
        rowKey="_id"
      />
      <FormDrawer<Career>
        open={openEditDrawer}
        textButton={<EditFilled />}
        title="Editar Carrera"
        width={500}
        confirmText="Editar"
        cancelText="Cancelar"
        formMode="edit"
        formItems={careerFormItems}
        loading={careerStatus === SliceStatus.Updating}
        onConfirm={(values) => handleEditCareer(values)}
        onOpen={() => setOpenEditDrawer(true)}
        onClose={() => {
          setOpenEditDrawer(false);
        }}
        form={form}
        dataCy="edit-career"
      />
    </div>
  );
};
