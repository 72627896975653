import {
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Switch,
  Modal,
  Button,
} from 'antd';
import { useCallback, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Contract, {
  ContractKind,
  UpdateContract,
} from '../../../types/contract';
import { Dates } from 'constants/Dates';
import { getActiveContractKinds } from 'api/contract.api';
import { getContractKindTerm } from 'hooks/getContractKindTerm';
import { contractsPaths, usersPaths } from 'constants/routePaths';
import { useAppDispatch } from 'hooks/reduxHooks';
import { doUpdateOne } from 'store/slices/contractSlice';
import { doList as listContracts } from 'store/slices/contractSlice';
import { NewScheduleInterval } from '../../../types/schedule';
import { ShowEmployeeData } from '../ShowEmployeeData';
import * as Sentry from '@sentry/react';
import notificationHook from 'hooks/notificationHook';
import statusMessages from 'statusMessages';
import { ApiError } from 'types';
import { BackButton } from 'components/common/BackButton/BackButton';

interface IEditContract {
  contract: Contract;
}

export const EditContract = ({ contract }: IEditContract) => {
  const navigate = useNavigate();
  const [contractStatus, setContractStatus] = useState(contract.status);
  const [wasStatusActivated, setWasStatusActivated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contractKinds, setContractKinds] = useState<ContractKind[]>();
  const [contractKindTerm, setContractKindTerm] = useState({
    term: '',
    workDayKind: '',
  });
  const dispatch = useAppDispatch();
  const params = useParams();
  const contractId = params.id;
  const [form] = Form.useForm();
  const [contractType, setContractType] = useState('');
  const initialContractStatus = useRef(contract.status);
  const [showInvalidSchedules, setShowInvalidSchedules] = useState(false);
  const [invalidSchedules, setInvalidSchedules] =
    useState<NewScheduleInterval[]>();

  const setContractFields = useCallback(() => {
    form.setFieldsValue(contract);
    const startDate = Dates.getDate(contract.startDate);
    const expirationDate = contract.expirationDate
      ? Dates.getDate(contract.expirationDate)
      : null;
    form.setFieldsValue({ startDate, expirationDate });
    setContractType(contract.contractKind);
  }, [contract, form]);

  useEffect(() => {
    setContractFields();
  }, [setContractFields]);

  const onFinish = (values: UpdateContract) => {
    if (contractId) {
      values._id = contractId;
      values.status = contractStatus;
      values.isActivated = wasStatusActivated;
      values.userId = contract.userId;
      submit(values);
    }
  };

  const getContractKindList = async () => {
    await getActiveContractKinds()
      .then((res) => {
        setContractKinds(res.data);
      })
      .catch((error) => {
        Sentry.captureException(error);
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          type: 'error',
          message: statusMessages.contract[
            currentStatus ? currentStatus : 500
          ] as string,
        });
      });
  };

  useEffect(() => {
    if (contractKinds && contractType) {
      const contractKind = getContractKindTerm({
        contractKinds,
        kindId: contractType,
      });
      if (contractKind) {
        setContractKindTerm({
          term: contractKind?.term,
          workDayKind: contractKind?.workDayKind,
        });
      }
    }
  }, [contractKinds, contractType]);

  useEffect(() => {
    getContractKindList();
  }, []);

  useEffect(() => {
    if (!initialContractStatus.current && contractStatus) {
      setWasStatusActivated(true);
    }
  }, [contractStatus]);

  const submit = (values: UpdateContract) => {
    if (contractKindTerm.term !== 'temporal') {
      values.expirationDate = null;
    }
    setLoading(true);
    dispatch(doUpdateOne(values))
      .unwrap()
      .then((response) => {
        if (
          response.data.invalidSchedules &&
          response.data.invalidSchedules.length > 0
        ) {
          setInvalidSchedules(response.data.invalidSchedules);
          setShowInvalidSchedules(true);
          setLoading(false);
        } else {
          notificationHook({
            type: 'success',
            message: 'Contrato editado con éxito !',
          });
          setLoading(false);
          dispatch(listContracts());
          navigate(
            `${usersPaths.basePath}${contractsPaths.basePath}/${contract.userId}`
          );
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          type: 'error',
          message: statusMessages.contract[
            currentStatus ? currentStatus : 500
          ] as string,
        });
        setLoading(false);
      });
  };

  const onChangeContractType = (type: string) => {
    setContractType(type);
  };

  const invalidSchedulesModal = () => (
    <Modal
      open={showInvalidSchedules}
      title="Error al activar el contrato"
      width={600}
      onCancel={() => {
        setShowInvalidSchedules(false);
      }}
      onOk={() => {
        setShowInvalidSchedules(false);
      }}
      cancelText="Cerrar"
    >
      {invalidSchedules && (
        <>
          <Row>
            <Col xs={24}>
              <p>Los siguientes horarios se solapan con un horario activo</p>
            </Col>
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col xs={{ offset: 1, span: 22 }}>
              <ul>
                {invalidSchedules.map((schedule, index) => (
                  <li key={index}>
                    {`Día ${schedule.dayOfWeek}, de ${schedule.from} a ${schedule.to}`}
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </>
      )}

      <Row style={{ marginTop: '20px' }}>
        <Col>
          <p>
            Por favor, revisa que los horarios del contrato no se solapen para
            poder activarse.
          </p>
        </Col>
      </Row>
    </Modal>
  );

  return (
    <div id="edit-employee-contract">
      <h2 className="title">Editar contrato</h2>
      <BackButton />
      <Spin spinning={loading}>
        <div id="form-edit-employee-contract" className="">
          {contractId && contractKinds && (
            <>
              <ShowEmployeeData userId={contract.userId} />
              <Row className="mt-10" justify="center">
                <Col xs={10}>
                  <h2 className="sub-title mb-5">Información del contrato</h2>
                  <Form
                    onFinish={onFinish}
                    autoComplete="off"
                    form={form}
                    labelCol={{ span: 10 }}
                  >
                    <Form.Item
                      name="contractKind"
                      label="Selecciona el tipo de contrato"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor selecciona el tipo de contrato',
                          type: 'string',
                        },
                      ]}
                      hasFeedback
                    >
                      <Select
                        placeholder="Seleccionar tipo de contrato"
                        id="contractType"
                        onChange={(e) => {
                          onChangeContractType(e);
                        }}
                        disabled
                      >
                        {contractKinds && (
                          <>
                            {contractKinds.map((contractKind: ContractKind) => (
                              <Select.Option
                                value={contractKind._id}
                                key={contractKind._id}
                              >
                                {contractKind.name}
                              </Select.Option>
                            ))}
                          </>
                        )}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="contractCode"
                      label="Consecutivo o clave"
                      rules={[
                        {
                          required: true,
                          message:
                            'Por favor ingresa el consecutivo o clave del contrato',
                          type: 'string',
                        },
                      ]}
                      hasFeedback
                    >
                      <Input
                        placeholder="Ingresa el consecutivo o clave"
                        id="contractCode"
                      />
                    </Form.Item>
                    <Form.Item
                      name="startDate"
                      label="Fecha de inicio"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor ingresa la fecha de inicio',
                          type: 'date',
                        },
                      ]}
                      hasFeedback
                    >
                      <DatePicker id="startDate" />
                    </Form.Item>
                    {contractKindTerm.term === 'temporal' && (
                      <Form.Item
                        name="expirationDate"
                        label="Fecha de fin"
                        rules={[
                          {
                            required: true,
                            message: 'Por favor ingresa la fecha de fin',
                            type: 'date',
                          },
                        ]}
                        hasFeedback
                      >
                        <DatePicker id="endDate" />
                      </Form.Item>
                    )}
                    <Form.Item
                      name="status"
                      label="Estatus del contrato"
                      valuePropName="checked"
                    >
                      <Row gutter={10} align="middle">
                        <Col>
                          <Switch
                            onChange={(e: boolean) => {
                              setContractStatus(e);
                            }}
                            defaultChecked={contractStatus}
                            id="status"
                          />
                        </Col>
                        <Col>
                          {contractStatus && contractStatus == true ? (
                            <span className="active-text">Activo</span>
                          ) : (
                            <span className="inactive-text">Inactivo</span>
                          )}
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item>
                      <Row justify="end" gutter={10}>
                        <Col>
                          <Button
                            onClick={() => navigate(-1)}
                            id="cancelBtn"
                            type="default"
                          >
                            Regresar
                          </Button>
                        </Col>
                        <Col>
                          <Button htmlType="submit" id="saveBtn" type="primary">
                            Guardar
                          </Button>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </>
          )}
        </div>
        {invalidSchedulesModal()}
      </Spin>
    </div>
  );
};
