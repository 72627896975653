import { PageTitle } from 'components/common/PageTitle/PageTitle';
import { UserNew } from 'components/employees/UserNew';

const UserNewPage = () => {
  return (
    <>
      <PageTitle>{`Nuevo empleado`}</PageTitle>
      <UserNew />
    </>
  );
};

export default UserNewPage;
