import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { newGroup, editGroup, getAllGroups, removeGroup } from 'api/groups.api';
import { SliceStatus } from 'constants/enums/slices';
import { Group, UpdateGroupPayload, searchGroup } from 'types/groups';

export interface GroupSlice {
  groups: Group[];
  groupStatus: SliceStatus;
  listGroupStatus: SliceStatus;
}

const initialState: GroupSlice = {
  groups: [],
  groupStatus: SliceStatus.Empty,
  listGroupStatus: SliceStatus.Empty,
};

export const doGroupsList = createAsyncThunk(
  'groups/list',
  async (searchPayload: searchGroup, { rejectWithValue }) => {
    try {
      return await getAllGroups(searchPayload);
    } catch (error: unknown) {
      return await rejectWithValue(error);
    }
  }
);

export const doCreateGroup = createAsyncThunk(
  'groups/create',
  async (GroupCreatePayload: Group, { rejectWithValue }) => {
    try {
      await newGroup(GroupCreatePayload);
      return await getAllGroups({ career: '', specialty: '' });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const doEditGroup = createAsyncThunk(
  'groups/edit',
  async (GroupEditPayload: UpdateGroupPayload, { rejectWithValue }) => {
    try {
      await editGroup(GroupEditPayload);
      return await getAllGroups({ career: '', specialty: '' });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const doRemoveGroup = createAsyncThunk(
  'groups/remove',
  async (id: string, { rejectWithValue }) => {
    try {
      await removeGroup(id);
      return await getAllGroups({ career: '', specialty: '' });
    } catch (error: unknown) {
      return await rejectWithValue(error);
    }
  }
);

const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGroupsList.pending, (state) => {
      state.listGroupStatus = SliceStatus.Updating;
    });
    builder.addCase(doGroupsList.fulfilled, (state, action) => {
      state.groups = action.payload;
      state.listGroupStatus = SliceStatus.Fulfilled;
    });
    builder.addCase(doGroupsList.rejected, (state) => {
      state.listGroupStatus = SliceStatus.Error;
    });
    builder.addCase(doCreateGroup.pending, (state) => {
      state.groupStatus = SliceStatus.Updating;
    });
    builder.addCase(doCreateGroup.fulfilled, (state, action) => {
      state.groups = action.payload;
      state.groupStatus = SliceStatus.Fulfilled;
    });
    builder.addCase(doCreateGroup.rejected, (state) => {
      state.groupStatus = SliceStatus.Error;
    });
    builder.addCase(doEditGroup.pending, (state) => {
      state.groupStatus = SliceStatus.Updating;
    });
    builder.addCase(doEditGroup.fulfilled, (state, action) => {
      state.groups = action.payload;
      state.groupStatus = SliceStatus.Fulfilled;
    });
    builder.addCase(doEditGroup.rejected, (state) => {
      state.groupStatus = SliceStatus.Error;
    });
    builder.addCase(doRemoveGroup.pending, (state) => {
      state.groupStatus = SliceStatus.Updating;
    });
    builder.addCase(doRemoveGroup.fulfilled, (state, action) => {
      state.groups = action.payload;
      state.groupStatus = SliceStatus.Fulfilled;
    });
    builder.addCase(doRemoveGroup.rejected, (state) => {
      state.groupStatus = SliceStatus.Error;
    });
  },
});

export default groupsSlice.reducer;
