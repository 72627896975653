import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getActiveContractKinds } from 'api/contract.api';
import { SliceStatus } from 'constants/enums/slices';
import { ContractKind } from 'types/contract';

export interface ContractKindSlice {
  contractKinds: ContractKind[];
  contractKindsSliceStatus: SliceStatus;
}

const initialState: ContractKindSlice = {
  contractKinds: [],
  contractKindsSliceStatus: SliceStatus.Empty,
};

export const doContractKindsList = createAsyncThunk(
  'contractKind/list',
  async (_, { rejectWithValue }) => {
    try {
      return await getActiveContractKinds();
    } catch (error) {
      return await rejectWithValue(error);
    }
  }
);

const contractKindSlice = createSlice({
  name: 'contractKind',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doContractKindsList.pending, (state) => {
      state.contractKindsSliceStatus = SliceStatus.Updating;
    });
    builder.addCase(doContractKindsList.fulfilled, (state, action) => {
      state.contractKinds = action.payload.data;
      state.contractKindsSliceStatus = SliceStatus.Fulfilled;
    });
  },
});

export default contractKindSlice.reducer;
