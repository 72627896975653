import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { CycleListColumns } from './columns';
import { Table } from 'antd';
import { doCyclesRemove, doEditCycle } from 'store/slices/cyclesSlice';
import 'styles/sass/tables.scss';
import { FormDrawer } from 'components/common/Drawer/FormDrawer';
import { Cycle } from 'types/cycle';
import { CycleForm } from './CycleForm';
import { SliceStatus } from 'constants/enums/slices';
import { useState } from 'react';
import { useForm } from 'antd/es/form/Form';
import { generateCyclePayload } from 'hooks/cycleHooks';
import notificationHook from 'hooks/notificationHook';
import { ApiError } from 'types';
import statusMessages from 'statusMessages';
import { TableProps } from 'antd/lib/table';

const tableProps: TableProps<Cycle> = {
  bordered: true,
  tableLayout: 'fixed',
  size: 'small',
  pagination: { position: ['bottomCenter'], pageSize: 50 },
  className: 'table-ps',
};

export const CycleList = () => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const { cycles, cycleStatus } = useAppSelector((state) => state.cycle);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [cycleToEditId, setCycleToEditId] = useState<string>();

  const deleteHandler = (id: string) => {
    dispatch(doCyclesRemove(id))
      .unwrap()
      .then(() => {
        notificationHook({
          type: 'success',
          message: 'El registro se ha eliminado correctamente',
        });
      })
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          type: 'error',
          message: statusMessages.cycle[
            currentStatus ? currentStatus : 500
          ] as string,
        });
      });
  };

  const handleEditCycle = async (values: Cycle) => {
    if (cycleToEditId) {
      const newValues = await generateCyclePayload(values);
      dispatch(
        doEditCycle({
          ...newValues,
          cycleId: cycleToEditId,
        })
      )
        .unwrap()
        .then(() => {
          setOpenEditDrawer(false);
          notificationHook({
            type: 'success',
            message: 'El ciclo se edito con éxito!',
          });
        })
        .catch((error) => {
          const currentStatus = (error as ApiError).statusCode;
          notificationHook({
            type: 'error',
            message: statusMessages.cycle[
              currentStatus ? currentStatus : 500
            ] as string,
          });
        });
    }
  };

  return (
    <div data-cy="cycle-list">
      <Table
        {...tableProps}
        dataSource={cycles}
        columns={CycleListColumns({
          onDelete: (id) => deleteHandler(id),
          onOpenEditDrawer: (value) => setOpenEditDrawer(value),
          setItemToEditId: (id) => setCycleToEditId(id),
          form,
        })}
        rowKey="_id"
      />
      <FormDrawer<Cycle>
        open={openEditDrawer}
        title="Editar Ciclo"
        width={500}
        confirmText="Editar"
        cancelText="Cancelar"
        formMode="edit"
        formItems={CycleForm}
        loading={cycleStatus === SliceStatus.Updating}
        onConfirm={(values) => handleEditCycle(values)}
        onOpen={() => setOpenEditDrawer(true)}
        onClose={() => {
          setOpenEditDrawer(false);
        }}
        form={form}
        dataCy="edit-cycle"
      />
    </div>
  );
};
