import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAll } from 'api/devices.api';
import { SliceStatus } from 'constants/enums/slices';
import { Device } from 'types/devices';

export interface DeviceSlice {
  devices: Device[];
  status: SliceStatus;
}

const initialState: DeviceSlice = {
  devices: [],
  status: SliceStatus.Empty,
};

export const doList = createAsyncThunk(
  'devices/list',
  async (_, { rejectWithValue }) => {
    try {
      return await getAll();
    } catch (error) {
      return await rejectWithValue(error);
    }
  }
);

export const doClear = createAction('devices/clear', () => {
  return {
    payload: 'clear',
  };
});

const deviceSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doList.pending, (state) => {
      state.status = SliceStatus.Updating;
    });
    builder.addCase(doList.fulfilled, (state, action) => {
      state.devices = action.payload.data;
      state.status = SliceStatus.Fulfilled;
    });
    builder.addCase(doClear, (state) => {
      state.devices = [];
      state.status = SliceStatus.Empty;
    });
  },
});

export default deviceSlice.reducer;
