export default {
  201: 'Incidencia creada con éxito.',
  202: 'La razón de incidencia fue actualizada con éxito.',
  410: 'El empleado no existe',
  413: 'Usuario no encontrado.',
  414: 'La categoría de incidencia no existe.',
  404: 'Esta incidencia ya se encuentra registrada.',
  415: 'Hay incidencias con esta razón de incidencia.',
  416: 'No es posible eliminar esta categoría porque está protegida.',
  417: 'Es necesario especificar el año',
  421: 'La fecha recibida no es válida',
  427: 'Este tipo de incidencia ya existe.',
};
