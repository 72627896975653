import { Navigate } from 'react-router-dom';
import { useAppSelector } from 'hooks/reduxHooks';
import { authPaths, globalPaths } from 'constants/routePaths';
import { UserRoles } from 'constants/enums/roles';

interface RoleGuardProps {
  children: React.ReactNode;
  allowedRoles: UserRoles[];
}
const RoleGuard = ({ children, allowedRoles }: RoleGuardProps) => {
  const { user } = useAppSelector((state) => state.auth);
  if (!user || !user.token) {
    return <Navigate to={`${authPaths.basePath}/${authPaths.login}`} replace />;
  }
  if (!allowedRoles.includes(user.role)) {
    return <Navigate to={`${globalPaths.notFoundPage}`} replace />;
  }
  return children;
};

export default RoleGuard;
