import { CustomFormItem, FormQuestionTypes } from 'interfaces/form.interfaces';

export const holidayFormItems: CustomFormItem[] = [
  {
    name: 'date',
    label: 'Fecha',
    type: FormQuestionTypes.date,
    visibleOnCreate: true,
    visibleOnEdit: true,
    rules: [{ required: true, message: 'Por favor, ingresa la fecha' }],
  },
  {
    name: 'desc',
    label: 'Descripción',
    type: FormQuestionTypes.input,
    visibleOnCreate: true,
    visibleOnEdit: true,
    rules: [{ required: true, message: 'Por favor, ingresa la descripción' }],
    hasFeedback: true,
  },
];
