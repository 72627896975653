import { AppRouter } from 'router/AppRouter';
import { themeObject } from 'styles/themes/themeVariables';
import GlobalStyle from 'styles/GlobalStyle';
import GetFrontendConfig from 'hooks/getFrontConfig';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ConfigProvider } from 'antd';
import locale from 'antd/locale/es_ES';

function App() {
  const frontendConfig = GetFrontendConfig();

  return (
    <>
      {frontendConfig && (
        <GoogleOAuthProvider clientId={frontendConfig.google.authClientId}>
          <meta name="theme-color" content={themeObject['dark'].primary} />
          <GlobalStyle />
          <div className="App">
            <ConfigProvider
              locale={locale}
              theme={{
                token: {
                  colorPrimary: '#7a69f6',
                },
                components: {
                  Button: {
                    borderRadius: 10,
                    colorPrimary: '#7a69f6',
                    colorPrimaryHover: '#252461',
                    algorithm: false,
                    defaultColor: '#ff9591',
                    defaultHoverColor: '#fdb0ae',
                    defaultBorderColor: '#ff9591',
                    defaultHoverBorderColor: '#fdb0ae',
                    colorBgContainerDisabled: '#DDE8FD',
                    colorTextDisabled: 'white',
                    borderColorDisabled: '#DDE8FD',
                  },
                  Modal: {
                    titleColor: '#7a69f6',
                  },
                  List: {
                    colorPrimary: '#7a69f6',
                    colorFill: 'red',
                  },
                },
              }}
            >
              <AppRouter />
            </ConfigProvider>
          </div>
        </GoogleOAuthProvider>
      )}
    </>
  );
}

export default App;
