import { Col, Row } from 'antd';
import { PageTitle } from 'components/common/PageTitle/PageTitle';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { doCyclesList, doCreateCycle } from 'store/slices/cyclesSlice';
import 'styles/sass/tables.scss';
import 'styles/sass/employees/employees-list.scss';
import { FormDrawer } from 'components/common/Drawer/FormDrawer';
import { CycleList } from 'components/cycles/CycleList';
import { useState } from 'react';
import { CycleForm } from 'components/cycles/CycleForm';
import { Cycle } from 'types/cycle';
import { SliceStatus } from 'constants/enums/slices';
import { useForm } from 'antd/es/form/Form';
import notificationHook from 'hooks/notificationHook';
import { ApiError } from 'types';
import statusMessages from 'statusMessages';
import { LoadingCard } from 'components/common/LoadingCard';
import { ErrorResult } from 'components/common/ErrorResult';
import { generateCyclePayload } from 'hooks/cycleHooks';

const CyclesPage = () => {
  const dispatch = useAppDispatch();
  const [openCreateDrawer, setOpenCreateDrawer] = useState(false);
  const { listCycleStatus, cycleStatus } = useAppSelector(
    (state) => state.cycle
  );
  const [form] = useForm();

  if (listCycleStatus === SliceStatus.Empty) {
    dispatch(doCyclesList());
  }

  const handleCreateCycle = async (values: Cycle) => {
    const newValues = await generateCyclePayload(values);
    dispatch(doCreateCycle(newValues))
      .unwrap()
      .then(() => {
        setOpenCreateDrawer(false);
        notificationHook({
          type: 'success',
          message: 'El ciclo se creó con éxito!',
        });
      })
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          message: statusMessages.cycle[
            currentStatus ? currentStatus : 500
          ] as string,
          type: 'error',
        });
      });
  };

  return (
    <div id="cycles-list">
      <PageTitle>Ciclos</PageTitle>
      <Row>
        <Col xs={24}>
          <Row justify="space-between" align="middle">
            <Col>
              <h2 className="title">Lista de ciclos</h2>
            </Col>
            <Col>
              <FormDrawer<Cycle>
                open={openCreateDrawer}
                textButton="Crear ciclo"
                title="Crear Ciclo"
                width={500}
                confirmText="Crear"
                cancelText="Cancelar"
                formMode="create"
                formItems={CycleForm}
                loading={cycleStatus === SliceStatus.Updating}
                onConfirm={handleCreateCycle}
                onOpen={() => {
                  setOpenCreateDrawer(true);
                }}
                onClose={() => {
                  setOpenCreateDrawer(false);
                }}
                form={form}
                dataCy="create-cycle"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {listCycleStatus === SliceStatus.Updating && <LoadingCard />}
      {listCycleStatus === SliceStatus.Fulfilled && <CycleList />}
      {listCycleStatus === SliceStatus.Error && (
        <ErrorResult title="No se pudo obtener la lista de ciclos" />
      )}
    </div>
  );
};

export default CyclesPage;
