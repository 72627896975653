import dayjs from 'dayjs';
import * as Sentry from '@sentry/react';
import { Button, Col, DatePickerProps, Result, Row, Select } from 'antd';
import { PageTitle } from 'components/common/PageTitle/PageTitle';
import { useCallback, useEffect, useState } from 'react';
import { KardexHeader } from 'components/kardex/KardexHeader';
import { KardexCalendar } from 'components/kardex/KardexCalendar';
import { KardexIncidencesList } from 'components/kardex/KardexIncidencesList';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { doGetIncidenceCategories } from 'store/slices/incidenceSlice';
import { SliceStatus } from 'constants/enums/slices';
import { LoadingCard } from 'components/common/LoadingCard';
import { EmployeeIncidences } from 'types/incidences';
import {
  getEmployeeIncidencesByYear,
  getEmployeeWeeklyIncidences,
} from 'api/incidence.api';
import { useParams } from 'react-router-dom';
import { getOne } from 'api/user.api';
import { User } from 'types';
import { doEmployeeMe } from 'store/slices/employeeSlice';
import { kardexPaths } from 'constants/routePaths';
import KardexWeeklyCalendar from 'components/kardex/KardexWeeklyCalendar';
import Contract from 'types/contract';
import { getEmployeeContracts } from 'api/contract.api';
import { getInitialWeekRange } from 'hooks/kardexHooks';
import { EmployeeChecksListModal } from 'components/kardex/EmployeeChecksListModal';
import { readUser } from 'services/localStorage.service';
import { CheckType } from 'types/checks';
import { getEmployeeChecks } from 'api/checks.api';
import RenderPhotos from 'components/employees/profile/RenderPhotos';

const currentYear = dayjs().year();

const checksDatesRange: [start: dayjs.Dayjs, end: dayjs.Dayjs] = [
  dayjs().startOf('week'),
  dayjs().endOf('week'),
];

const KardexPage = () => {
  const { requestedEmployeeId } = useParams();
  const dispatch = useAppDispatch();
  const currentPath = location.pathname;
  const selfKardex = currentPath.includes(
    `${kardexPaths.base}/${kardexPaths.self}`
  );

  const [year, setYear] = useState(dayjs(`${currentYear}`));
  const [employeeIncidences, setEmployeeIncidences] =
    useState<EmployeeIncidences[]>();
  const [employeeData, setEmployeeData] = useState<User>();
  const [kardexMode, setKardexMode] = useState<'weekly' | 'yearly'>('weekly');
  const [employeeContracts, setEmployeeContracts] = useState<Contract[]>();
  const [weekRange, setWeekRange] = useState(getInitialWeekRange(new Date()));
  const [loadingIncidences, setLoadingIncidences] = useState(false);
  const [weeklyKardexDate, setWeeklyKardexDate] = useState(new Date());
  const [openChecksModal, setOpenChecksModal] = useState(false);
  const [loadingChecks, setLoadingChecks] = useState(false);
  const [checks, setChecks] = useState<CheckType[]>();

  const { incidenceCategories, incidenceCategoriesStatus } = useAppSelector(
    (state) => state.incidence
  );
  const { employee, employeeSliceStatus } = useAppSelector(
    (state) => state.employee
  );

  const getChecksList = useCallback(
    async (from: string, to: string) => {
      if (!requestedEmployeeId) {
        try {
          if (employee._id) {
            setLoadingChecks(true);
            const checksList = await getEmployeeChecks({
              from,
              to,
            });
            setChecks(checksList.data);
          }
        } catch (error) {
          Sentry.captureException(error);
        } finally {
          setLoadingChecks(false);
        }
      }
    },
    [employee._id, requestedEmployeeId]
  );

  const getEmployeeContractsList = useCallback(async () => {
    if (employeeData && employeeData._id) {
      const contracts = await getEmployeeContracts(employeeData._id);
      setEmployeeContracts(contracts.data);
    }
  }, [employeeData]);

  useEffect(() => {
    getEmployeeContractsList();
  }, [getEmployeeContractsList]);

  useEffect(() => {
    const startDate = checksDatesRange[0].format('YYYY-MM-DD');
    const endDate = checksDatesRange[0].format('YYYY-MM-DD');
    getChecksList(startDate, endDate);
  }, [getChecksList]);

  const kardexModeOptions = [
    {
      value: 'weekly',
      label: 'Semana',
    },
    {
      value: 'yearly',
      label: 'Año',
      disabled: !employeeContracts?.find(
        (contract) => contract.workDayKind === 'fija'
      ),
    },
  ];

  useEffect(() => {
    if (
      employeeSliceStatus === SliceStatus.Empty ||
      employee._id !== readUser()?._id
    ) {
      dispatch(doEmployeeMe());
    }
  }, [dispatch, employee._id, employeeSliceStatus]);

  useEffect(() => {
    if (incidenceCategoriesStatus === SliceStatus.Empty) {
      dispatch(doGetIncidenceCategories());
    }
  }, [dispatch, incidenceCategoriesStatus]);

  const getEmployeeData = useCallback(async () => {
    if (requestedEmployeeId) {
      const employeeData = await getOne(requestedEmployeeId);
      setEmployeeData(employeeData.data);
    }
    if (selfKardex) {
      setEmployeeData(employee);
    }
  }, [employee, requestedEmployeeId, selfKardex]);

  useEffect(() => {
    getEmployeeData();
  }, [getEmployeeData]);

  useEffect(() => {
    const weekRange = getInitialWeekRange(weeklyKardexDate);
    setWeekRange(weekRange);
  }, [weeklyKardexDate]);

  const getEmployeeIncidencesList = useCallback(async () => {
    try {
      setLoadingIncidences(true);
      let employeeId;
      if (requestedEmployeeId) {
        employeeId = requestedEmployeeId;
      }
      if (selfKardex) {
        employeeId = employee._id;
      }
      if (employeeId) {
        if (kardexMode === 'yearly') {
          const yearEmployeeIncidences = await getEmployeeIncidencesByYear({
            employeeId,
            year: year.year(),
          });
          setEmployeeIncidences(yearEmployeeIncidences.data);
          return;
        }
        if (kardexMode === 'weekly') {
          const weekEmployeeIncidences = await getEmployeeWeeklyIncidences({
            employee: employeeId,
            from: weekRange.from,
            to: weekRange.to,
          });
          setEmployeeIncidences(weekEmployeeIncidences.data);
          return;
        }
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoadingIncidences(false);
    }
  }, [
    employee._id,
    kardexMode,
    requestedEmployeeId,
    selfKardex,
    weekRange.from,
    weekRange.to,
    year,
  ]);

  useEffect(() => {
    getEmployeeIncidencesList();
  }, [getEmployeeIncidencesList]);

  const onYearChange: DatePickerProps['onChange'] = (date, dateString) => {
    if (date) {
      const selectedYear = dayjs(dateString as string);
      setYear(selectedYear);
      getEmployeeIncidencesList();
    }
  };

  const renderErrorResult = (title: string) => (
    <Result
      status="error"
      title={title}
      subTitle="Por favor vuelve a intentarlo más tarde."
      extra={
        <Button key="reload" onClick={() => window.location.reload()}>
          Reintentar
        </Button>
      }
    />
  );

  const onChecksRangeChange = (dates: dayjs.Dayjs[]) => {
    if (dates) {
      const startDate = dates[0].format('YYYY-MM-DD');
      const endDate = dates[1].format('YYYY-MM-DD');
      getChecksList(startDate, endDate);
    }
  };

  const isUpdating = [incidenceCategoriesStatus].includes(SliceStatus.Updating);

  return (
    <div id="employee-kardex">
      <PageTitle>Kardex</PageTitle>
      <Row>
        <Col xs={24}>
          <Row>
            <Col xs={20}>
              <Row align="middle" className="mb-3">
                <Col xs={8}>
                  <h1 className="title">Kardex del empleado</h1>
                </Col>
                <Col xs={4}>
                  <Select
                    options={kardexModeOptions}
                    className="common-select"
                    defaultValue={kardexMode}
                    onSelect={(value) => {
                      setKardexMode(value);
                    }}
                  />
                </Col>

                {employeeData && (
                  <Col xs={24}>
                    <KardexHeader
                      employee={employeeData}
                      year={year}
                      onYearChange={onYearChange}
                      mode={kardexMode}
                      setNewDate={(newDate) => {
                        setWeeklyKardexDate(newDate);
                      }}
                    />
                  </Col>
                )}
              </Row>
            </Col>
            {employeeData && employeeData.idEmployee && (
              <Col xs={4}>
                <RenderPhotos
                  employeeId={employeeData.idEmployee}
                  lastName={employeeData.lastName}
                  name={employeeData.name}
                  size="lg"
                />
              </Col>
            )}
          </Row>
          {isUpdating ? (
            <LoadingCard />
          ) : (
            <>
              <Row align="middle">
                {!requestedEmployeeId && checks && (
                  <Col>
                    <EmployeeChecksListModal
                      open={openChecksModal}
                      onClose={() => setOpenChecksModal(false)}
                      onOpen={() => setOpenChecksModal(true)}
                      datesRange={checksDatesRange}
                      onRangeChange={onChecksRangeChange}
                      loadingChecks={loadingChecks}
                      checks={checks}
                    />
                  </Col>
                )}
                <Col>
                  {incidenceCategories && employeeIncidences && (
                    <KardexIncidencesList
                      categories={incidenceCategories}
                      incidences={employeeIncidences}
                    />
                  )}
                </Col>
              </Row>
              {employeeIncidences && (
                <>
                  {kardexMode === 'weekly' ? (
                    <KardexWeeklyCalendar
                      incidences={employeeIncidences}
                      loading={loadingIncidences}
                      date={weeklyKardexDate}
                      setNewDate={(newDate) => {
                        setWeeklyKardexDate(newDate);
                      }}
                    />
                  ) : (
                    <KardexCalendar
                      incidences={employeeIncidences}
                      year={year.year().toString()}
                      loading={loadingIncidences}
                    />
                  )}
                </>
              )}

              {!loadingIncidences &&
                (!employee || !employeeIncidences || !incidenceCategories) &&
                renderErrorResult('Ocurrió un error al cargar el kardex')}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default KardexPage;
