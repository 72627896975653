import { Card, Col, Row, Tag } from 'antd';
import { LastActivityData } from 'components/devices/LastActivityData';
import { maxDeviceIdleMinutes } from 'constants/global';
import { getPassedMinutesFromDate } from 'hooks/getPassedMinutesFromDate';
import { DevicesCardProps } from 'interfaces/device.interfaces';
import { DeviceTag, Device } from 'types/devices';

export const Devices = ({ devices }: DevicesCardProps) => {
  return (
    <div id="devices">
      <Card
        className="card vertical-card"
        title={<h3 className="sub-title">Dispositivos</h3>}
      >
        <div className="devices-container">
          {devices.map((device: Device) => (
            <div key={device._id}>
              {device.status === true && (
                <Row
                  justify="center"
                  align="middle"
                  key={`device-${device._id}`}
                  className="device-row"
                >
                  <Col xl={8}>
                    <p className="serial-number">
                      <b>{device.serialNumber}</b>
                    </p>
                  </Col>
                  <Col xl={8} className="text-center">
                    <div className="device-status-container">
                      {getPassedMinutesFromDate(device.updatedAt) >
                      maxDeviceIdleMinutes ? (
                        <LastActivityData
                          date={device.updatedAt}
                          children={
                            <p className="offline-text-color">Desconectado</p>
                          }
                        />
                      ) : (
                        <p className="online-text-color">En línea</p>
                      )}
                    </div>
                  </Col>
                  <Col xl={8} className="text-center">
                    {device.tags?.map((tag: DeviceTag) => (
                      <div className="mt-1 text-center" key={`tag-${tag._id}`}>
                        <Tag color={tag.tagColor}>{tag.tagName}</Tag>
                      </div>
                    ))}
                  </Col>
                </Row>
              )}
            </div>
          ))}
        </div>
      </Card>
    </div>
  );
};
