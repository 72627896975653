import { httpApi } from 'api/http.api';
import { AxiosResponse } from 'axios';
import {
  CheckType,
  ChecksResponseType,
  GetEmployeeChecksPayload,
} from 'types/checks';
import { ReportFilters } from 'types/reports';

const controller = `check`;

export const getChecksList = (
  payload: ReportFilters,
  page: number,
  limit: number
): Promise<AxiosResponse<ChecksResponseType>> => {
  const params = new URLSearchParams({
    page: page.toString(),
    limit: limit.toString(),
  }).toString();

  return httpApi
    .post<ChecksResponseType>(`${controller}?${params}`, payload)
    .then((res) => res);
};

export const getEmployeeChecks = (
  payload: GetEmployeeChecksPayload
): Promise<AxiosResponse<CheckType[]>> =>
  httpApi
    .post<CheckType[]>(`${controller}/get-self-checks`, { ...payload })
    .then((res) => res);
