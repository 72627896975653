import { Card, Row, Col } from 'antd';
import { MonthHoliday } from 'interfaces/holidays.interfaces';
import { useCallback, useEffect, useState } from 'react';
import 'styles/sass/holidays.scss';

type HolidayCardType = {
  month: string;
  holidays: MonthHoliday[];
};

export const HolidayCard = ({ month, holidays }: HolidayCardType) => {
  const [monthHolidays, setMonthHolidays] = useState<MonthHoliday[]>();

  const getHolidays = useCallback(() => {
    if (holidays) {
      setMonthHolidays(holidays);
    }
  }, [holidays]);

  useEffect(() => {
    getHolidays();
  }, [getHolidays]);

  return (
    <Card size="small" title={month} className="common-card">
      {monthHolidays &&
        monthHolidays.map((holiday, index) => (
          <Row className="fullDate" key={`${month}-holiday-${index}`}>
            <Col className="date-number" xs={3}>
              {holiday.dayOfMonth}
            </Col>
            <Col className="date-name" xs={21}>
              {holiday.description}
            </Col>
          </Row>
        ))}
    </Card>
  );
};
