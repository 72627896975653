import { FilterOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Input, Row } from 'antd';
import dayjs from 'dayjs';
import * as GlobalStyles from 'pages/Global.styles';
import { doContractKindsList } from 'store/slices/contractKindSlice';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { SliceStatus } from 'constants/enums/slices';
import 'styles/sass/reports.scss';

type ReportFilterProps = {
  startDate: dayjs.Dayjs | null;
  endDate: dayjs.Dayjs | null;
  employee: string;
  contractType: string;
  handleStartDateChange: (date: dayjs.Dayjs | null) => void;
  handleEndDateChange: (date: dayjs.Dayjs | null) => void;
  updateData: () => void;
  resetFilters: () => void;
  showModal: () => void;
  handleEmployeeChange: (employee: string) => void;
  handleContractTypeChange: (value: string) => void;
};

export const ReportFilter = ({
  startDate,
  endDate,
  employee,
  contractType,
  handleStartDateChange,
  handleEndDateChange,
  updateData,
  resetFilters,
  showModal,
  handleEmployeeChange,
  handleContractTypeChange,
}: ReportFilterProps) => {
  const dispatch = useAppDispatch();

  const { contractKinds, contractKindsSliceStatus } = useAppSelector(
    (state) => state.contractKind
  );

  if (contractKindsSliceStatus === SliceStatus.Empty) {
    dispatch(doContractKindsList());
  }

  return (
    <Row justify="space-between" align="bottom" className="my-5">
      <Col xs={20}>
        <Row align="bottom" gutter={10}>
          <Col>
            <FilterOutlined
              className="primary-02-txt"
              style={{ fontSize: '20px', marginBottom: '4px' }}
            />
          </Col>
          <Col>
            <p className="label-filter">Desde:</p>
            <DatePicker
              placeholder="Fecha inicial"
              onChange={handleStartDateChange}
              value={startDate}
            />
          </Col>
          <Col>
            <p className="label-filter">Hasta:</p>
            <DatePicker
              placeholder="Fecha final"
              onChange={handleEndDateChange}
              value={endDate}
              id="endDatePicker"
            />
          </Col>
          <Col xs={5}>
            <p className="label-filter">Empleado:</p>
            <Input
              type="text"
              onChange={(e) => {
                handleEmployeeChange(e.target.value);
              }}
              value={employee}
              placeholder="No. Empleado o Nombre"
            />
          </Col>
          <Col xs={3}>
            <p className="label-filter">Tipo de contrato:</p>
            <GlobalStyles.SimpleSelect
              value={contractType}
              options={contractKinds.map((k) => {
                return { key: k.name, value: k.name, label: k.name };
              })}
              onChange={(value) => {
                handleContractTypeChange(value as string);
              }}
              placeholder="Tipo de contrato"
            />
          </Col>
          <Col>
            <Button
              title="Actualizar"
              icon={<ReloadOutlined />}
              className="outlined-icon"
              onClick={updateData}
              type="default"
            />
          </Col>
          <Col>
            <Button
              className="btn-link"
              id="clearSearchBtn"
              onClick={resetFilters}
              style={{ marginBottom: '0' }}
            >
              Limpiar filtros
            </Button>
          </Col>
        </Row>
      </Col>
      <Col>
        <Button type="primary" onClick={showModal}>
          Descargar Reporte
        </Button>
      </Col>
    </Row>
  );
};
