import { PageTitle } from 'components/common/PageTitle/PageTitle';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useState, useEffect } from 'react';
import { Device } from 'types/devices';
import { useNavigate, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Button, Col, Result, Row, Skeleton } from 'antd';
import { doList } from 'store/slices/devicesSlice';
import { DeviceDetail } from 'components/devices/DeviceDetail';
import { globalPaths } from 'constants/routePaths';
import { SliceStatus } from 'constants/enums/slices';

export const DeviceDetailPage = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { devices, status: deviceSliceStatus } = useAppSelector(
    (state) => state.device
  );
  const [device, setDevice] = useState<Device>();

  useEffect(() => {
    if (deviceSliceStatus === SliceStatus.Empty) {
      dispatch(doList());
    } else if (deviceSliceStatus === SliceStatus.Fulfilled) {
      const currentDevice = devices
        .filter((c) => c && c.serialNumber === params.sn)
        .at(0);
      if (currentDevice) {
        setDevice(currentDevice);
      } else {
        Sentry.captureMessage('Not able to find device');
      }
    } else {
      if (deviceSliceStatus !== SliceStatus.Updating) {
        Sentry.captureMessage('Not able to find a checksData');
      }
    }
  }, [dispatch, devices, deviceSliceStatus, params.sn]);

  return (
    <>
      <PageTitle>Dispositivos</PageTitle>
      {deviceSliceStatus === SliceStatus.Updating && !devices && (
        <Row>
          <Col xs={24}>
            <Skeleton active />
          </Col>
        </Row>
      )}

      {deviceSliceStatus === SliceStatus.Fulfilled && device && (
        <DeviceDetail device={device} />
      )}
      {!devices && deviceSliceStatus === SliceStatus.Fulfilled && (
        <Result
          status="error"
          title="Hubo un error al cargar los datos"
          subTitle="Por favor vuelve a intentarlo más tarde."
          extra={[
            <Button
              type="primary"
              key="back"
              onClick={() => navigate(globalPaths.basePath)}
            >
              Regresar
            </Button>,
            <Button key="reload" onClick={() => window.location.reload()}>
              Reintentar
            </Button>,
          ]}
        />
      )}
    </>
  );
};
