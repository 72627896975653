import dayjs from 'dayjs';

export const getStartEndOfMonth = () => {
  const startOfMonth = dayjs().startOf('month').format('YYYY-MM-DD');
  const endOfMonth = dayjs().endOf('month').format('YYYY-MM-DD');

  return {
    startOfMonth,
    endOfMonth,
  };
};

export const getStartEndOfMonthDayjs = () => {
  const dayjsStartOfMonth = dayjs().startOf('month');
  const dayjsEndOfMonth = dayjs().endOf('month');
  return {
    dayjsStartOfMonth,
    dayjsEndOfMonth,
  };
};

export const getStartOfDate = (date: dayjs.Dayjs) => {
  return dayjs(date).startOf('day').format('YYYY-MM-DD');
};

export const getEndOfDate = (date: dayjs.Dayjs) => {
  return dayjs(date).endOf('day').format('YYYY-MM-DD');
};
