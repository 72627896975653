import { CustomFormItem, FormQuestionTypes } from 'interfaces/form.interfaces';

export const CycleForm: CustomFormItem[] = [
  {
    label: 'Clave:',
    visibleOnEdit: true,
    visibleOnCreate: true,
    type: FormQuestionTypes.input,
    name: 'code',
    rules: [
      {
        required: true,
        message: 'Aún no llenas este campo',
      },
    ],
  },
  {
    label: 'Fechas de inicio y fin:',
    visibleOnEdit: true,
    visibleOnCreate: true,
    type: FormQuestionTypes.rangePicker,
    name: 'dates',
    rules: [
      {
        required: true,
        message: 'Aún no llenas este campo',
      },
    ],
  },
  {
    label: 'Activar:',
    visibleOnEdit: true,
    visibleOnCreate: true,
    type: FormQuestionTypes.switch,
    name: 'status',
    rules: [
      {
        required: true,
        message: 'Aún no llenas este campo',
      },
    ],
  },
];
