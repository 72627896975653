import { ColumnsType } from 'antd/es/table';
import { Tag } from 'antd';
import { TableDeletePopOver } from 'components/TableDeletePopOver/TableDeletePopOver';
import { DrawerModeListColumnProps } from 'interfaces/global.interfaces';
import { Subject } from 'types/subjects';
import { TableEditButton } from 'components/TableEditButton/TableEditButton';

export const SubjectListColumns = ({
  onDelete,
  form,
  onOpenEditDrawer,
  setItemToEditId,
}: DrawerModeListColumnProps) => {
  const dataCy = 'subjects-table';
  const openDrawer = (formValues: Subject) => {
    setItemToEditId(formValues._id);
    form.setFieldsValue(formValues);
    onOpenEditDrawer(true);
  };

  const columns: ColumnsType<Subject> = [
    {
      title: 'Materia',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Clave única',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Grado',
      dataIndex: 'grade',
      key: 'grade',
      width: 100,
    },
    {
      title: 'Especialidades',
      dataIndex: 'specialties',
      key: 'specialties',
      render: (specialties: string[]) => (
        <>
          {specialties.map((specialty, index) => (
            <Tag key={index} color="blue" className="mb-2">
              {specialty}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: 'Opciones',
      align: 'center',
      width: 150,
      render: (_, record: Subject, index) => {
        return (
          <div className="option-buttons" id={`drawer-${record.name}`}>
            <TableEditButton
              dataCy={`edit-subject-${record.code}`}
              onClick={() => {
                openDrawer(record);
              }}
            />
            <TableDeletePopOver
              id={record._id}
              deleteHandler={onDelete}
              dataCy={`${dataCy}-delete-btn-${index}`}
            />
          </div>
        );
      },
    },
  ];
  return columns;
};
