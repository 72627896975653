import { PageTitle } from 'components/common/PageTitle/PageTitle';
import { UploadContracts } from 'components/contracts/UploadContracts';

const UploadContractsPage = () => {
  return (
    <>
      <PageTitle>Subir contratos</PageTitle>
      <UploadContracts />
    </>
  );
};

export default UploadContractsPage;
