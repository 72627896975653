import { LoginForm } from 'components/auth/LoginForm/LoginForm';
import { PageTitle } from 'components/common/PageTitle/PageTitle';

const LoginPage = () => {
  return (
    <>
      <PageTitle>Ingresar</PageTitle>
      <LoginForm />
    </>
  );
};

export default LoginPage;
