import * as S from './Card.styles';

type Card = {
  title: string;
  content: JSX.Element;
};

export const CardComponent = ({ title, content }: Card) => {
  return (
    <>
      <S.Wrapper>
        <S.Card
          id="employees-new"
          title={<h2 className="title">{title}</h2>}
          padding="1.25rem"
        >
          {content}
        </S.Card>
      </S.Wrapper>
    </>
  );
};
