import { FormItemProps, FormInstance } from 'antd';

export enum FormQuestionTypes {
  input = 'input',
  rangePicker = 'rangePicker',
  switch = 'switch',
  list = 'list',
  selectUsers = 'selectUsers',
  selectCycles = 'selectCycles',
  selectCareers = 'selectCareers',
  selectSpecialties = 'selectSpecialties',
  selectMultipleCareerSpecialties = 'selectMultipleCareerSpecialties',
  selectSingleCareerSpecialty = 'selectSingleCareerSpecialty',
  numberInput = 'numberInput',
  date = 'date',
}

export interface CustomFormItem extends FormItemProps {
  name: string;
  label: string;
  type: FormQuestionTypes;
  visibleOnCreate: boolean;
  visibleOnEdit: boolean;
  form?: FormInstance;
  dataCy?: string;
}
