import { httpApi } from 'api/http.api';
import { AxiosResponse } from 'axios';
import { EventsResponseType } from 'types/events';
import { ReportFilters } from 'types/reports';

const controller = `event`;

export const getEventsList = (
  filters: ReportFilters,
  page: number,
  limit: number
): Promise<AxiosResponse<EventsResponseType>> => {
  const params = new URLSearchParams({
    page: page.toString(),
    limit: limit.toString(),
  }).toString();

  return httpApi
    .post<EventsResponseType>(`${controller}?${params}`, {
      ...filters,
    })
    .then((res) => res);
};
