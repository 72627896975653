import { useState } from 'react';
import { Col, Row, Drawer, Button } from 'antd';
import { countIncidencesByCategory } from 'hooks/incidencesCounter';
import { IncidenceCategoriesListProps } from 'interfaces/kardex';
import { IncidenceCategory } from 'types/incidences';
import 'styles/sass/kardex.scss';

export const KardexIncidencesList = ({
  categories,
  incidences,
}: IncidenceCategoriesListProps) => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => setOpen(true);
  const onClose = () => setOpen(false);

  return (
    <div id="incidences-list">
      <Row>
        <Col className="btn-list-incidences-wrap" xs={24}>
          <Button className="btn-link" onClick={showDrawer}>
            Ver resumen de claves
          </Button>
          <Drawer
            title="Resumen de claves"
            placement="bottom"
            closable
            onClose={onClose}
            open={open}
            height={230}
            className="drawer-list-incidences"
          >
            <Row className="card-incidences">
              {categories.map((category: IncidenceCategory) => (
                <Col xs={4} key={`${category.abbr}-incidence`}>
                  <Row>
                    <Col
                      className="category-incidence"
                      xs={2}
                      style={{ backgroundColor: category.color }}
                    >
                      <span
                        className="clave"
                        style={{ color: category.txtColor }}
                      >
                        {category.abbr}
                      </span>
                    </Col>
                    <Col
                      className="incidences-name"
                      xs={{ offset: 1, span: 21 }}
                    >
                      {category.desc}
                      <span className="count">
                        {countIncidencesByCategory({
                          incidences,
                          categoryAbbr: category.abbr,
                        })}
                      </span>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Drawer>
        </Col>
      </Row>
    </div>
  );
};
