import { Button, Col, Row } from 'antd';
import { BackButton } from 'components/common/BackButton/BackButton';
import { useParams } from 'react-router-dom';
import { ApiError } from '../../types';
import * as Sentry from '@sentry/react';
import { Group } from '../../types/groups';
import { Subject } from '../../types/subjects';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useState, useEffect, useCallback } from 'react';
import { doList } from 'store/slices/userSlice';
import { doGroupsList } from 'store/slices/groupsSlice';
import {
  doGroupScheduleList,
  doPublishGroupSchedule,
  clearGroupScheduleList,
} from 'store/slices/groupScheduleSlice';
import { exportGroupSchedule } from 'api/group-schedule.api';
import { doSubjectList } from 'store/slices/subjectsSlice';
import { SliceStatus } from 'constants/enums/slices';
import { PageTitle } from 'components/common/PageTitle/PageTitle';
import { GroupsSchedule } from 'components/groups/GroupsSchedule';
import { GroupsScheduleSubjects } from 'components/groups/GroupsScheduleSubjects';
import notificationHook from 'hooks/notificationHook';
import statusMessages from 'statusMessages';

export const GroupsSchedulePage = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const { users, status } = useAppSelector((state) => state.user);
  const { listGroupStatus, groups } = useAppSelector((state) => state.group);
  const { listGroupScheduleStatus, groupSchedules } = useAppSelector(
    (state) => state.groupSchedule
  );
  const { listSubjectStatus, subjects } = useAppSelector(
    (state) => state.subject
  );
  const [isPublishDisabled, setIsPublishDisabled] = useState(true);
  const [group, setGroup] = useState<Group | null>();
  const [subjectsList, setSubjectsList] = useState<Subject[] | null>();
  const [userOptions, setUserOptions] = useState<
    { label: string; value: string }[]
  >([]);

  if (status === SliceStatus.Empty) {
    dispatch(doList())
      .unwrap()
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          type: 'error',
          message: statusMessages.user[
            currentStatus ? currentStatus : 500
          ] as string,
        });
      });
  }

  useEffect(() => {
    const formattedUsers = users.map((user) => ({
      label: `${user.name} ${user.lastName}`,
      value: user._id,
    }));
    setUserOptions(formattedUsers);
  }, [users]);

  const getGroupScheduleInformation = useCallback(() => {
    if (listGroupStatus === SliceStatus.Empty) {
      dispatch(doGroupsList({ career: '', specialty: '' }))
        .unwrap()
        .catch((error) => {
          const currentStatus = (error as ApiError).statusCode;
          notificationHook({
            type: 'error',
            message: statusMessages.group[
              currentStatus ? currentStatus : 500
            ] as string,
          });
        });
    } else if (
      listGroupStatus === SliceStatus.Fulfilled &&
      params.id &&
      !group
    ) {
      const currentGroup: Group | undefined = groups
        .filter((g) => g && g._id === params.id)
        .at(0);
      if (currentGroup) {
        setGroup(currentGroup);
      } else {
        notificationHook({
          type: 'error',
          message: statusMessages.group[404] as string,
        });
        Sentry.captureMessage('Not able to find the group to assign schedule');
      }
    }
    if (listGroupScheduleStatus === SliceStatus.Empty && params.id) {
      dispatch(doGroupScheduleList(params.id))
        .unwrap()
        .catch((error) => {
          const currentStatus = (error as ApiError).statusCode;
          notificationHook({
            type: 'error',
            message: statusMessages.groupSchedule[
              currentStatus ? currentStatus : 500
            ] as string,
          });
        });
    }
    if (listSubjectStatus === SliceStatus.Empty && group) {
      dispatch(
        doSubjectList({ career: group.career, searchParam: group.specialty })
      )
        .unwrap()
        .catch((error) => {
          const currentStatus = (error as ApiError).statusCode;
          notificationHook({
            type: 'error',
            message: statusMessages.subject[
              currentStatus ? currentStatus : 500
            ] as string,
          });
        });
    } else if (
      listSubjectStatus === SliceStatus.Fulfilled &&
      listGroupScheduleStatus === SliceStatus.Fulfilled
    ) {
      const currentSubjects: Subject[] | undefined = subjects.filter(
        (s) =>
          s &&
          s.grade === group?.grade &&
          !groupSchedules.some((gs) => gs.subjectId === s._id)
      );
      if (currentSubjects) {
        if (
          currentSubjects.length === 0 &&
          groupSchedules.length > 0 &&
          groupSchedules.every(
            (schedule) => schedule.teacherId && schedule.teacherId.trim() !== ''
          ) &&
          group &&
          !group.isSchedulePublished
        ) {
          setIsPublishDisabled(false);
        }
        setSubjectsList(currentSubjects);
      } else {
        notificationHook({
          type: 'error',
          message: statusMessages.subject[404] as string,
        });
        Sentry.captureMessage('Not able to find the group to assign schedule');
      }
    }
  }, [
    params,
    listGroupStatus,
    listSubjectStatus,
    listGroupScheduleStatus,
    dispatch,
    group,
    groups,
    groupSchedules,
    subjects,
  ]);

  const publishGroupSchedule = () => {
    if (group) {
      dispatch(doPublishGroupSchedule(group?._id))
        .unwrap()
        .then(() => {
          notificationHook({
            type: 'success',
            message: '¡Se ha publicado el horario de grupo con éxito!',
          });
        })
        .catch((error) => {
          const currentStatus = (error as ApiError).statusCode;
          notificationHook({
            message: statusMessages.groupSchedule[
              currentStatus ?? 500
            ] as string,
            type: 'error',
          });
        });
    } else {
      notificationHook({
        message: statusMessages.groupSchedule[404] as string,
        type: 'error',
      });
    }
  };

  const handlePublishGroupSchedule = () => {
    notificationHook({
      message: 'Una vez publicado, no se puede modificar',
      description:
        'Revisa la información antes de publicar ya que una vez publicado el horario, no se podrá modificar',
      type: 'warning',
      duration: null,
      showConfirmBtn: true,
      confirmBtnLabel: 'Publicar',
      key: 'publish',
      onConfirm: () => {
        publishGroupSchedule();
      },
    });
  };

  const handleExportGroupSchedule = () => {
    try {
      if (group) {
        exportGroupSchedule(group._id).then((data) => {
          const fileName = `Horario_Grupo_${group.name}.xlsx`;
          const archivoUrl = window.URL.createObjectURL(new Blob([data.data]));
          const link = document.createElement('a');
          link.href = archivoUrl;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        });
      } else {
        notificationHook({
          message: statusMessages.group[404] as string,
          type: 'error',
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      const currentStatus = (error as ApiError).statusCode;
      notificationHook({
        message: statusMessages.groupSchedule[currentStatus ?? 500] as string,
        type: 'error',
      });
    }
  };

  useEffect(() => {
    dispatch(clearGroupScheduleList());
  }, [dispatch]);

  useEffect(() => {
    getGroupScheduleInformation();
  }, [getGroupScheduleInformation]);

  return (
    <>
      <PageTitle>Detalle del grupo</PageTitle>
      <Row justify="space-between">
        <Col>
          <Row align="bottom">
            <Col>
              <h2 className="title">Grupo {group?.code} -</h2>
            </Col>
            <Col className="">
              <h3 className="sub-title ml-2 mb-3">{group?.careerTitle}</h3>
            </Col>
          </Row>
          <Row>{group?.cycleCode}</Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <Button
                type="primary"
                className="ml-2"
                onClick={handleExportGroupSchedule}
              >
                Exportar horario
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                className="ml-2"
                data-cy="publish-button"
                disabled={isPublishDisabled}
                onClick={() => handlePublishGroupSchedule()}
              >
                Publicar
              </Button>
            </Col>
          </Row>
          <Row>
            {group?.isSchedulePublished ? (
              <p>Publicado. No se puede modificar.</p>
            ) : (
              <p>El horario tendrá efecto hasta que haya sido publicado.</p>
            )}
          </Row>
        </Col>
      </Row>
      <BackButton />
      <Row id="ScheduleCalendar" justify="center" gutter={[16, 16]}>
        <Col xs={18}>
          <GroupsSchedule
            loading={listGroupScheduleStatus === SliceStatus.Updating}
            groupSchedules={groupSchedules}
            userOptions={userOptions}
            isPublished={group?.isSchedulePublished ?? false}
          />
        </Col>
        <Col xs={6}>
          <GroupsScheduleSubjects
            subjects={subjectsList}
            group={group}
            groupSchedules={groupSchedules}
            userOptions={userOptions}
            isPublished={group?.isSchedulePublished ?? false}
          />
        </Col>
      </Row>
    </>
  );
};
