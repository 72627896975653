import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Modal,
  Result,
  Row,
  Select,
  Spin,
} from 'antd';
import type { UploadProps, UploadFile } from 'antd/es/upload/interface';
import { useEffect, useState } from 'react';
import Dragger from 'antd/es/upload/Dragger';
import { InboxOutlined, LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { presignPutExcel } from 'api/file.api';
import {
  UploadContractsResponse,
  getActiveContractKinds,
  uploadContracts,
} from 'api/contract.api';
import { formatISO } from 'date-fns';
import { Dayjs } from 'dayjs';
import { usersPaths } from 'constants/routePaths';
import { ContractKind } from 'types/contract';
import { UploadContractsInfo } from './UploadContractsInfo';
import { getContractKindTerm } from 'hooks/getContractKindTerm';
import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';
import notificationHook from 'hooks/notificationHook';
import statusMessages from 'statusMessages';
import { ApiError } from 'types';
import { BackButton } from 'components/common/BackButton/BackButton';
import 'styles/sass/contracts.scss';
import { DataFile } from 'types/file';

type UploadContractsValues = {
  startDate: Dayjs;
  endDate?: Dayjs;
};

export const UploadContracts = () => {
  const navigate = useNavigate();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  // const [uploading, setUploading] = useState(false);
  const [uploadingState, setUploadingState] = useState<
    'initial' | 'proccessing' | 'success' | 'error'
  >('initial');
  const [presignedURL, setPresignedURL] = useState('');
  const [selectedFile, setSelectedFile] = useState<File>();
  const [fileMeta, setFileMeta] = useState<DataFile>();
  const [fileName, setFileName] = useState<string>();
  const [showInvalidIdsModal, setShowInvalidIdsModal] = useState(false);
  const [invalidEmployeesData, setInvalidEmployeesData] =
    useState<UploadContractsResponse>();
  const [contractKinds, setContractKinds] = useState<ContractKind[]>();
  const [selectedContractKind, setSelectedContractKind] = useState<string>();
  const [contractKindTerm, setContractKindTerm] = useState({
    term: '',
    workDayKind: '',
  });
  const [form] = Form.useForm();

  useEffect(() => {
    if (contractKinds && selectedContractKind) {
      const contractKind = getContractKindTerm({
        contractKinds,
        kindId: selectedContractKind,
      });
      if (contractKind) {
        setContractKindTerm({
          term: contractKind?.term,
          workDayKind: contractKind?.workDayKind,
        });
      }
    }
  }, [contractKinds, selectedContractKind]);

  const props: UploadProps = {
    multiple: false,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: async (file) => {
      const isExcel = file.type === 'application/vnd.ms-excel';
      const isCSV = file.type === 'text/csv';
      const isXLSX =
        file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const isValidFileType = isExcel || isCSV || isXLSX; // Permitir archivos de Excel, CSV o XLSX
      if (!isValidFileType) {
        notificationHook({
          type: 'error',
          message: 'Debe ser un archivo de Excel.',
        });
        return false;
      }
      setFileList([file]);
      await presignPutExcel({
        mime: file.type,
      })
        .then((presigned) => {
          setPresignedURL(presigned.data.url);
          setSelectedFile(file);
          setFileName(presigned.data.fileId);
          setFileMeta({
            fileType: file.type,
            fileExtension: file.name.split('.')[1],
          });
        })
        .catch((error) => {
          const currentStatus = (error as ApiError).statusCode;
          notificationHook({
            message: statusMessages.file[
              currentStatus ? currentStatus : 500
            ] as string,
            type: 'error',
          });
          return false;
        });
    },
    fileList,
  };

  const showResponseResult = (response: UploadContractsResponse) => {
    const { invalidEmployeeIds, invalidEmployeeSchedules } = response;

    if (
      invalidEmployeeIds &&
      invalidEmployeeIds.length > 0 &&
      invalidEmployeeSchedules &&
      invalidEmployeeSchedules.length > 0
    ) {
      setInvalidEmployeesData({
        msg: 'No se pudieron crear los contratos debido a que algunos empleados ya tienen un horario que se solapa con el indicado y otros no fueron encontrados en la base de datos.',
        invalidEmployeeIds: response.invalidEmployeeIds,
        invalidEmployeeSchedules: response.invalidEmployeeSchedules,
      });
      setShowInvalidIdsModal(true);
      setUploadingState('error');
    } else if (invalidEmployeeIds && invalidEmployeeIds.length > 0) {
      setInvalidEmployeesData({
        msg: 'No se pudieron crear los contratos debido a que los siguientes números de empleado no fueron encontrados en la base de datos.',
        invalidEmployeeIds: response.invalidEmployeeIds,
      });
      setShowInvalidIdsModal(true);
      setUploadingState('error');
    } else if (
      invalidEmployeeSchedules &&
      invalidEmployeeSchedules.length > 0
    ) {
      setInvalidEmployeesData({
        msg: 'No se pudieron crear los contratos debido a que los siguientes números de empleado ya tienen un horario que se solapa con el indicado.',
        invalidEmployeeSchedules: response.invalidEmployeeSchedules,
      });
      setShowInvalidIdsModal(true);
      setUploadingState('error');
    } else {
      form.resetFields();
    }
  };

  const handleUpload = async (values: UploadContractsValues) => {
    if (selectedContractKind) {
      const payload = {
        startDate: new Date(
          formatISO(values.startDate.toDate(), {
            representation: 'date',
          })
        ),
        endDate: values.endDate
          ? new Date(
              formatISO(values.endDate.toDate(), {
                representation: 'date',
              })
            )
          : undefined,
        contractKind: selectedContractKind,
      };
      setUploadingState('proccessing');
      await fetch(presignedURL, {
        method: 'PUT',
        body: selectedFile,
      }).catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          type: 'error',
          message: statusMessages.files[
            currentStatus ? currentStatus : 500
          ] as string,
        });
        Sentry.captureException(error);
      });

      uploadContracts({
        idFile: `${fileName}.${fileMeta?.fileExtension}`,
        startDate: payload.startDate,
        endDate: payload.endDate,
        contractKind: selectedContractKind,
      })
        .then((response: AxiosResponse<UploadContractsResponse>) => {
          setFileList([]);
          showResponseResult(response.data);
          if (
            (response.data.invalidEmployeeIds &&
              response.data.invalidEmployeeIds.length > 0) ||
            (response.data.invalidEmployeeSchedules &&
              response.data.invalidEmployeeSchedules.length > 0)
          ) {
            setUploadingState('error');
          } else {
            setUploadingState('success');
          }
        })
        .catch((e) => {
          setUploadingState('error');
          Sentry.captureException(e);
        });
    }
  };

  const getContractKindList = async () => {
    await getActiveContractKinds()
      .then((res) => {
        setContractKinds(res.data);
      })
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          type: 'error',
          message: statusMessages.contract[
            currentStatus ? currentStatus : 500
          ] as string,
        });
        Sentry.captureException(error);
      });
  };

  useEffect(() => {
    getContractKindList();
  }, []);

  const onChangeContractType = (id: string) => {
    setSelectedContractKind(id);
  };

  const SuccessUploadResult = () => (
    <Card>
      <Result
        status="success"
        title="¡Éxito!"
        subTitle="Todos los contratos se subieron exitosamente."
        extra={[
          <Button type="primary" key="back" onClick={() => navigate(-1)}>
            Aceptar
          </Button>,
        ]}
      />
    </Card>
  );

  const ErrorUploadResult = () => (
    <Card>
      <Result
        status="error"
        title="¡Error!"
        subTitle="No fue posible subir los contratos. Por favor, inténtalo de nuevo más tarde."
        extra={[
          <Button type="primary" key="back" onClick={() => navigate(-1)}>
            Aceptar
          </Button>,
          <Button key="reload" onClick={() => window.location.reload()}>
            Reintentar
          </Button>,
        ]}
      />
    </Card>
  );

  const CardContent = () => (
    <div id="upload-contracts">
      {uploadingState === 'initial' || uploadingState === 'proccessing' ? (
        <>
          <Row justify="center">
            <Col className="example-upload-contract-container" xs={16}>
              <UploadContractsInfo />
            </Col>
          </Row>
          <Row justify="center">
            <Col xs={16}>
              <Alert
                type="warning"
                description={
                  <p>
                    Cuando elijas el tipo de contrato, asegúrate de que el
                    archivo que subas contenga solo contratos de ese tipo.
                    <br />
                    Esto es importante porque los contratos se cargarán con el
                    tipo seleccionado.
                    <br />
                    De esta manera, garantizamos que la información se maneje
                    correctamente.
                  </p>
                }
                message="¡Importante!"
                showIcon
              />
            </Col>
          </Row>
          <Spin
            tip="Subiendo contratos. Esto puede demorar algunos minutos. Por favor, no cierres esta pestaña hasta que termine el proceso."
            indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
            spinning={uploadingState === 'proccessing'}
          >
            <Row className="mt-10" align="middle" gutter={16}>
              <Col>
                <h3>Selecciona el tipo de contrato</h3>
              </Col>
              <Col>
                <Select
                  placeholder="Seleccionar tipo de contrato"
                  id="contractKindId"
                  onChange={(e) => {
                    onChangeContractType(e as string);
                  }}
                >
                  {contractKinds && (
                    <>
                      {contractKinds.map((contractKind: ContractKind) => (
                        <Select.Option
                          value={contractKind._id}
                          key={contractKind._id}
                        >
                          {contractKind.name}
                        </Select.Option>
                      ))}
                    </>
                  )}
                </Select>
              </Col>
            </Row>
            {selectedContractKind && (
              <>
                <div className="upload-dragger">
                  <Dragger {...props}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Haz clic o arrastra un archivo en esta área para subirlo
                    </p>
                    <p className="ant-upload-hint">
                      Recuerda subir en archivos unicamente <b>.csv</b>{' '}
                      <b>.xls</b> <b>.xlsx</b>
                    </p>
                  </Dragger>
                </div>
                <Row justify="center">
                  <Col xs={8}>
                    <Form
                      onFinish={handleUpload}
                      form={form}
                      labelCol={{ span: 8 }}
                    >
                      <Form.Item
                        label="Fecha de inicio"
                        name="startDate"
                        rules={[
                          {
                            required: true,
                            message: 'Selecciona la fecha de inicio',
                          },
                        ]}
                      >
                        <DatePicker format="DD/MM/YYYY" />
                      </Form.Item>
                      {contractKindTerm.term === 'temporal' && (
                        <Form.Item
                          label="Fecha de fin"
                          name="endDate"
                          rules={[
                            {
                              required: true,
                              message: 'Selecciona la fecha de fin',
                            },
                          ]}
                        >
                          <DatePicker format="DD/MM/YYYY" />
                        </Form.Item>
                      )}
                      <Form.Item>
                        <Row gutter={10} justify="end">
                          <Col>
                            <Button
                              type="default"
                              onClick={() => navigate(usersPaths.basePath)}
                              id="cancelBtn"
                              disabled={uploadingState === 'proccessing'}
                            >
                              Cancelar
                            </Button>
                          </Col>
                          <Col>
                            <Button
                              type="primary"
                              disabled={fileList.length === 0}
                              loading={uploadingState === 'proccessing'}
                              htmlType="submit"
                            >
                              {uploadingState === 'proccessing'
                                ? 'Subiendo'
                                : 'Subir'}
                            </Button>
                          </Col>
                        </Row>
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </>
            )}
          </Spin>
        </>
      ) : (
        <>
          {uploadingState === 'success' && <SuccessUploadResult />}
          {uploadingState === 'error' && <ErrorUploadResult />}
        </>
      )}
    </div>
  );

  const invalidIdsModal = () => (
    <Modal
      open={showInvalidIdsModal}
      title="Error al subir los contratos"
      width={600}
      onCancel={() => {
        setShowInvalidIdsModal(false);
      }}
      onOk={() => {
        setShowInvalidIdsModal(false);
      }}
      cancelText="Cerrar"
    >
      {invalidEmployeesData && (
        <>
          <p>{invalidEmployeesData.msg}</p>
          <Row style={{ marginTop: '20px' }}>
            <Col xs={{ offset: 1, span: 22 }}>
              {invalidEmployeesData.invalidEmployeeSchedules ? (
                <>
                  <ul>
                    {invalidEmployeesData.invalidEmployeeSchedules.map(
                      (schedule, index) => (
                        <li key={index}>
                          {`${schedule.employeeId}, día ${schedule.dayOfWeek}, de ${schedule.from} a ${schedule.to}`}
                        </li>
                      )
                    )}
                  </ul>
                  {invalidEmployeesData.invalidEmployeeIds && (
                    <>
                      <br />
                      <p>
                        Los siguientes números de empleado no fueron encontrados
                        en la base de datos
                      </p>
                      <br />
                      <ul>
                        {invalidEmployeesData.invalidEmployeeIds?.map(
                          (id: string, index: number) => (
                            <li key={index}>{id}</li>
                          )
                        )}
                      </ul>
                    </>
                  )}
                </>
              ) : (
                <ul>
                  {invalidEmployeesData.invalidEmployeeIds?.map(
                    (id: string, index: number) => <li key={index}>{id}</li>
                  )}
                </ul>
              )}
            </Col>
          </Row>
        </>
      )}

      <Row style={{ marginTop: '20px' }}>
        <Col>
          <p>Por favor, revisa los datos del archivo, e inténtalo de nuevo.</p>
        </Col>
      </Row>
    </Modal>
  );

  return (
    <div id="upload-contract-container">
      <h2 className="title">Subir contratos por excel</h2>
      <BackButton />
      <CardContent />
      {invalidIdsModal()}
    </div>
  );
};
