import { Button, Col, Result, Row, Skeleton } from 'antd';
import { getCategory } from 'api/incidence.api';
import { PageTitle } from 'components/common/PageTitle/PageTitle';
import IncidenceCategoryEditForm from 'components/incidences/category/IncidenceCategoryEditForm';
import { incidencePaths } from 'constants/routePaths';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IncidenceCategory } from 'types/incidences';

const IncidenceCategoryEditPage = () => {
  const [incidenceCategory, setIncidenceCategory] =
    useState<IncidenceCategory | null>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params.id) {
      setLoading(true);
      getCategory(params.id)
        .then((res) => {
          setIncidenceCategory(res.data);
          setLoading(false);
        })
        .catch(() => {
          setIncidenceCategory(null);
          setLoading(false);
        });
    }
  }, [params.id]);

  return (
    <>
      <PageTitle>Editar tipo incidencia</PageTitle>
      {loading && (
        <Row>
          <Col xs={24}>
            <Skeleton active />
          </Col>
        </Row>
      )}
      {incidenceCategory && (
        <IncidenceCategoryEditForm category={incidenceCategory} />
      )}
      {!loading && !incidenceCategory && (
        <Result
          status="error"
          title="Hubo un error al cargar el tipo de incidencia"
          subTitle="Por favor vuelve a intentarlo más tarde."
          extra={[
            <Button
              type="primary"
              key="back"
              onClick={() =>
                navigate(
                  `${incidencePaths.basePath}/${incidencePaths.catalogue}`
                )
              }
            >
              Regresar
            </Button>,
            <Button key="reload" onClick={() => window.location.reload()}>
              Reintentar
            </Button>,
          ]}
        />
      )}
    </>
  );
};

export default IncidenceCategoryEditPage;
