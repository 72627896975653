import React from 'react';
import * as S from './MainSider/MainSider.styles';
import { RightOutlined } from '@ant-design/icons';
import { useResponsive } from 'hooks/useResponsive';
import logo from 'assets/logo-prosinergia-wh.svg';
import { useAppSelector } from 'hooks/reduxHooks';
import { AdminRole } from 'constants/enums/roles';
import { globalPaths } from 'constants/routePaths';

interface SiderLogoProps {
  isSiderCollapsed: boolean;
  toggleSider: () => void;
}
export const SiderLogo: React.FC<SiderLogoProps> = ({
  isSiderCollapsed,
  toggleSider,
}) => {
  const { tabletOnly } = useResponsive();
  const { user } = useAppSelector((state) => state.auth);

  const img = logo;

  return (
    <S.SiderLogoDiv>
      {user && user.role !== AdminRole ? (
        <img src={img} alt="Designa" width={220} height={200} />
      ) : (
        <S.SiderLogoLink to={`${globalPaths.basePath}`}>
          <img src={img} alt="Designa" width={220} height={200} />
        </S.SiderLogoLink>
      )}
      {tabletOnly && (
        <S.CollapseButton
          shape="circle"
          size="small"
          $isCollapsed={isSiderCollapsed}
          icon={<RightOutlined rotate={isSiderCollapsed ? 0 : 180} />}
          onClick={toggleSider}
        />
      )}
    </S.SiderLogoDiv>
  );
};
