import RequireAuth from 'router/RequireAuth';
import MainLayout from 'components/layouts/main/MainLayout/MainLayout';
import { RoleProtectedLayoutProps } from 'interfaces/global.interfaces';
import loadable from '@loadable/component';
import RoleGuard from 'router/AuthGuard';

export const AuthLayout = loadable(
  () => import('components/layouts/AuthLayout/AuthLayout')
);

export const AuthProtectedLayout = () => (
  <RequireAuth>
    <MainLayout />
  </RequireAuth>
);
export const RoleProtectedLayout = ({
  allowedRoles,
}: RoleProtectedLayoutProps) => (
  <RequireAuth>
    <RoleGuard allowedRoles={allowedRoles}>
      <MainLayout />
    </RoleGuard>
  </RequireAuth>
);
