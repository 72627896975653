import { LoadingOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import { Col, DatePicker, Row, Spin, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { getEmployeeChecks } from 'api/checks.api';
import { SliceStatus } from 'constants/enums/slices';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useCallback, useEffect, useState } from 'react';
import { doEmployeeMe } from 'store/slices/employeeSlice';
import { CheckType } from 'types/checks';
import dayjs from 'dayjs';
import { dateForDisplay } from 'libs/dateFormaterPetition';

const datesRange: [start: dayjs.Dayjs, end: dayjs.Dayjs] = [
  dayjs().startOf('week'),
  dayjs().endOf('week'),
];

export const EmployeeSelfChecksPage = () => {
  const dispatch = useAppDispatch();
  const [loadingChecks, setLoadingChecks] = useState(false);
  const [checks, setChecks] = useState<CheckType[]>();

  const { employee, employeeSliceStatus } = useAppSelector(
    (state) => state.employee
  );

  const { RangePicker } = DatePicker;

  useEffect(() => {
    if (employeeSliceStatus === SliceStatus.Empty) {
      dispatch(doEmployeeMe());
    }
  }, [dispatch, employeeSliceStatus]);

  const getChecksList = useCallback(
    async (from: string, to: string) => {
      try {
        if (employee._id) {
          setLoadingChecks(true);
          const checksList = await getEmployeeChecks({
            from,
            to,
          });
          setChecks(checksList.data);
        }
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        setLoadingChecks(false);
      }
    },
    [employee._id]
  );

  useEffect(() => {
    const startDate = datesRange[0].format('YYYY-MM-DD');
    const endDate = datesRange[0].format('YYYY-MM-DD');
    getChecksList(startDate, endDate);
  }, [getChecksList]);

  const columns: ColumnsType<CheckType> = [
    {
      title: 'Fecha y hora',
      key: 'date',
      render: (_: string, record: CheckType) =>
        dateForDisplay(record.date.toString()),
    },
  ];

  const onRangeChange = (dates: dayjs.Dayjs[]) => {
    if (dates) {
      const startDate = dates[0].format('YYYY-MM-DD');
      const endDate = dates[1].format('YYYY-MM-DD');
      getChecksList(startDate, endDate);
    }
  };

  return (
    <div id="employee-self-checks-page">
      <Row>
        <Col xs={24}>
          <h1 className="title">Mis chequeos</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <RangePicker
            defaultValue={datesRange}
            format="DD/MMMM/YYYY"
            onChange={(dates) => {
              onRangeChange(dates as dayjs.Dayjs[]);
            }}
          />
        </Col>
      </Row>
      <Row className="mt-10">
        <Col xs={24}>
          <Spin
            spinning={loadingChecks}
            tip="Cargando..."
            indicator={<LoadingOutlined />}
          >
            <Table
              columns={columns}
              dataSource={checks}
              rowKey={'_id'}
              className="table-ps"
              bordered
              size="small"
            />
          </Spin>
        </Col>
      </Row>
    </div>
  );
};
