import styled from 'styled-components';
import { Card as CommonCard } from '../../components/common/Card/Card';
import { H1 } from '../../components/common/typography/H1/H1';

export const Wrapper = styled.div`
  margin-top: 1.875rem;
`;

export const Card = styled(CommonCard)`
  margin-bottom: 2rem;
`;

export const Title = styled(H1)`
  color: #35a0dc !important;
  margin-bottom: 1rem !important;
  margin-top: 1rem;
  font-size: 2rem !important;
  text-align: center;
`;
