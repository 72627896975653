import { CustomFormItem, FormQuestionTypes } from 'interfaces/form.interfaces';
import { missingFieldRule } from 'constants/global';

export const careerFormItems: CustomFormItem[] = [
  {
    name: 'title',
    label: 'Nombre de la carrera',
    type: FormQuestionTypes.input,
    visibleOnCreate: true,
    visibleOnEdit: true,
    rules: missingFieldRule,
  },
  {
    name: 'code',
    label: 'Clave única',
    type: FormQuestionTypes.input,
    visibleOnCreate: true,
    visibleOnEdit: true,
    rules: missingFieldRule,
  },
  {
    name: 'specialties',
    label: 'Especialidades',
    type: FormQuestionTypes.list,
    visibleOnCreate: true,
    visibleOnEdit: true,
  },
  {
    name: 'coordinators',
    label: 'Vincular empleados',
    type: FormQuestionTypes.selectUsers,
    visibleOnCreate: false,
    visibleOnEdit: true,
  },
];
