import { useAppSelector, useAppDispatch } from 'hooks/reduxHooks';
import { GroupsListColumns } from './GroupsListColumns';
import { TableProps } from 'antd/lib/table';
import { doRemoveGroup, doEditGroup } from 'store/slices/groupsSlice';
import { getAllGroups } from 'api/groups.api';
import { doListCareers } from 'store/slices/careersSlice';
import { FormDrawer } from 'components/common/Drawer/FormDrawer';
import { groupFormItems } from 'constants/forms/groups';
import { useForm } from 'antd/es/form/Form';
import notificationHook from 'hooks/notificationHook';
import { useState, useEffect } from 'react';
import { Table, Row, Col, Select } from 'antd';
import { Group } from 'types/groups';
import { ApiError } from 'types';
import { SliceStatus } from 'constants/enums/slices';
import statusMessages from 'statusMessages';
import 'styles/sass/tables.scss';

const tableProps: TableProps<Group> = {
  bordered: true,
  tableLayout: 'fixed',
  size: 'small',
  pagination: { position: ['bottomCenter'], pageSize: 50 },
  className: 'table-ps',
};

export const GroupsList = () => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [groupToEditId, setGroupToEditId] = useState<string>();
  const [selectedCareer, setSelectedCareer] = useState<string>('');
  const [selectedSpecialty, setSelectedSpecialty] = useState<string>('');
  const [filteredGroups, setFilteredGroups] = useState<Group[]>();
  const [specialtiesOptions, setSpecialtiesOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const { groups, groupStatus } = useAppSelector((state) => state.group);
  const { careers, careerStatus } = useAppSelector((state) => state.career);

  useEffect(() => {
    if (careerStatus === SliceStatus.Empty) {
      dispatch(doListCareers());
    }
  }, [careerStatus, dispatch]);

  useEffect(() => {
    const career = careers.find((career) => career._id === selectedCareer);
    const specialtiesOptions = career
      ? career.specialties.map((specialty) => {
          return { value: specialty, label: specialty };
        })
      : [];
    setSpecialtiesOptions(specialtiesOptions);
  }, [selectedCareer, careers]);

  const handleDeleteGroup = (id: string) => {
    dispatch(doRemoveGroup(id))
      .unwrap()
      .then(() => {
        notificationHook({
          type: 'success',
          message: 'El grupo se ha eliminado correctamente',
        });
      })
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          type: 'error',
          message: statusMessages.group[
            currentStatus ? currentStatus : 500
          ] as string,
        });
      });
  };

  const handleChangeCareers = async (career: string) => {
    setSelectedCareer(career);
    setSelectedSpecialty('');
    const filteredGroups = await getAllGroups({
      career,
      specialty: '',
    });
    setFilteredGroups(filteredGroups);
  };

  const handleSearchGroups = async (specialty: string) => {
    setSelectedSpecialty(specialty);
    const filteredGroups = await getAllGroups({
      career: selectedCareer,
      specialty,
    });
    setFilteredGroups(filteredGroups);
  };

  const handleEditGroup = async (values: Group) => {
    if (groupToEditId) {
      const payload = { payload: values, id: groupToEditId };
      dispatch(doEditGroup(payload))
        .unwrap()
        .then(() => {
          form.resetFields();
          notificationHook({
            type: 'success',
            message: '¡Se ha editado el grupo con éxito!',
          });
        })
        .catch((error) => {
          const currentStatus = (error as ApiError).statusCode;
          notificationHook({
            message: statusMessages.group[currentStatus ?? 500] as string,
            type: 'error',
          });
        })
        .finally(() => {
          setOpenEditDrawer(false);
        });
    }
  };

  return (
    <div data-cy="groups-list">
      <Row gutter={10}>
        <Col xs={5}>
          <p className="label-filter">Selecciona una carrera</p>
          <Select
            className="common-select"
            placeholder="Selecciona la carrera"
            value={selectedCareer}
            options={careers.map((c) => {
              return { value: c._id, label: c.title };
            })}
            onChange={(value) => handleChangeCareers(value)}
          />
        </Col>
        <Col xs={5}>
          <p className="label-filter">Selecciona una especialidad</p>
          <Select
            className="common-select"
            placeholder="Selecciona la especialidad"
            options={specialtiesOptions}
            value={selectedSpecialty}
            onChange={(value) => handleSearchGroups(value)}
          />
        </Col>
      </Row>
      <Table
        {...tableProps}
        dataSource={filteredGroups ? filteredGroups : groups}
        columns={GroupsListColumns({
          onDelete: (id) => handleDeleteGroup(id),
          onOpenEditDrawer: (value) => setOpenEditDrawer(value),
          setItemToEditId: (id) => setGroupToEditId(id),
          form,
        })}
        rowKey="_id"
      />
      <FormDrawer<Group>
        open={openEditDrawer}
        title="Editar Grupo"
        width={500}
        confirmText="Editar"
        cancelText="Cancelar"
        formMode="edit"
        formItems={groupFormItems}
        loading={groupStatus === SliceStatus.Updating}
        onConfirm={(values) => handleEditGroup(values)}
        onOpen={() => setOpenEditDrawer(true)}
        onClose={() => {
          setOpenEditDrawer(false);
        }}
        form={form}
        dataCy="edit-group"
      />
    </div>
  );
};
