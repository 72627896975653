export type { default as User } from './user';

export class ApiError extends Error {
  constructor(
    public statusCode: number,
    public message: string
  ) {
    super();
  }
}

export type ThemeType = 'light' | 'dark';

import { ReactNode } from 'react';

export type WithChildrenProps<T = undefined> = T extends undefined
  ? {
      children?: ReactNode;
    }
  : T & {
      children?: ReactNode;
    };

export type Severity = 'success' | 'error' | 'info' | 'warning';

export type AWSResponsePresignURLType = {
  url: string;
  fileId: string;
  fileName: string;
};

export type Json = {
  [key: string | number]: number | string | boolean | null;
};

export type ResponseGeneral = {
  ok: boolean;
};
export type ResponseSchedule = {
  msg: string;
  isValidSchedule: boolean;
};
