import dayjs from 'dayjs';
import {
  GroupSchedule,
  ScheduleDay,
  ScheduleComparison,
} from 'types/group-schedule';

export const compareNewSchedules = (values: GroupSchedule) => {
  const overlapedNewSchedules: ScheduleComparison[] = [];
  values.schedule.forEach((schedule1, index1) => {
    values.schedule.forEach((schedule2, index2) => {
      if (index1 >= index2) return;

      if (schedule1.dayOfWeek === schedule2.dayOfWeek) {
        const initial1 = dayjs.utc(schedule1.initialHour, 'HH:mm:ssZ');
        const end1 = dayjs.utc(schedule1.endHour, 'HH:mm:ssZ');
        const initial2 = dayjs.utc(schedule2.initialHour, 'HH:mm:ssZ');
        const end2 = dayjs.utc(schedule2.endHour, 'HH:mm:ssZ');

        const isOverlap =
          (initial1.isBefore(end2) && end1.isAfter(initial2)) ||
          (initial1.isSame(initial2) && end1.isSame(end2));

        if (isOverlap) {
          overlapedNewSchedules.push({
            dayOfWeek: schedule1.dayOfWeek,
            firstSchedule: `de ${schedule1.initialHour} a ${schedule1.endHour}`,
            secondSchedule: `de ${schedule2.initialHour} a ${schedule2.endHour}`,
          });
        }
      }
    });
  });
  return overlapedNewSchedules;
};

export const compareExistingSchedules = (
  values: GroupSchedule,
  groupSchedules: GroupSchedule[]
) => {
  const overlappedExistingSchedules: ScheduleDay[] = [];
  values.schedule.forEach((newSchedule) => {
    groupSchedules.forEach((existingGroup) => {
      existingGroup.schedule.forEach((existingSchedule) => {
        if (existingSchedule.dayOfWeek === newSchedule.dayOfWeek) {
          const newInitial = dayjs.utc(newSchedule.initialHour, 'HH:mm:ssZ');
          const newEnd = dayjs.utc(newSchedule.endHour, 'HH:mm:ssZ');
          const existingInitial = dayjs.utc(
            existingSchedule.initialHour,
            'HH:mm:ssZ'
          );
          const existingEnd = dayjs.utc(existingSchedule.endHour, 'HH:mm:ssZ');

          const isOverlap =
            (newInitial.isBefore(existingEnd) &&
              newEnd.isAfter(existingInitial)) ||
            (newInitial.isSame(existingInitial) && newEnd.isSame(existingEnd));

          if (isOverlap) {
            overlappedExistingSchedules.push({
              dayOfWeek: existingSchedule.dayOfWeek,
              initialHour: existingSchedule.initialHour,
              endHour: existingSchedule.endHour,
            });
          }
        }
      });
    });
  });
  return overlappedExistingSchedules;
};
