import { Col, Row, Table, Button } from 'antd';

export const InfoUplad = () => {
  const data = [
    {
      key: '1',
      idEmployee: '999999',
      name: 'Michael',
      lastName: 'Lawson',
      email: 'michael.lawson@reqres.in',
      status: 'activo',
      role: 'administrador',
    },
  ];

  const columns = [
    {
      title: 'No. Empleado',
      dataIndex: 'idEmployee',
      key: 'idEmployee',
    },
    {
      title: 'Nombre(s)',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Apellido(s)',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Correo electrónico',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Estatus',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Rol',
      dataIndex: 'role',
      key: 'role',
    },
  ];

  const downloadTemplate = () => {
    const link = document.createElement('a');
    link.href = '/plantilla-subir-empleados.xlsx';
    link.setAttribute('download', 'plantilla-subir-empleados.xlsx');
    document.body.appendChild(link);
    link.click();
  };
  return (
    <div id="card-example-upload-employees">
      <Row justify="space-between" align="middle">
        <Col>
          <h3>Formato</h3>
        </Col>
        <Col>
          <Button onClick={downloadTemplate} type="primary">
            Descargar Plantilla
          </Button>
        </Col>
      </Row>
      <p>Recuerda que el excel debe de tener este formato </p>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        className="table-ps"
        bordered
        size="small"
      />
    </div>
  );
};
