import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Badge, Button, Col, Popover, Result, Row, Table } from 'antd';
import { ShowEmployeeData } from '../ShowEmployeeData';
import { useNavigate, useParams } from 'react-router-dom';
import Contract from '../../../types/contract';
import { ColumnsType, TableProps } from 'antd/es/table';
import { CalendarFilled, DeleteFilled, EyeFilled } from '@ant-design/icons';
import { RouteParam } from '../../../types/routeParam';
import { deleteOne } from 'api/contract.api';
import { contractsPaths, usersPaths } from 'constants/routePaths';
import * as Sentry from '@sentry/react';
import notificationHook from 'hooks/notificationHook';
import statusMessages from 'statusMessages';
import { ApiError } from 'types';
import { TableEditButton } from 'components/TableEditButton/TableEditButton';
import { BackButton } from 'components/common/BackButton/BackButton';
dayjs.extend(utc);

interface IUserContracts {
  employeeContracts: Contract[];
  setter: React.Dispatch<React.SetStateAction<Contract[] | null | undefined>>;
}

export const EmployeeContracts = ({
  employeeContracts,
  setter,
}: IUserContracts) => {
  const navigate = useNavigate();
  const { id } = useParams<RouteParam>();

  const onDeleteContract = (data: Contract) => {
    deleteOne(data._id)
      .then(() => {
        notificationHook({
          type: 'success',
          message: 'Contrato eliminado',
        });
        setter((pre) => {
          return pre ? pre.filter((contract) => data._id !== contract._id) : [];
        });
      })
      .catch((error: unknown) => {
        Sentry.captureException(error);
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          type: 'error',
          message: statusMessages.contract[
            currentStatus ? currentStatus : 500
          ] as string,
        });
      });
    return true;
  };

  const columns: ColumnsType<Contract> = [
    {
      title: 'Consecutivo o clave',
      align: 'center',
      render: (text: string, record: Contract) => (
        <div>{record.contractCode}</div>
      ),
    },
    {
      title: 'Tipo de contrato',
      dataIndex: 'contractType',
      align: 'center',
    },
    {
      title: 'Fecha de inicio',
      dataIndex: 'startDate',
      align: 'center',
      render: (date: string) => (
        <div>{dayjs.utc(date).format('DD-MM-YYYY')}</div>
      ),
    },
    {
      title: 'Fecha de termino',
      dataIndex: 'expirationDate',
      align: 'center',
      render: (date: string) => (
        <div>{date && <>{dayjs.utc(date).format('DD-MM-YYYY')}</>}</div>
      ),
    },
    {
      title: 'Estatus',
      align: 'center',
      render: (record: Contract) => (
        <div>
          {record.status == true ? (
            <Badge status="success" text="Activo" />
          ) : (
            <Badge status="error" text="Inactivo" />
          )}
        </div>
      ),
    },
    {
      title: 'Opciones',
      align: 'center',
      width: 300,
      render: (text: string, record: Contract, index: number) => (
        <div className="option-buttons">
          <Row gutter={10}>
            <Col title="Detalle">
              <Button
                id={'employeeContractDetail' + index}
                onClick={() =>
                  navigate(
                    `${contractsPaths.basePath}/${contractsPaths.contractDetail}/${record._id}`
                  )
                }
              >
                <EyeFilled />
              </Button>
            </Col>
            <Col title="Horario">
              <Button
                id={'employeeContractSchedule' + index}
                onClick={() =>
                  navigate(
                    `${usersPaths.basePath}/${usersPaths.contractSchedules}/${record._id}/${id}`
                  )
                }
              >
                <CalendarFilled />
              </Button>
            </Col>
            <Col title="Editar">
              <TableEditButton
                id={'editEmployeeContract' + index}
                onClick={() => {
                  navigate(
                    `${contractsPaths.basePath}/${contractsPaths.editContract}/${record._id}`
                  );
                }}
              />
            </Col>
            <Col title="Eliminar">
              <Popover
                content={
                  <Button
                    type="primary"
                    danger
                    block
                    onClick={() => {
                      onDeleteContract(record);
                    }}
                  >
                    Confirmar
                  </Button>
                }
                trigger="click"
                placement="left"
              >
                <Button
                  id={'deleteEmployeeContract' + index}
                  className="delete-icon"
                >
                  <DeleteFilled />
                </Button>
              </Popover>
            </Col>
          </Row>
        </div>
      ),
    },
  ];

  const tableProps: TableProps<Contract> = {
    bordered: true,
    pagination: { position: ['bottomCenter'] },
    tableLayout: 'fixed',
    className: 'table-ps',
    size: 'small',
  };

  return (
    <>
      <div id="employee-contracts-container">
        <h2 className="title">Contratos del empleado</h2>
        <BackButton />
        <ShowEmployeeData userId={id ?? ''} />
        {employeeContracts && employeeContracts.length > 0 ? (
          <>
            <Row justify="end" className="mt-5">
              <Col>
                <Button
                  htmlType="submit"
                  id="assignContractBtn"
                  onClick={() =>
                    navigate(
                      `${usersPaths.basePath}/${usersPaths.userContracts}/${usersPaths.newUser}/${id}`
                    )
                  }
                  type="primary"
                >
                  Asignar contrato
                </Button>
              </Col>
            </Row>
            <Table
              {...tableProps}
              dataSource={employeeContracts}
              columns={columns}
              id="employeeContractsTable"
              rowKey={'contractCode'}
            />
          </>
        ) : (
          <Result
            title="Este empleado no tiene contratos"
            className="result-info-contract"
            extra={
              <Row justify="center">
                <Col>
                  <Button
                    htmlType="submit"
                    id="assignContractBtn"
                    onClick={() =>
                      navigate(
                        `${usersPaths.basePath}/${usersPaths.userContracts}/${usersPaths.newUser}/${id}`
                      )
                    }
                    type="primary"
                    size="large"
                  >
                    Asignar contrato
                  </Button>
                </Col>
              </Row>
            }
          />
        )}
      </div>
    </>
  );
};
