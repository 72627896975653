import * as Sentry from '@sentry/react';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BaseForm } from 'components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from 'hooks/reduxHooks';
import { doLogin, doLoginWitGoogle } from 'store/slices/authSlice';
import notificationHook from 'hooks/notificationHook';
import * as Auth from 'components/layouts/AuthLayout/AuthLayout.styles';
import { LoginFormData } from 'types/auth';
import { loginFormInitValues } from 'constants/initialValues';
import { redirectAfterLogin } from 'hooks/redirectAfterLogin';
import { LoginResponse } from 'api/auth.api';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { Button, Col, Row } from 'antd';
import 'styles/sass/login.scss';
import { AxiosResponse } from 'axios';
import { ApiError } from 'types';
import statusMessages from 'statusMessages';

export const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (values: LoginFormData) => {
    setLoading(true);
    dispatch(doLogin(values))
      .unwrap()
      .then((res: AxiosResponse<LoginResponse>) => {
        if (res.data.user) {
          const navigatePath = redirectAfterLogin(res.data.user.role);
          navigate(navigatePath);
        }
      })
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          type: 'error',
          message: statusMessages.user[
            currentStatus ? currentStatus : 500
          ] as string,
        });
        setLoading(false);
      });
  };

  const processGoogleResponse = async (response: CredentialResponse) => {
    if (response && response.credential) {
      dispatch(doLoginWitGoogle({ token: response.credential }))
        .unwrap()
        .then((res: AxiosResponse<LoginResponse>) => {
          if (res.data.user) {
            const navigatePath = redirectAfterLogin(res.data.user.role);
            navigate(navigatePath);
          }
        })
        .catch((error) => {
          notificationHook({
            type: 'error',
            message: 'Ocurrió un error al iniciar sesión con Google',
          });
          Sentry.captureException(
            `Ocurrió un error al iniciar sesión con Google:  ${error}`
          );
        });
    }
  };

  return (
    <div className="form-wrapper">
      <BaseForm
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark="optional"
        initialValues={loginFormInitValues}
      >
        <h1 className="title">Ingresar</h1>
        <Row justify="center" className="mt-4 mb-5">
          <Col>
            <GoogleLogin
              onSuccess={(e) => processGoogleResponse(e)}
              onError={() => {
                notificationHook({
                  type: 'error',
                  message: 'Error al iniciar sesión con Google',
                  description: 'Por favor, inténtalo de nuevo más tarde.',
                });
                Sentry.captureException(
                  `Ocurrió un error al intentar iniciar sesión con Google <GoogleLogin />`
                );
              }}
              use_fedcm_for_prompt={false}
              useOneTap
            />
          </Col>
        </Row>
        <Col>
          <p>O ingresa con tu email y contraseña</p>
          <Auth.FormItem
            name="email"
            label="Email"
            rules={[
              { required: true, message: `Este campo es obligatorio` },
              {
                type: 'email',
                message: `Ingresa una dirección de correo válida`,
              },
            ]}
          >
            <Auth.FormInput placeholder={`Email`} />
          </Auth.FormItem>
          <Auth.FormItem
            label={`Contraseña`}
            name="password"
            rules={[{ required: true, message: `Este campo es obligatorio` }]}
          >
            <Auth.FormInputPassword placeholder={`Contraseña`} />
          </Auth.FormItem>
        </Col>
        <Row>
          <Col>
            <Link className="forget-link" to="/auth/forgot-password">
              Recuperar contraseña
            </Link>
          </Col>
        </Row>
        <Row justify="center" className="mt-5">
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              className="submit-btn"
            >
              Ingresar
            </Button>
          </Col>
        </Row>
      </BaseForm>
    </div>
  );
};
