import {
  Button,
  Input,
  Row,
  Space,
  Table,
  TableProps,
  Modal,
  List,
  Col,
} from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { doList } from 'store/slices/userSlice';
import { useState, useEffect } from 'react';
import { ApiError, User } from 'types';
import { ColumnsType } from 'antd/es/table';
import { Device } from 'types/devices';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { bulkCommand } from 'api/devices.api';
import { AvailableCommandsType, CommandsType } from '../../../types/commands';
import * as Sentry from '@sentry/react';
import { AdminRole } from 'constants/enums/roles';
import notificationHook from 'hooks/notificationHook';
import statusMessages from 'statusMessages';
import { SliceStatus } from 'constants/enums/slices';

type ModalProps = {
  deviceInfo: Device | undefined;
  closeModal: () => void;
};
export const ModalUserContent = ({ deviceInfo, closeModal }: ModalProps) => {
  const dispatch = useAppDispatch();
  const { users, status } = useAppSelector((state) => state.user);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<User[]>([]);
  const [enableBtn, setEnableBtn] = useState<boolean>(true);
  const [searchedText, setSearchedText] = useState<string>('');

  if (status === SliceStatus.Empty) {
    dispatch(doList());
  }

  const activeUsers = users.filter((e) => e.status);
  const hasSelected = selectedRowKeys.length > 0;

  useEffect(() => {
    if (hasSelected) {
      setEnableBtn(false);
    } else {
      setEnableBtn(true);
    }
  }, [hasSelected]);

  const columns: ColumnsType<User> = [
    {
      title: 'Id de Empleado',
      dataIndex: 'idEmployee',
      align: 'center',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => Number(a.idEmployee) - Number(b.idEmployee),
      onFilter: (value, record) => {
        return (
          record.name
            .toLowerCase()
            .includes(value.toString().toLocaleLowerCase()) ||
          record.idEmployee
            .toLowerCase()
            .includes(value.toString().toLocaleLowerCase()) ||
          record.lastName
            .toLowerCase()
            .includes(value.toString().toLocaleLowerCase()) ||
          record.email
            .toLowerCase()
            .includes(value.toString().toLocaleLowerCase())
        );
      },
      filteredValue: [searchedText],
      responsive: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
    },
    {
      title: 'Nombre(s)',
      dataIndex: 'name',
      align: 'center',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Apellido(s)',
      dataIndex: 'lastName',
      align: 'center',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
    },
    {
      title: 'Correo electrónico',
      dataIndex: 'email',
      align: 'center',
      responsive: ['lg', 'xl', 'xxl'],
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
  ];

  const onSelectChange = (selectedRows: User[]) => {
    setSelectedRows(selectedRows);
  };

  const getSelectedRows = (selectedRowKeys: React.Key[]) => {
    return selectedRowKeys
      .map((key) => activeUsers.find((user) => user.idEmployee === key))
      .filter((user) => user !== undefined) as User[];
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[]) => {
      const selectedRows = getSelectedRows(selectedRowKeys);
      setSelectedRowKeys(selectedRowKeys);
      onSelectChange(selectedRows);
    },
  };

  const tableProps: TableProps<User> = {
    bordered: true,
    pagination: { position: ['bottomCenter'] },
    tableLayout: 'fixed',
    size: 'small',
    rowSelection: rowSelection,
    className: 'table-ps',
  };

  const contentListModal = () => {
    return (
      <List
        itemLayout="horizontal"
        dataSource={selectedRows}
        className="mt-5"
        bordered
        size="small"
        pagination={{ align: 'center', pageSize: 10, position: 'bottom' }}
        renderItem={(item, index) => (
          <List.Item
            key={`${item.idEmployee}-${index}`}
            className="list-modal-devices"
          >
            <span className="primary-02-txt mr-2">{item.idEmployee}</span>
            {`${item.name}`}
          </List.Item>
        )}
      />
    );
  };
  const constructCommand = async (command: AvailableCommandsType) => {
    if (selectedRows && selectedRows.length > 0) {
      // Add users
      const payloadArray: CommandsType[] = selectedRows.map((element) => ({
        serialNumber: deviceInfo?.serialNumber ?? '',
        command,
        employeeId: Number(element.idEmployee),
        fullName: `${element.name} ${element.lastName}`,
        admin: element.role === AdminRole,
        status: element.status,
      }));
      let payloadBd: CommandsType[] = [];
      let payloadFp: CommandsType[] = [];
      let payloadBp: CommandsType[] = [];
      if (command === 'insertUser') {
        // Sync bio data
        payloadBd = selectedRows.map((element) => ({
          serialNumber: deviceInfo?.serialNumber ?? '',
          command: 'updateBioData',
          employeeId: Number(element.idEmployee),
          fullName: `${element.name} ${element.lastName}`,
          admin: element.role === AdminRole,
          status: element.status,
        }));

        for (const fid of [...Array(10).keys()]) {
          const tmpfps = selectedRows.map((element) => ({
            serialNumber: deviceInfo?.serialNumber ?? '',
            command: 'updateFingerPrint',
            employeeId: Number(element.idEmployee),
            fullName: `${element.name} ${element.lastName}`,
            admin: element.role === AdminRole,
            status: element.status,
            fid: fid,
          }));
          payloadFp = [...payloadFp, ...tmpfps];
        }
        payloadBp = selectedRows.map((element) => ({
          serialNumber: deviceInfo?.serialNumber ?? '',
          command: 'updateBioPhoto',
          employeeId: Number(element.idEmployee),
          fullName: `${element.name} ${element.lastName}`,
          admin: element.role === AdminRole,
          status: element.status,
        }));
      }
      try {
        const response = await bulkCommand([
          ...payloadFp,
          ...payloadBp,
          ...payloadBd,
          ...payloadArray,
        ]);
        const { falseCount, trueCount, message: messageRes } = response.data;
        if (falseCount && trueCount) {
          notificationHook({
            type: 'success',
            message: `Éxito: ${messageRes} Correctos:${trueCount}, Incorrectos:${falseCount}`,
          });
        } else {
          notificationHook({
            type: 'success',
            message: `Éxito: ${messageRes}`,
          });
        }
      } catch (error) {
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          type: 'error',
          message: statusMessages.devices[
            currentStatus ? currentStatus : 500
          ] as string,
        });
        Sentry.captureException(error);
      }
      closeModal();
    }
  };

  const info = () => {
    Modal.confirm({
      title: `Se agregarán al dispositivo: "${deviceInfo?.configs.DeviceName}", los siguientes usuarios: (${selectedRows.length})`,
      width: '35vw',
      icon: <ExclamationCircleOutlined className="success-txt" />,
      content: contentListModal(),
      onOk() {
        constructCommand('insertUser');
      },
      className: 'modal-info',
    });
  };

  const warning = () => {
    Modal.confirm({
      title: `Se eliminiarán del dispositivo: "${deviceInfo?.configs.DeviceName}", los siguientes usuarios: (${selectedRows.length})`,
      icon: <ExclamationCircleOutlined className="error-txt" />,
      width: '35vw',
      okType: 'primary',
      okText: 'Eliminar',
      content: contentListModal(),
      onOk() {
        constructCommand('deleteUser');
      },
      className: 'modal-warning',
    });
  };
  return (
    <>
      <Row justify="space-between" align="middle" className="mb-5">
        <Space>
          {deviceInfo && (
            <>
              <span className="subtitle-modal">Dispositivo:</span>
              <h2>{deviceInfo.configs.DeviceName}</h2>

              <span className="subtitle-modal ml-5">Número de Serie:</span>
              <h2>{deviceInfo.serialNumber}</h2>
            </>
          )}
        </Space>
        <Col>
          <Row gutter={10}>
            <Col>
              <Button
                id="removeUserFromDevice"
                type="default"
                onClick={warning}
                disabled={enableBtn}
              >
                Eliminar del Dispositivo
              </Button>
            </Col>
            <Col>
              <Button
                id="addUsertoDevice"
                type="primary"
                onClick={info}
                disabled={enableBtn}
              >
                Agregar al Dispositivo
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Input.Search
        placeholder="Buscar por Id de Empleado, Nombre, Apellido y Correo electrónico"
        onSearch={(value) => {
          setSearchedText(value);
        }}
        onChange={({ target }) => {
          setSearchedText(target.value);
        }}
        className="search-btn"
      />
      <Table
        {...tableProps}
        dataSource={activeUsers}
        columns={columns}
        id="employeesTableModal"
        rowKey={'idEmployee'}
      />
    </>
  );
};
