import { httpApi } from 'api/http.api';
import { ContractSchedule } from 'types/contract';
import { NewSchedule } from 'types/schedule';
import { AxiosResponse } from 'axios';
import { ResponseGeneral, ResponseSchedule } from 'types';

const controller = `schedule`;

export const getContractSchedules = (
  contractId: string
): Promise<AxiosResponse<ContractSchedule[]>> =>
  httpApi
    .get<
      ContractSchedule[]
    >(`${controller}/getContractSchedules/${contractId}`, {})
    .then((res) => res);

export const newSchedule = (
  payload: NewSchedule
): Promise<AxiosResponse<ResponseSchedule>> =>
  httpApi
    .post<ResponseSchedule>(`${controller}`, { ...payload })
    .then((res) => res);

export const removeSchedule = (
  scheduleId: string
): Promise<AxiosResponse<ResponseGeneral>> =>
  httpApi
    .delete<ResponseGeneral>(`${controller}/${scheduleId}`, {})
    .then((res) => res);
