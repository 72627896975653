import {
  Button,
  Divider,
  Input,
  Row,
  Space,
  Table,
  TableProps,
  Modal,
  List,
  Typography,
} from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { doList } from 'store/slices/userSlice';
import { useState, useEffect } from 'react';
import { ApiError, User } from 'types';
import { ColumnsType } from 'antd/es/table';
import { Device } from 'types/devices';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { bulkCommand } from 'api/devices.api';
import { CommandsType } from '../../../types/commands';
import * as Sentry from '@sentry/react';
import notificationHook from 'hooks/notificationHook';
import statusMessages from 'statusMessages';
import { SliceStatus } from 'constants/enums/slices';

type ModalProps = {
  deviceInfo: Device | undefined;
  closeModal: () => void;
};
export const ModalGetBiometricContent = ({
  deviceInfo,
  closeModal,
}: ModalProps) => {
  const dispatch = useAppDispatch();
  const { users, status } = useAppSelector((state) => state.user);
  const [usersData, setUsersData] = useState<User[]>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<User[]>([]);
  const [enableBtn, setEnableBtn] = useState<boolean>(true);
  const [searchedText, setSearchedText] = useState<string>('');

  if (status === SliceStatus.Empty) {
    dispatch(doList());
  }

  useEffect(() => {
    setUsersData(users);
  }, [users]);
  const hasSelected = selectedRowKeys.length > 0;

  useEffect(() => {
    if (hasSelected) {
      setEnableBtn(false);
    } else {
      setEnableBtn(true);
    }
  }, [hasSelected]);

  const columns: ColumnsType<User> = [
    {
      title: 'Id de Empleado',
      dataIndex: 'idEmployee',
      align: 'center',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => Number(a.idEmployee) - Number(b.idEmployee),
      onFilter: (value, record) => {
        return (
          record.name
            .toLowerCase()
            .includes(value.toString().toLocaleLowerCase()) ||
          record.idEmployee
            .toLowerCase()
            .includes(value.toString().toLocaleLowerCase()) ||
          record.lastName
            .toLowerCase()
            .includes(value.toString().toLocaleLowerCase()) ||
          record.email
            .toLowerCase()
            .includes(value.toString().toLocaleLowerCase())
        );
      },
      filteredValue: [searchedText],
      responsive: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
    },
    {
      title: 'Nombre(s)',
      dataIndex: 'name',
      align: 'center',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Apellido(s)',
      dataIndex: 'lastName',
      align: 'center',
      responsive: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
    },
    {
      title: 'Correo electrónico',
      dataIndex: 'email',
      align: 'center',
      responsive: ['lg', 'xl', 'xxl'],
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
  ];

  const onSelectChange = (selectedRows: User[]) => {
    setSelectedRows(selectedRows);
  };

  const getSelectedRows = (selectedRowKeys: React.Key[]) => {
    return selectedRowKeys
      .map((key) => usersData?.find((user) => user.idEmployee === key))
      .filter((user) => user !== undefined) as User[];
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[]) => {
      const selectedRows = getSelectedRows(selectedRowKeys);
      setSelectedRowKeys(selectedRowKeys);
      onSelectChange(selectedRows);
    },
  };

  const tableProps: TableProps<User> = {
    bordered: true,
    pagination: { position: ['bottomCenter'] },
    tableLayout: 'fixed',
    size: 'small',
    rowSelection: rowSelection,
    className: 'table-ps',
  };

  const contentListModal = () => {
    return (
      <List
        itemLayout="horizontal"
        dataSource={selectedRows}
        style={{ marginTop: 20 }}
        bordered
        size="small"
        pagination={{ align: 'center', pageSize: 10, position: 'bottom' }}
        renderItem={(item, index) => (
          <List.Item key={`${item.idEmployee}-${index}`}>
            <Typography.Text style={{ color: '#1677ff' }}>
              {item.idEmployee}
            </Typography.Text>{' '}
            {`${item.name} `}
          </List.Item>
        )}
      />
    );
  };
  const constructCommand = async () => {
    if (selectedRows && selectedRows.length > 0) {
      let payloadBd: CommandsType[] = [];
      let payloadFp: CommandsType[] = [];
      let payloadBp: CommandsType[] = [];
      // Sync bio data
      payloadBd = selectedRows.map((element) => ({
        serialNumber: deviceInfo?.serialNumber ?? '',
        command: 'getBioData',
        employeeId: Number(element.idEmployee),
      }));
      payloadFp = selectedRows.map((element) => ({
        serialNumber: deviceInfo?.serialNumber ?? '',
        command: 'getFinger',
        employeeId: Number(element.idEmployee),
      }));
      payloadBp = selectedRows.map((element) => ({
        serialNumber: deviceInfo?.serialNumber ?? '',
        command: 'getBioPhoto',
        employeeId: Number(element.idEmployee),
      }));
      try {
        const response = await bulkCommand([
          ...payloadFp,
          ...payloadBp,
          ...payloadBd,
        ]);
        const { falseCount, trueCount, message: messageRes } = response.data;
        if (falseCount && trueCount) {
          notificationHook({
            type: 'success',
            message: `Éxito: ${messageRes} Correctos:${trueCount}, Incorrectos:${falseCount}`,
          });
        } else {
          notificationHook({
            type: 'success',
            message: `Éxito: ${messageRes}`,
          });
        }
      } catch (error) {
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          type: 'error',
          message: statusMessages.devices[
            currentStatus ? currentStatus : 500
          ] as string,
        });
        Sentry.captureException(error);
      }
      closeModal();
    }
  };

  const info = () => {
    Modal.confirm({
      title: `Se obtendrán del dispositivo: "${deviceInfo?.configs.DeviceName}", los biometricos de siguientes usuarios: (${selectedRows.length})`,
      width: '35vw',
      icon: <ExclamationCircleOutlined style={{ color: 'darkgreen' }} />,
      content: contentListModal(),
      onOk() {
        constructCommand();
      },
    });
  };

  return (
    <>
      <Space>
        {deviceInfo && (
          <>
            Dispositivo: <h2>{deviceInfo.configs.DeviceName}</h2> Número de
            Serie:
            <h2> {deviceInfo.serialNumber}</h2>
          </>
        )}
      </Space>
      <Row justify="end">
        <Space size={[8, 16]}>
          <Button
            id="addUsertoDevice"
            type="primary"
            style={{ backgroundColor: 'darkgreen' }}
            onClick={info}
            disabled={enableBtn}
          >
            Obtener del Dispositivo
          </Button>
        </Space>
      </Row>
      <Divider />
      <Input.Search
        placeholder="Buscar por Id de Empleado, Nombre, Apellido y Correo electrónico)"
        style={{ margin: 8 }}
        onSearch={(value) => {
          setSearchedText(value);
        }}
        onChange={({ target }) => {
          setSearchedText(target.value);
        }}
      />
      <Table
        {...tableProps}
        dataSource={usersData}
        columns={columns}
        id="employeesTableModal"
        rowKey={'idEmployee'}
      />
    </>
  );
};
