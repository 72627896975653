import { Col, Row } from 'antd';
import { FormDrawer } from 'components/common/Drawer/FormDrawer';
import { PageTitle } from 'components/common/PageTitle/PageTitle';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useState } from 'react';
import { doSubjectList, doCreateSubject } from 'store/slices/subjectsSlice';
import 'styles/sass/tables.scss';
import 'styles/sass/employees/employees-list.scss';
import { SubjectList } from 'components/subjects/SubjectList';
import { subjectFormItems } from 'constants/forms/subject';
import { SliceStatus } from 'constants/enums/slices';
import { LoadingCard } from 'components/common/LoadingCard';
import { ErrorResult } from 'components/common/ErrorResult';
import { Subject } from 'types/subjects';
import { useForm } from 'antd/es/form/Form';
import notificationHook from 'hooks/notificationHook';
import statusMessages from 'statusMessages';
import { ApiError } from 'types';

const SubjectsPage = () => {
  const dispatch = useAppDispatch();
  const { listSubjectStatus, subjectStatus } = useAppSelector(
    (state) => state.subject
  );
  const [openCreateDrawer, setOpenCreateDrawer] = useState(false);
  const [form] = useForm();

  if (listSubjectStatus === SliceStatus.Empty) {
    dispatch(doSubjectList({ career: '', searchParam: '' }));
  }

  const onNewSubject = async (values: Subject) => {
    dispatch(doCreateSubject(values))
      .unwrap()
      .then(() => {
        form.resetFields();
        notificationHook({
          type: 'success',
          message: '¡Se ha creado la materia con éxito!',
        });
      })
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          message: statusMessages.subjects[currentStatus ?? 500] as string,
          type: 'error',
        });
      })
      .finally(() => {
        setOpenCreateDrawer(false);
      });
  };

  return (
    <div id="subjects-list">
      <PageTitle>Materias</PageTitle>
      <Row align="middle" justify="space-between">
        <Col>
          <h2 className="title" data-cy="subjects-title">
            Lista de materias
          </h2>
        </Col>
        <Col>
          <FormDrawer<Subject>
            open={openCreateDrawer}
            textButton="Crear materia"
            title="Crear materia"
            width={500}
            confirmText="Crear"
            cancelText="Cancelar"
            formMode="create"
            formItems={subjectFormItems}
            loading={subjectStatus === SliceStatus.Updating}
            onConfirm={onNewSubject}
            onOpen={() => {
              setOpenCreateDrawer(true);
            }}
            onClose={() => {
              setOpenCreateDrawer(false);
            }}
            form={form}
            dataCy={'create-subject'}
          />
        </Col>
      </Row>
      {listSubjectStatus === SliceStatus.Updating && <LoadingCard />}
      {listSubjectStatus === SliceStatus.Fulfilled && <SubjectList />}
      {listSubjectStatus === SliceStatus.Error && (
        <ErrorResult
          dataCy="subjects-list-error"
          title="No se pudo obtener la lista de materias"
        />
      )}
    </div>
  );
};

export default SubjectsPage;
