import { httpApi } from 'api/http.api';
import { AxiosResponse } from 'axios';
import { GroupSchedule } from 'types/group-schedule';
import { ResponseGeneral } from 'types';

const controller = `group-schedule`;

//GET
export const getGroupSchedule = (id: string): Promise<GroupSchedule[]> =>
  httpApi.get<GroupSchedule[]>(`${controller}/${id}`).then((res) => res?.data);
export const exportGroupSchedule = (
  id: string
): Promise<AxiosResponse<string | Blob>> =>
  httpApi
    .get<string | Blob>(`${controller}/export-schedule/${id}`, {
      responseType: 'blob',
    })
    .then((res) => res);

//POST
export const newGroupSchedule = (
  payload: GroupSchedule
): Promise<GroupSchedule> =>
  httpApi
    .post<GroupSchedule>(`${controller}/`, { ...payload })
    .then((res) => res?.data);
export const publishGroupSchedule = (
  id: string
): Promise<AxiosResponse<ResponseGeneral>> =>
  httpApi.post<ResponseGeneral>(`${controller}/${id}`).then((res) => res);

//PUT
export const editGroupSchedule = (
  payload: GroupSchedule
): Promise<AxiosResponse<GroupSchedule>> =>
  httpApi
    .put<GroupSchedule>(`${controller}/`, { ...payload })
    .then((res) => res);
