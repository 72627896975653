import { ColumnsType } from 'antd/es/table';
import { TableDeletePopOver } from 'components/TableDeletePopOver/TableDeletePopOver';
import { isPastDate } from 'hooks/compareDates';
import { Holiday } from 'interfaces/holidays.interfaces';
import { dateForDisplay } from 'libs/dateFormaterPetition';
import dayjs from 'dayjs';
import { DrawerModeListColumnProps } from 'interfaces/global.interfaces';
import { TableEditButton } from 'components/TableEditButton/TableEditButton';

export const HolidaysListColumns = ({
  onDelete,
  form,
  onOpenEditDrawer,
  setItemToEditId,
}: DrawerModeListColumnProps) => {
  const dataCy = 'holidays-table';
  const openDrawer = (formValues: Holiday) => {
    setItemToEditId(formValues._id);
    const modifiedValues = {
      ...formValues,
      date: dayjs(formValues.date),
    };
    form.setFieldsValue(modifiedValues);
    onOpenEditDrawer(true);
  };

  const columns: ColumnsType<Holiday> = [
    {
      title: 'Fecha',
      dataIndex: 'date',
      key: 'date',
      render: (date) => dateForDisplay(date, true),
      width: 300,
    },
    {
      title: 'Descripción',
      dataIndex: 'desc',
      key: 'desc',
    },
    {
      title: 'Opciones',
      align: 'center',
      width: 150,
      render: (_, record: Holiday, index) => {
        return (
          <div className="option-buttons" id={`drawer-${record.desc}`}>
            <TableEditButton
              dataCy={`${dataCy}-edit-btn-${index}`}
              onClick={() => {
                openDrawer(record);
              }}
            />
            <TableDeletePopOver
              id={record._id}
              deleteHandler={() => {
                onDelete(record._id);
              }}
              disabled={isPastDate(record.date as Date)}
              dataCy={`${dataCy}-delete-btn-${index}`}
            />
          </div>
        );
      },
    },
  ];

  return columns;
};
