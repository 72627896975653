import React, { ComponentProps } from 'react';
import { Form } from 'antd';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { BaseFormTitle } from 'components/common/forms/components/BaseFormTitle/BaseFormTitle';
import { BaseFormItem } from 'components/common/forms/components/BaseFormItem/BaseFormItem';
import { BaseFormList } from 'components/common/forms/components/BaseFormList/BaseFormList';
import notificationHook from 'hooks/notificationHook';

export type BaseFormProps = Omit<ComponentProps<typeof Form>, 'onFinish'> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFinish?: (values: any) => void;
};

export interface BaseFormInterface<T> extends React.FC<T> {
  Title: typeof BaseFormTitle;
  Item: typeof BaseFormItem;
  List: typeof BaseFormList;
  useForm: typeof Form.useForm;
  Provider: typeof Form.Provider;
}

export const BaseForm: BaseFormInterface<BaseFormProps> = ({
  onFinishFailed,
  layout = 'vertical',
  ...props
}) => {
  const onFinishFailedDefault = (error: ValidateErrorEntity<unknown>) => {
    notificationHook({
      type: 'error',
      message: `Error`,
      description: error.errorFields[0].errors[0],
    });
  };

  return (
    <Form
      onFinishFailed={onFinishFailed || onFinishFailedDefault}
      layout={layout}
      {...props}
    />
  );
};

BaseForm.Title = BaseFormTitle;
BaseForm.Item = BaseFormItem;
BaseForm.List = BaseFormList;
BaseForm.useForm = Form.useForm;
BaseForm.Provider = Form.Provider;
