import { Col, Row } from 'antd';
import { useState, useEffect } from 'react';
import { PageTitle } from 'components/common/PageTitle/PageTitle';
import { FormDrawer } from 'components/common/Drawer/FormDrawer';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { doListCareers, doNewCareer } from 'store/slices/careersSlice';
import { CareersList } from 'components/careers/CareersList';
import { careerFormItems } from 'constants/forms/careers';
import { SliceStatus } from 'constants/enums/slices';
import { Career } from 'types/careers';
import { useForm } from 'antd/es/form/Form';
import notificationHook from 'hooks/notificationHook';
import statusMessages from 'statusMessages';
import { ApiError } from 'types';
import { LoadingCard } from 'components/common/LoadingCard';
import { ErrorResult } from 'components/common/ErrorResult';

const CareersPage = () => {
  const dispatch = useAppDispatch();
  const { listCareerStatus, careerStatus } = useAppSelector(
    (state) => state.career
  );
  const [openCreateDrawer, setOpenCreateDrawer] = useState(false);
  const [form] = useForm();

  useEffect(() => {
    form.setFieldsValue({ specialties: ['Base'] });
  }, [form]);

  if (listCareerStatus === SliceStatus.Empty) {
    dispatch(doListCareers());
  }

  const onNewCareer = async (values: Career) => {
    dispatch(doNewCareer(values))
      .unwrap()
      .then(() => {
        form.resetFields();
        notificationHook({
          type: 'success',
          message: '¡Se ha creado la carrera con éxito!',
        });
      })
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          message: statusMessages.career[currentStatus ?? 500] as string,
          type: 'error',
        });
      })
      .finally(() => {
        setOpenCreateDrawer(false);
      });
  };

  return (
    <div id="careers-list">
      <PageTitle>Carreras</PageTitle>
      <Row>
        <Col xs={24}>
          <Row justify="space-between" align="middle">
            <Col>
              <h2 className="title" data-cy="careers-title">
                Lista de carreras
              </h2>
            </Col>
            <Col>
              <FormDrawer<Career>
                open={openCreateDrawer}
                textButton="Crear carrera"
                title="Crear Carrera"
                width={500}
                confirmText="Crear"
                cancelText="Cancelar"
                formMode="create"
                formItems={careerFormItems}
                loading={careerStatus === SliceStatus.Updating}
                onConfirm={onNewCareer}
                onOpen={() => {
                  setOpenCreateDrawer(true);
                }}
                onClose={() => {
                  setOpenCreateDrawer(false);
                }}
                form={form}
                dataCy="create-career"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {listCareerStatus === SliceStatus.Updating && <LoadingCard />}
      {listCareerStatus === SliceStatus.Fulfilled && <CareersList />}
      {listCareerStatus === SliceStatus.Error && (
        <ErrorResult
          dataCy="careers-list-error"
          title="No se pudo obtener la lista de carreras"
        />
      )}
    </div>
  );
};

export default CareersPage;
