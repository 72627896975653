import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getActiveContractKinds } from 'api/contract.api';
import { getAll } from 'api/devices.api';
import { getEmployeesWithSchedulesOfToday } from 'api/user.api';
import { SliceStatus } from 'constants/enums/slices';
import { ContractKind } from 'types/contract';
import { Device } from 'types/devices';
import { EmployeeWithScheduleOfToday } from 'types/user';

interface DashboardSlice {
  employeesList: EmployeeWithScheduleOfToday[];
  employeesListStatus: SliceStatus;
  contractKindsCount: ContractKind[];
  contractKindsCountSliceStatus: SliceStatus;
  devicesList: Device[];
  devicesListSliceStatus: SliceStatus;
}

const initialState: DashboardSlice = {
  employeesList: [],
  employeesListStatus: SliceStatus.Empty,
  contractKindsCount: [],
  contractKindsCountSliceStatus: SliceStatus.Empty,
  devicesList: [],
  devicesListSliceStatus: SliceStatus.Empty,
};

export const doGetDashboardContractKindsCount = createAsyncThunk(
  'dashboard/contractKindsCount',
  async (_, { rejectWithValue }) => {
    try {
      return await getActiveContractKinds();
    } catch (error) {
      return await rejectWithValue(error);
    }
  }
);

export const doGetDashboardEmployeesList = createAsyncThunk(
  'dashboard/employeesList',
  async (_, { rejectWithValue }) => {
    try {
      return await getEmployeesWithSchedulesOfToday();
    } catch (error) {
      return await rejectWithValue(error);
    }
  }
);

export const doGetDashboardDevicesList = createAsyncThunk(
  'dashboard/devicesList',
  async (_, { rejectWithValue }) => {
    try {
      return await getAll();
    } catch (error) {
      return await rejectWithValue(error);
    }
  }
);

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetDashboardContractKindsCount.pending, (state) => {
      state.contractKindsCountSliceStatus = SliceStatus.Updating;
    });
    builder.addCase(
      doGetDashboardContractKindsCount.fulfilled,
      (state, action) => {
        state.contractKindsCount = action.payload.data;
        state.contractKindsCountSliceStatus = SliceStatus.Fulfilled;
      }
    );
    builder.addCase(doGetDashboardEmployeesList.pending, (state) => {
      state.employeesListStatus = SliceStatus.Updating;
    });
    builder.addCase(doGetDashboardEmployeesList.fulfilled, (state, action) => {
      state.employeesList = action.payload.data;
      state.employeesListStatus = SliceStatus.Fulfilled;
    });
    builder.addCase(doGetDashboardDevicesList.pending, (state) => {
      state.devicesListSliceStatus = SliceStatus.Updating;
    });
    builder.addCase(doGetDashboardDevicesList.fulfilled, (state, action) => {
      state.devicesList = action.payload.data;
      state.devicesListSliceStatus = SliceStatus.Fulfilled;
    });
  },
});

export default dashboardSlice.reducer;
