import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCategories } from 'api/incidence.api';
import { SliceStatus } from 'constants/enums/slices';
import { IncidenceCategory } from 'types/incidences';

interface IncidenceSlice {
  incidenceCategories: IncidenceCategory[];
  incidenceCategoriesStatus: SliceStatus;
}

const initialState: IncidenceSlice = {
  incidenceCategories: [],
  incidenceCategoriesStatus: SliceStatus.Empty,
};

export const doGetIncidenceCategories = createAsyncThunk(
  'incidence/getCategories',
  async (_, { rejectWithValue }) => {
    try {
      return await getCategories();
    } catch (error) {
      return await rejectWithValue(error);
    }
  }
);

const incidenceSlice = createSlice({
  name: 'incidence',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doGetIncidenceCategories.pending, (state) => {
      state.incidenceCategoriesStatus = SliceStatus.Updating;
    });
    builder.addCase(doGetIncidenceCategories.fulfilled, (state, action) => {
      state.incidenceCategories = action.payload.data;
      state.incidenceCategoriesStatus = SliceStatus.Fulfilled;
    });
  },
});

export default incidenceSlice.reducer;
