import { httpApi } from 'api/http.api';
import { AxiosResponse } from 'axios';
import { User } from 'types';
import { UserUpdatePayloadType } from 'types/user';
import { EmployeeWithScheduleOfToday } from 'types/user';
import { ResponseGeneral } from 'types';

const controller = `user`;

export type badFormat = {
  reason: {
    msg: string;
    user: string;
  };
};

export type ResponseExcelType = {
  succesMsg: string;
  failedMsg: string;
};
export const createOne = (user: User): Promise<AxiosResponse<User>> =>
  httpApi.post<User>(`${controller}`, { ...user }).then((res) => res);

export const getOne = (id: string): Promise<AxiosResponse<User>> => {
  return httpApi.get<User>(`${controller}/${id}`, {}).then((res) => res);
};

export const registryByExcel = (
  idFile: string
): Promise<AxiosResponse<ResponseExcelType | badFormat[]>> => {
  return httpApi
    .get<ResponseExcelType | badFormat[]>(`${controller}/excel/${idFile}`)
    .then((res) => res);
};
export const udpateOne = (
  dataPayload: UserUpdatePayloadType
): Promise<AxiosResponse<User>> =>
  httpApi
    .put<User>(`${controller}/${dataPayload._id ?? '0'}`, { ...dataPayload })
    .then((res) => res);

export const getAllUsers = (filter = ''): Promise<AxiosResponse<User[]>> =>
  httpApi
    .get<User[]>(`${controller}?searchValue=${filter}`, {})
    .then((res) => res);

export const deleteUser = (
  userId: string
): Promise<AxiosResponse<ResponseGeneral>> =>
  httpApi
    .delete<ResponseGeneral>(`${controller}/${userId}`, {})
    .then((res) => res);

export const getEmployeesWithSchedulesOfToday = async (): Promise<
  AxiosResponse<EmployeeWithScheduleOfToday[]>
> =>
  httpApi
    .get<
      EmployeeWithScheduleOfToday[]
    >(`${controller}/get-employees-with-schedules-of-today`, {})
    .then((res) => res);

export const employeeMe = (): Promise<AxiosResponse<User>> => {
  return httpApi.get<User>(`${controller}/me`, {}).then((res) => res);
};

export const employeeExists = (
  idEmployee: string
): Promise<AxiosResponse<{ exists: boolean }>> => {
  return httpApi
    .get<{ exists: boolean }>(`${controller}/employeeExists/${idEmployee}`, {})
    .then((res) => res);
};

export const sendImageToQueue = (
  imageName: string
): Promise<AxiosResponse<JSON>> => {
  return httpApi
    .post<JSON>(`${controller}/imageQueue`, { imageName })
    .then((res) => res);
};
