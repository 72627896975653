import { httpApi } from 'api/http.api';
import { AxiosResponse } from 'axios';
import { Cycle } from 'types/cycle';
import { ResponseGeneral } from 'types';

const controller = `cycle`;

//GET
export const getAllCycles = (): Promise<AxiosResponse<Cycle[]>> =>
  httpApi.get<Cycle[]>(`${controller}`).then((res) => res);

//PUT
export const editCycle = (payload: Cycle): Promise<AxiosResponse<Cycle>> =>
  httpApi.put<Cycle>(`${controller}/`, { ...payload }).then((res) => res);

//POST
export const createCycle = (payload: Cycle): Promise<AxiosResponse<Cycle>> =>
  httpApi.post<Cycle>(`${controller}/`, { ...payload }).then((res) => res);

//DELETE
export const remove = (id: string): Promise<AxiosResponse<ResponseGeneral>> =>
  httpApi.delete<ResponseGeneral>(`${controller}/${id}`).then((res) => res);
