import { httpApi } from 'api/http.api';
import { AxiosResponse } from 'axios';
import { FrontendConfig } from 'interfaces/config';

const controller = `config`;

export const getFrontendConfig = (): Promise<AxiosResponse<FrontendConfig>> =>
  httpApi
    .get<FrontendConfig>(`${controller}/frontendConfig`, {})
    .then((res) => res);
