import { globalPaths } from 'constants/routePaths';
import { useAppDispatch } from 'hooks/reduxHooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doLogout } from 'store/slices/authSlice';
const LogOut = () => {
  const navigator = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(doLogout()).then(() => {
      navigator(globalPaths.basePath);
    });
  }, [dispatch, navigator]);

  return <div>LogOut</div>;
};

export default LogOut;
