import { Col, Row, Spin } from 'antd';
import { monthDays, months } from 'constants/kardex';
import {
  IncidenceProps,
  YearlyKardexCalendarProps,
} from '../../interfaces/kardex';
import { Month, MonthProps } from 'interfaces/kardex';
import { LoadingOutlined } from '@ant-design/icons';

export const KardexCalendar = ({
  year,
  incidences,
  loading,
}: YearlyKardexCalendarProps) => {
  const MonthDays = () => (
    <thead>
      <tr className="t-head-kardex">
        <th>{`\u00A0`}</th>
        {monthDays.map((day) => (
          <th key={`day-${day}`}>{day}</th>
        ))}
      </tr>
    </thead>
  );

  const Month = () => (
    <tbody className="t-body-kardex">
      {months.map((month: Month, index: number) => (
        <tr key={`row-${index}`}>
          <th className="th-month">{month.name}</th>
          <MonthDay month={month} />
        </tr>
      ))}
    </tbody>
  );

  const MonthDay = ({ month }: MonthProps) => (
    <>
      {monthDays.map((day) => (
        <td key={`day-${day}`}>
          <Incidence month={month} day={day} />
        </td>
      ))}
    </>
  );

  const Incidence = ({ month, day }: IncidenceProps) => (
    <>
      {incidences.map((incidence) => (
        <div key={`incidence-${incidence._id}`} className="td-incidence-wrap">
          {incidence.formattedStartDate ===
            `${year}-${month.monthNumber}-${day}` && (
            <div
              className="td-incidence"
              style={{
                backgroundColor: incidence.category.color,
                color: incidence.category.txtColor,
              }}
            >
              {incidence.category.abbr}
            </div>
          )}
        </div>
      ))}
    </>
  );
  return (
    <Row>
      <Col xs={24}>
        <Spin
          spinning={loading}
          tip="Cargando..."
          indicator={<LoadingOutlined />}
        >
          <table className="table-incidences" cellSpacing={0} cellPadding={0}>
            <tbody>
              <MonthDays />
              <Month />
            </tbody>
          </table>
        </Spin>
      </Col>
    </Row>
  );
};
