import dayjs from 'dayjs';
import { Button, Col, DatePicker, Modal, Row, Spin } from 'antd';
import 'styles/sass/kardex.scss';
import { CheckType } from 'types/checks';
import Table, { ColumnsType } from 'antd/es/table';
import { dateForDisplay } from 'libs/dateFormaterPetition';
import { LoadingOutlined } from '@ant-design/icons';

interface EmployeeChecksListModalProps {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  datesRange: [start: dayjs.Dayjs, end: dayjs.Dayjs];
  onRangeChange: (dates: dayjs.Dayjs[]) => void;
  loadingChecks: boolean;
  checks: CheckType[];
}

export const EmployeeChecksListModal = ({
  open,
  onClose,
  onOpen,
  datesRange,
  onRangeChange,
  loadingChecks,
  checks,
}: EmployeeChecksListModalProps) => {
  const { RangePicker } = DatePicker;

  const columns: ColumnsType<CheckType> = [
    {
      title: 'Fecha y hora',
      key: 'date',
      render: (_: string, record: CheckType) =>
        dateForDisplay(record.date.toString()),
    },
  ];

  return (
    <div id="employee-checks-list">
      <Row>
        <Col className="btn-list-incidences-wrap" xs={24}>
          <Button className="btn-link" onClick={onOpen}>
            Ver mis chequeos
          </Button>
        </Col>
        <Modal
          title={<h1 className="title">Mis chequeos</h1>}
          open={open}
          onClose={onClose}
          onCancel={onClose}
          footer={null}
          closable
          width={800}
        >
          <div id="employee-self-checks-page">
            <Row>
              <Col>
                <RangePicker
                  defaultValue={datesRange}
                  format="DD/MMMM/YYYY"
                  onChange={(dates) => {
                    onRangeChange(dates as dayjs.Dayjs[]);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Spin
                  spinning={loadingChecks}
                  tip="Cargando..."
                  indicator={<LoadingOutlined />}
                >
                  <Table
                    columns={columns}
                    dataSource={checks}
                    rowKey={'_id'}
                    className="table-ps"
                    bordered
                    size="small"
                  />
                </Spin>
              </Col>
            </Row>
          </div>
        </Modal>
      </Row>
    </div>
  );
};
