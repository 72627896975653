import { useAppSelector, useAppDispatch } from 'hooks/reduxHooks';
import 'styles/sass/tables.scss';
import { TableProps } from 'antd/lib/table';
import { Table, Row, Col, Select, Input } from 'antd';
import { Subject, SearchSubject } from 'types/subjects';
import { doEditSubject, doRemoveSubject } from 'store/slices/subjectsSlice';
import { doListCareers } from 'store/slices/careersSlice';
import { subjectFormItems } from 'constants/forms/subject';
import { SubjectListColumns } from './SubjectListColumns';
import { getAll } from 'api/subjects.api';
import { FormDrawer } from 'components/common/Drawer/FormDrawer';
import { useForm } from 'antd/es/form/Form';
import { ApiError } from 'types';
import notificationHook from 'hooks/notificationHook';
import { useEffect, useState } from 'react';
import { SliceStatus } from 'constants/enums/slices';
import statusMessages from 'statusMessages';

const tableProps: TableProps<Subject> = {
  bordered: true,
  tableLayout: 'fixed',
  size: 'small',
  pagination: { position: ['bottomCenter'], pageSize: 50 },
  className: 'table-ps',
};

export const SubjectList = () => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [subjectToEditId, setSubjectToEditId] = useState<string>();
  const [selectedCareer, setSelectedCareer] = useState<string>('');
  const [filteredSubjects, setFilteredSubjects] = useState<Subject[]>();
  const { subjects, subjectStatus } = useAppSelector((state) => state.subject);
  const { careers, careerStatus } = useAppSelector((state) => state.career);

  useEffect(() => {
    if (careerStatus === SliceStatus.Empty) {
      dispatch(doListCareers());
    }
  }, [careerStatus, dispatch]);

  const onChangeSearchParam = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const input = e.target.value;
    const searchPayload: SearchSubject = {
      career: selectedCareer,
      searchParam: input,
    };
    const filteredSubjects = await getAll(searchPayload);
    const newSubjects = filteredSubjects.data;
    setFilteredSubjects(newSubjects);
  };

  const handleSearchSubjects = async (career: string) => {
    setSelectedCareer(career);
    const searchPayload: SearchSubject = {
      career: career,
      searchParam: '',
    };
    const filteredSubjects = await getAll(searchPayload);
    const newSubjects = filteredSubjects.data;
    setFilteredSubjects(newSubjects);
  };

  const handleDeleteSubject = (id: string) => {
    dispatch(doRemoveSubject(id))
      .unwrap()
      .then(() => {
        notificationHook({
          type: 'success',
          message: 'La materia se ha eliminado correctamente',
        });
      })
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          type: 'error',
          message: statusMessages.subjects[
            currentStatus ? currentStatus : 500
          ] as string,
        });
      });
  };

  const handleEditSubject = async (values: Subject) => {
    if (subjectToEditId) {
      dispatch(doEditSubject({ ...values, _id: subjectToEditId }))
        .unwrap()
        .then(() => {
          form.resetFields();
          notificationHook({
            type: 'success',
            message: '¡Se ha editado la materia con éxito!',
          });
        })
        .catch((error) => {
          const currentStatus = (error as ApiError).statusCode;
          notificationHook({
            message: statusMessages.subjects[currentStatus ?? 500] as string,
            type: 'error',
          });
        })
        .finally(() => {
          setOpenEditDrawer(false);
        });
    }
  };

  return (
    <div data-cy="subjects-list">
      <Row>
        <Col xs={5}>
          <p className="label-filter">Selecciona la carrera:</p>
          <Select
            className="common-select"
            placeholder="Selecciona la carrera"
            value={selectedCareer}
            options={careers.map((c) => {
              return { value: c._id, label: c.title };
            })}
            onChange={(value) => {
              handleSearchSubjects(value);
            }}
          />
        </Col>
      </Row>
      <Input.Search
        placeholder="Buscar por nombre, grado o especialidades vinculadas"
        className="mt-5 search-btn"
        onChange={onChangeSearchParam}
      />
      <Table
        {...tableProps}
        dataSource={filteredSubjects ? filteredSubjects : subjects}
        columns={SubjectListColumns({
          onDelete: (id) => handleDeleteSubject(id),
          onOpenEditDrawer: (value) => setOpenEditDrawer(value),
          setItemToEditId: (id) => setSubjectToEditId(id),
          form,
        })}
        rowKey="_id"
      />
      <FormDrawer<Subject>
        open={openEditDrawer}
        title="Editar Materia"
        width={500}
        confirmText="Editar"
        cancelText="Cancelar"
        formMode="edit"
        formItems={subjectFormItems}
        loading={subjectStatus === SliceStatus.Updating}
        onConfirm={(values) => handleEditSubject(values)}
        onOpen={() => setOpenEditDrawer(true)}
        onClose={() => {
          setOpenEditDrawer(false);
        }}
        form={form}
        dataCy="edit-subject"
      />
    </div>
  );
};
