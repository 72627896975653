export type WeekScheduleType = {
  dayName: string;
  dayNumber: number;
};
export const dataWeekDays: WeekScheduleType[] = [
  { dayName: 'Lunes', dayNumber: 1 },
  { dayName: 'Martes', dayNumber: 2 },
  { dayName: 'Miércoles', dayNumber: 3 },
  { dayName: 'Jueves', dayNumber: 4 },
  { dayName: 'Viernes', dayNumber: 5 },
  { dayName: 'Sábado', dayNumber: 6 },
  { dayName: 'Domingo', dayNumber: 0 },
];
