import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { employeeMe } from 'api/user.api';
import { employeeInitialState } from '../../constants/employee';
import { SliceStatus } from 'constants/enums/slices';
import { User } from 'types';
import Contract from 'types/contract';
import { getEmployeeContracts } from 'api/contract.api';

interface EmployeeSlice {
  employee: User;
  employeeSliceStatus: SliceStatus;
  ownContracts: Contract[];
  ownContractsStatus: SliceStatus;
}

const initialState: EmployeeSlice = {
  employee: employeeInitialState,
  employeeSliceStatus: SliceStatus.Empty,
  ownContracts: [],
  ownContractsStatus: SliceStatus.Empty,
};

export const doEmployeeMe = createAsyncThunk(
  'employee/me',
  async (_, { rejectWithValue }) => {
    try {
      return await employeeMe();
    } catch (error) {
      return await rejectWithValue(error);
    }
  }
);

export const doGetMyContracts = createAsyncThunk(
  'contract/ownContracts',
  async (employeeId: string, { rejectWithValue }) => {
    try {
      return await getEmployeeContracts(employeeId);
    } catch (error) {
      return await rejectWithValue(error);
    }
  }
);

const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doEmployeeMe.pending, (state) => {
      state.employeeSliceStatus = SliceStatus.Updating;
    });
    builder.addCase(doEmployeeMe.fulfilled, (state, action) => {
      state.employee = action.payload.data;
      state.employeeSliceStatus = SliceStatus.Fulfilled;
    });
    builder.addCase(doGetMyContracts.pending, (state) => {
      state.ownContractsStatus = SliceStatus.Updating;
    });
    builder.addCase(doGetMyContracts.fulfilled, (state, action) => {
      state.ownContracts = action.payload.data;
      state.ownContractsStatus = SliceStatus.Fulfilled;
    });
  },
});

export default employeeSlice.reducer;
