import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseForm } from 'components/common/forms/BaseForm/BaseForm';
import * as Auth from 'components/layouts/AuthLayout/AuthLayout.styles';
import { useAppDispatch } from 'hooks/reduxHooks';
import { doResetPassword } from 'store/slices/authSlice';
import notificationHook from 'hooks/notificationHook';
import { authPaths } from 'constants/routePaths';
import { ApiError } from 'types';
import statusMessages from 'statusMessages';
import { Button, Col, Row } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

interface ForgotPasswordFormData {
  email: string;
}

const initValues = {
  email: '',
};

export const ForgotPasswordForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = (values: ForgotPasswordFormData) => {
    setLoading(true);
    dispatch(doResetPassword(values))
      .unwrap()
      .then(() => {
        navigate(`${authPaths.basePath}/${authPaths.login}`);
      })
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          type: 'error',
          message: statusMessages.user[
            currentStatus ? currentStatus : 500
          ] as string,
        });
        setLoading(false);
      });
  };

  return (
    <div className="form-wrapper">
      <BaseForm
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark="optional"
        initialValues={initValues}
      >
        <Button className="back" onClick={() => navigate(-1)}>
          <LeftOutlined /> Regresar
        </Button>
        <h1 className="title">Recuperar contraseña</h1>
        <p className="my-5 mb-7">
          Ingresa tu dirección de email, te enviaremos instrucciones para
          ingresar una nueva contraseña
        </p>
        <Auth.FormItem
          name="email"
          label={`Email`}
          rules={[{ required: true, message: `Campo obligatorio` }]}
        >
          <Auth.FormInput placeholder={`email`} />
        </Auth.FormItem>
        <Row justify="center" className="mt-5">
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              className="submit-btn"
            >
              Enviar correo
            </Button>
          </Col>
        </Row>
      </BaseForm>
    </div>
  );
};
