import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Overlay from 'components/common/Overlay';
import { useResponsive } from 'hooks/useResponsive';
import { SiderLogo } from '../SiderLogo';
import { getMenuItems } from 'constants/MenuItems';
import { Menu, Image } from 'antd';
import Sider from 'antd/es/layout/Sider';
import 'styles/sass/sider.scss';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import Link from 'antd/es/typography/Link';
import { WhatsAppOutlined } from '@ant-design/icons';
import GetFrontendConfig from 'hooks/getFrontConfig';
import { whatsapp, whatsappLinkSupport } from 'constants/contactInfo';
import { doGetMyContracts } from 'store/slices/employeeSlice';
import { SliceStatus } from 'constants/enums/slices';
import { ItemType, MenuItemGroupType } from 'antd/es/menu/interface';

interface MainSiderProps {
  isCollapsed: boolean;
  setCollapsed: (isCollapsed: boolean) => void;
}

const MainSider: React.FC<MainSiderProps> = ({
  isCollapsed,
  setCollapsed,
  ...props
}) => {
  const dispatch = useAppDispatch();
  const { isDesktop, mobileOnly, tabletOnly } = useResponsive();
  const { user } = useAppSelector((state) => state.auth);
  const { ownContracts, ownContractsStatus } = useAppSelector(
    (state) => state.employee
  );
  const [menuItems, setMenuItems] = useState<
    ItemType[] | MenuItemGroupType[]
  >();

  const isCollapsible = useMemo(
    () => mobileOnly && tabletOnly,
    [mobileOnly, tabletOnly]
  );

  const toggleSider = () => setCollapsed(!isCollapsed);

  const [current, setCurrent] = useState('');
  const currentPath = location.pathname;
  const frontendConfig = GetFrontendConfig();

  const menuItemsSetter = useCallback(() => {
    if (user && frontendConfig) {
      const items = getMenuItems(
        user.role,
        ownContracts,
        frontendConfig.isSchedulesModuleEnabled
      );
      if (items) {
        setMenuItems(items);
      }
    }
  }, [ownContracts, setMenuItems, user, frontendConfig]);

  useEffect(() => {
    menuItemsSetter();
  }, [menuItemsSetter]);

  useEffect(() => {
    if (user) {
      if (ownContractsStatus === SliceStatus.Empty) {
        dispatch(doGetMyContracts(user._id));
      }
    }
  }, [dispatch, ownContractsStatus, user]);

  useEffect(() => {
    const menuKeys: string[] = [];
    if (menuItems) {
      menuItems.forEach((item: ItemType | MenuItemGroupType) => {
        menuKeys.push(item?.key as string);
        if ((item as MenuItemGroupType).children) {
          const children = (item as MenuItemGroupType).children;
          children?.forEach((childrenItem) => {
            menuKeys.push(childrenItem?.key as string);
          });
        }
      });
      if (menuKeys.includes(currentPath)) {
        setCurrent(currentPath);
      }
    }
  }, [currentPath, menuItems]);

  return (
    <>
      <Sider
        className="sider-wrapper"
        trigger={null}
        collapsed={!isDesktop && isCollapsed}
        collapsedWidth={tabletOnly ? 80 : 0}
        collapsible={isCollapsible}
        width={260}
        {...props}
      >
        <div className="sider-flex">
          <div>
            <SiderLogo
              isSiderCollapsed={isCollapsed}
              toggleSider={toggleSider}
            />
            {user && ownContracts && (
              <Menu
                items={menuItems}
                mode="inline"
                selectedKeys={[current]}
                className="main-menu"
              />
            )}
          </div>
          <div>
            {frontendConfig && frontendConfig.siderLogoUrl && (
              <div className="logo-inst">
                <Image
                  width={130}
                  src={frontendConfig.siderLogoUrl}
                  alt="logo"
                  preview={false}
                />
              </div>
            )}
            <Link
              className="support-link"
              href={whatsappLinkSupport}
              target="_blank"
            >
              Soporte Técnico <WhatsAppOutlined /> {whatsapp}
            </Link>
            <div className="app-version">
              {import.meta.env.VITE_IMAGE_TAG &&
                `v${import.meta.env.VITE_IMAGE_TAG}`}
            </div>
          </div>
        </div>
      </Sider>
      {mobileOnly && <Overlay onClick={toggleSider} show={!isCollapsed} />}
    </>
  );
};

export default MainSider;
