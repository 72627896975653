import { Button, Result } from 'antd';
import React from 'react';

interface ErrorResultProps {
  title: string;
  dataCy?: string;
}

export const ErrorResult = ({ title, dataCy }: ErrorResultProps) => {
  return (
    <div data-cy={dataCy}>
      <Result
        status="error"
        title={title}
        subTitle="Por favor vuelve a intentarlo más tarde."
        extra={
          <Button
            key="reload"
            onClick={() => window.location.reload()}
            data-cy={`${dataCy}-retry-btn`}
          >
            Reintentar
          </Button>
        }
      />
    </div>
  );
};
