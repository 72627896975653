import { httpApi } from 'api/http.api';
import { AxiosResponse } from 'axios';
import { AWSResponsePresignURLType } from 'types';
import { ExcelData, fileMeta } from 'types/file';

const controller = `files`;

export const presignPutExcel = (
  payload: ExcelData
): Promise<AxiosResponse<AWSResponsePresignURLType>> =>
  httpApi
    .post<AWSResponsePresignURLType>(`${controller}/uploadExcel`, {
      ...payload,
    })
    .then((res) => res);

export const presignPutImage = (
  payload: fileMeta
): Promise<AxiosResponse<AWSResponsePresignURLType>> =>
  httpApi
    .post<AWSResponsePresignURLType>(`${controller}/uploadImage`, {
      ...payload,
    })
    .then((res) => res);
export const presignGetImage = (
  payload: fileMeta
): Promise<AxiosResponse<AWSResponsePresignURLType>> =>
  httpApi
    .post<AWSResponsePresignURLType>(`${controller}/getImage`, {
      ...payload,
    })
    .then((res) => res);
