import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Switch,
  Button,
  Select,
} from 'antd';
import { CustomFormItem, FormQuestionTypes } from 'interfaces/form.interfaces';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import { getAllUsers } from 'api/user.api';
import { getAllCycles } from 'api/cycles.api';
import { getAllCareers } from 'api/careers.api';
import User from '../../types/user';
import { Cycle } from '../../types/cycle';
import { AxiosResponse } from 'axios';
import { Career } from 'types/careers';

const RenderFormItem = ({
  type,
  rules,
  name,
  label,
  dataCy,
  form,
}: CustomFormItem) => {
  const { RangePicker } = DatePicker;

  const ListItemType = (name: string) => {
    return (
      <Form.List name={name}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item key={field.key}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Form.Item {...field} noStyle>
                    <Input
                      placeholder="Escribe el elemento"
                      disabled={index === 0}
                      style={{ width: '90%' }}
                      data-cy={`${dataCy}-list-input-${index}`}
                    />
                  </Form.Item>
                  {fields.length > 1 ? (
                    <Form.Item shouldUpdate noStyle>
                      {({ getFieldValue }) => {
                        const value = getFieldValue([name, field.name]);
                        return value !== 'Base' ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                            style={{ marginLeft: 8 }}
                            data-cy={`${dataCy}-list-delete-btn-${index}`}
                          />
                        ) : null;
                      }}
                    </Form.Item>
                  ) : null}
                </div>
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                icon={<PlusOutlined />}
                data-cy={`${dataCy}-list-add-btn`}
              >
                Agregar
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    );
  };

  const SelectUsersType = () => {
    const [users, setUsers] = useState<{ label: string; value: string }[]>([]);

    const getUsers = async () => {
      const usersData: AxiosResponse<User[]> = await getAllUsers('');
      const formattedUsers = usersData.data.map((user) => ({
        label: `${user.name} - ${user.email}`,
        value: user._id,
      }));
      setUsers(formattedUsers);
    };

    useEffect(() => {
      getUsers();
    }, []);

    return (
      <Select
        data-cy={`${dataCy}-add`}
        mode="multiple"
        className="common-select"
        placeholder="Selecciona un usuario"
        options={users}
      />
    );
  };

  const SelectCareersType = () => {
    const [careers, setCareers] = useState<{ label: string; value: string }[]>(
      []
    );

    const getCareers = async () => {
      const careersData: Career[] = await getAllCareers();
      const formattedCareers = careersData.map((career) => ({
        label: `${career.title}`,
        value: career._id,
      }));
      setCareers(formattedCareers);
    };

    useEffect(() => {
      getCareers();
    }, []);

    return (
      <Select
        data-cy={dataCy}
        className="common-select"
        placeholder="Selecciona una carrera"
        options={careers}
      />
    );
  };

  const SelectCareerSpecialtiesType = ({
    isMultiple,
  }: {
    isMultiple: boolean;
  }) => {
    const [specialties, setSpecialties] = useState<
      { label: string; value: string }[]
    >([]);

    const careerId = Form.useWatch('career', form);

    const onChangeSpecialties = (specialties: string | string[]) => {
      if (isMultiple) {
        form?.setFieldValue('specialties', specialties);
      } else {
        form?.setFieldValue('specialty', specialties);
      }
    };

    const getSpecialties = async () => {
      const careers: Career[] = await getAllCareers();
      const career = careers.find((career) => career._id === careerId);
      const specialties = career
        ? career.specialties.map((specialty) => {
            return { value: specialty, label: specialty };
          })
        : [];
      setSpecialties(specialties);
    };

    useEffect(() => {
      getSpecialties();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [careerId]);

    return (
      <Select
        data-cy={dataCy}
        className="common-select"
        placeholder="Selecciona la especialidad"
        value={
          isMultiple
            ? form?.getFieldValue('specialties')
            : form?.getFieldValue('specialty')
        }
        mode={isMultiple ? 'multiple' : undefined}
        onChange={(e) => {
          onChangeSpecialties(e);
        }}
        options={specialties}
      />
    );
  };

  const SelectCyclesType = () => {
    const [cycles, setCycles] = useState<{ label: string; value: string }[]>(
      []
    );

    const getCycles = async () => {
      const cyclesData: AxiosResponse<Cycle[]> = await getAllCycles();
      const formattedCycles = cyclesData.data.map((cycle) => ({
        label: `${cycle.code}`,
        value: cycle._id,
      }));
      setCycles(formattedCycles);
    };

    useEffect(() => {
      getCycles();
    }, []);

    return (
      <Select
        data-cy={dataCy}
        className="common-select"
        placeholder="Selecciona un ciclo"
        options={cycles}
      />
    );
  };

  return (
    <Form.Item rules={rules ?? []} label={label} name={name}>
      {type === FormQuestionTypes.input && <Input data-cy={dataCy} />}
      {type === FormQuestionTypes.numberInput && (
        <InputNumber min={1} data-cy={dataCy} />
      )}
      {type === FormQuestionTypes.rangePicker && (
        <RangePicker data-cy={dataCy} />
      )}
      {type === FormQuestionTypes.switch && <Switch data-cy={dataCy} />}
      {type === FormQuestionTypes.list && ListItemType(name)}
      {type === FormQuestionTypes.selectUsers && SelectUsersType()}
      {type === FormQuestionTypes.date && (
        <DatePicker format="DD/MMMM/YYYY" data-cy={dataCy} />
      )}
      {type === FormQuestionTypes.selectCycles && SelectCyclesType()}
      {type === FormQuestionTypes.selectCareers && SelectCareersType()}
      {type === FormQuestionTypes.selectMultipleCareerSpecialties && (
        <SelectCareerSpecialtiesType isMultiple={true} />
      )}
      {type === FormQuestionTypes.selectSingleCareerSpecialty && (
        <SelectCareerSpecialtiesType isMultiple={false} />
      )}
    </Form.Item>
  );
};

export default RenderFormItem;
