import * as Sentry from '@sentry/react';
import { Button, Col, Divider, Popover, Row } from 'antd';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { isImpersonateMode } from 'services/localStorage.service';
import { ImpersonateModeData } from 'types/user';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useNavigate } from 'react-router-dom';
import { globalPaths } from 'constants/routePaths';
import { doReturnToMyAccount } from 'store/slices/authSlice';
import 'styles/sass/header.scss';
import { RollbackOutlined } from '@ant-design/icons';
import notificationHook from 'hooks/notificationHook';

export const DesktopHeader = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const impersonateData: ImpersonateModeData | undefined = isImpersonateMode();

  const backToMyAccount = () => {
    try {
      dispatch(doReturnToMyAccount());
      navigate(globalPaths.basePath);
    } catch (error) {
      notificationHook({
        type: 'error',
        message: 'Error regresar a la cuenta original',
        description: 'Por favor, inténtalo de nuevo más tarde.',
      });
      Sentry.captureException(error);
    }
  };

  return (
    <div className="desktop-header">
      <Row
        align="middle"
        justify="end"
        gutter={16}
        className="header-container"
      >
        {user && (
          <>
            {impersonateData && impersonateData.originalUser ? (
              <>
                <Col>
                  <p className="impersonate-mode-txt">Estás impersonando a:</p>
                </Col>
                <Col>
                  <ProfileDropdown user={user} impersonateMode showName />
                </Col>
                <Col>
                  <Divider type="vertical" />
                </Col>
                <Col>
                  <ProfileDropdown user={impersonateData.originalUser} />
                </Col>
                <Col>
                  <Popover content={'Regresar a mi cuenta'}>
                    <Button
                      type="text"
                      className="back-to-my-account-btn"
                      onClick={backToMyAccount}
                    >
                      <RollbackOutlined />
                    </Button>
                  </Popover>
                </Col>
              </>
            ) : (
              <Col>
                <ProfileDropdown user={user} showName />
              </Col>
            )}
          </>
        )}
      </Row>
    </div>
  );
};
