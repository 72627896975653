import { User } from 'types';
import { ImpersonateModeData } from 'types/user';

export const persistToken = (token: string): void => {
  localStorage.setItem('accessToken', token);
};

export const readToken = (): string | null => {
  return localStorage.getItem('accessToken');
};

export const persistUser = (user: User): void => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const readUser = (): User | null => {
  const userStr = localStorage.getItem('user');

  return userStr ? JSON.parse(userStr) : {};
};

export const deleteToken = (): void => {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    localStorage.removeItem('accessToken');
  }
};

export const deleteUser = (): void => {
  const user = localStorage.getItem('user');
  if (user) {
    localStorage.removeItem('user');
  }
};

export const impersonatePersistOriginalUser = () => {
  const originalUser = localStorage.getItem('user');
  const originalAccessToken = localStorage.getItem('accessToken');
  if (originalUser && originalAccessToken) {
    localStorage.setItem('originalUser', originalUser);
    localStorage.setItem('originalAccessToken', originalAccessToken);
  }
};

export const isImpersonateMode = () => {
  const originalUser = localStorage.getItem('originalUser');
  const originalAccessToken = localStorage.getItem('originalAccessToken');
  if (originalUser && originalAccessToken) {
    const impersonateModeData: ImpersonateModeData = {
      originalUser: JSON.parse(originalUser),
      originalAccessToken,
    };
    return impersonateModeData;
  }
  return undefined;
};

export const deleteImpersonateModeData = () => {
  localStorage.removeItem('originalUser');
  localStorage.removeItem('originalAccessToken');
};
