import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Form,
  Modal,
  Row,
  Select,
  Spin,
} from 'antd';
import { ContractSchedule } from 'types/contract';
import * as ScheduleStyles from './Schedules.styles';
import { WeekScheduleType, dataWeekDays } from 'constants/WeekFormat';
import {
  formatScheduleIntervalDate,
  removeZOnHourtoDisplay,
} from 'libs/dateFormaterPetition';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { useCallback, useEffect, useState } from 'react';
import type { Dayjs } from 'dayjs';
import { newSchedule, removeSchedule } from 'api/schedule.api';
import { NewScheduleInterval } from 'types/schedule';
import openNotification from 'hooks/notificationHook';
import { incidencePaths } from 'constants/routePaths';
import { useNavigate } from 'react-router-dom';
import { ShowEmployeeData } from '../../ShowEmployeeData';
import * as Sentry from '@sentry/react';
import notificationHook from 'hooks/notificationHook';
import { ApiError } from 'types';
import statusMessages from 'statusMessages';
import { BackButton } from 'components/common/BackButton/BackButton';

interface ContractSchedulesProps {
  schedules: ContractSchedule[];
  contractId: string;
  employeeId: string;
  getContractSchedulesList: () => void;
}

export const ContractSchedules = ({
  schedules,
  contractId,
  employeeId,
  getContractSchedulesList,
}: ContractSchedulesProps) => {
  const navigate = useNavigate();
  const [addScheduleModalOpen, setAddScheduleModalOpen] = useState(false);
  const [scheduleStartTime, setScheduleStartTime] = useState<Dayjs | null>(
    null
  );
  const [scheduleEndTime, setScheduleEndTime] = useState<Dayjs | null>(null);
  const [addingSchedule, setAddingSchedule] = useState(false);
  const [deleteScheduleModal, setDeleteScheduleModal] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState<ContractSchedule>();
  const [handledChanges, setHandledChanges] = useState(false);
  const [form] = Form.useForm();

  const getScheduleFromDay = (dayOfWeek: number) => {
    const daySchedules = schedules.filter((day) => day.dayOfWeek === dayOfWeek);
    return daySchedules;
  };

  const onChangeInterval = (time: Dayjs, which: 'start' | 'end') => {
    if (which === 'start') {
      setScheduleStartTime(time);
    } else {
      setScheduleEndTime(time);
    }
  };

  const addSchedule = async (values: NewScheduleInterval) => {
    try {
      setAddingSchedule(true);
      const payload = {
        dayOfWeek: values.dayOfWeek,
        from: formatScheduleIntervalDate(values.from),
        to: formatScheduleIntervalDate(values.to),
        contract: contractId,
        employee: employeeId,
      };
      const response = await newSchedule(payload);
      getContractSchedulesList();
      if (response.data.isValidSchedule) {
        notificationHook({
          type: 'success',
          message: response.data.msg,
        });
        setHandledChanges(true);
      } else {
        notificationHook({
          type: 'error',
          message: response.data.msg,
        });
      }
    } catch (e) {
      Sentry.captureException(e);
      const currentStatus = (e as ApiError).statusCode;
      notificationHook({
        message: statusMessages.contract[
          currentStatus ? currentStatus : 500
        ] as string,
        type: 'error',
        description: '',
      });
    } finally {
      setAddingSchedule(false);
      setAddScheduleModalOpen(false);
      form.resetFields();
    }
  };

  const deleteSchedule = async (scheduleId: string | undefined) => {
    try {
      if (!selectedSchedule) {
        notificationHook({
          message: 'Ocurrió un error al obtener el horario para eliminarlo.',
          description: '',
          type: 'error',
        });
        Sentry.captureException(
          new Error('Ocurrió un error al obtener el horario para eliminarlo')
        );
        setDeleteScheduleModal(false);
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      await removeSchedule(scheduleId!);
      getContractSchedulesList();
      setDeleteScheduleModal(false);
      setSelectedSchedule(undefined);
      setHandledChanges(true);
      notificationHook({
        type: 'success',
        message: 'El horario se ha eliminado con éxito.',
      });
    } catch (e) {
      const currentStatus = (e as ApiError).statusCode;
      Sentry.captureException(e);
      notificationHook({
        message: statusMessages.schedule[
          currentStatus ? currentStatus : 500
        ] as string,
        type: 'error',
      });
    }
  };

  const onHandleChangesNotification = useCallback(() => {
    openNotification({
      type: 'info',
      message: `Se han detectado cambios en el horario`,
      description: '¿Deseas conciliar las incidencias? ',
      showConfirmBtn: true,
      key: 'reconcileIncidences',
      duration: null,
      onConfirm: () => {
        navigate(`${incidencePaths.basePath}/conciliar/${employeeId}`);
      },
      onClose: () => {
        setHandledChanges(false);
      },
      confirmBtnLabel: 'Conciliar',
    });
    return;
  }, [employeeId, navigate]);

  const handleChanges = useCallback(() => {
    if (handledChanges) {
      onHandleChangesNotification();
    }
  }, [handledChanges, onHandleChangesNotification]);

  useEffect(() => {
    handleChanges();
  }, [handleChanges]);

  return (
    <div id="contract-schedules">
      <h1 className="title">Horario semanal del contrato</h1>
      <BackButton />
      <Row justify="center">
        <Col className="mb-5" xs={16}>
          <Alert
            message="Los cambios que se realicen, serán aplicados de forma inmediata"
            description="Cualquier cambio realizado, tendrá efectos inmediatos a partir del momento en el que se guarden."
            type="warning"
            showIcon
          />
        </Col>
      </Row>
      <ShowEmployeeData userId={employeeId ?? ''} />
      <Row justify="end" className="my-5">
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setAddScheduleModalOpen(true);
            }}
          >
            Agregar horario
          </Button>
        </Col>
      </Row>

      <Card>
        {dataWeekDays && (
          <>
            {dataWeekDays.map((day: WeekScheduleType, index: number) => (
              <div key={`day-${index}`}>
                <div className="day-divider">
                  <Divider>{day.dayName}</Divider>
                </div>
                {getScheduleFromDay(day.dayNumber).length > 0 ? (
                  <Row justify="center">
                    <>
                      {getScheduleFromDay(day.dayNumber).map(
                        (schedule: ContractSchedule) => (
                          <ScheduleStyles.ScheduleTag
                            color="#e3f2fd"
                            key={schedule._id}
                            closable
                            closeIcon={
                              <CloseOutlined
                                style={{
                                  fontSize: '12px',
                                  color: 'black',
                                }}
                              />
                            }
                            onClose={(e) => {
                              e.preventDefault();
                              setSelectedSchedule(schedule);
                              setDeleteScheduleModal(true);
                            }}
                          >
                            <b>
                              {removeZOnHourtoDisplay(schedule.from)} -
                              {removeZOnHourtoDisplay(schedule.to)}
                            </b>
                          </ScheduleStyles.ScheduleTag>
                        )
                      )}
                    </>
                  </Row>
                ) : (
                  <Empty description="No hay horarios de este día." />
                )}
              </div>
            ))}
          </>
        )}
      </Card>
      <Modal
        open={addScheduleModalOpen}
        onCancel={() => {
          setAddScheduleModalOpen(false);
        }}
        width={'50vw'}
        title="Agregar horario"
        footer={null}
      >
        <Spin spinning={addingSchedule} tip="Agregando horario...">
          <Row>
            <Col xs={24}>
              <Form onFinish={addSchedule} form={form} labelCol={{ span: 4 }}>
                <Form.Item
                  label="Día"
                  name="dayOfWeek"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, selecciona el día de la semana',
                      type: 'number',
                    },
                  ]}
                  hasFeedback
                >
                  <Select placeholder="Selecciona el día">
                    {dataWeekDays.map((day: WeekScheduleType) => (
                      <Select.Option
                        value={day.dayNumber}
                        key={`${day.dayName}`}
                      >
                        {day.dayName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Divider className="primary-02-txt">Intervalo</Divider>
                <Form.Item
                  label="Hora de inicio"
                  name="from"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, ingresa la hora de inicio',
                    },
                  ]}
                  hasFeedback
                >
                  <ScheduleStyles.TimeSelector
                    onChange={(e) => {
                      onChangeInterval(e, 'start');
                    }}
                    format="HH:mm"
                    placeholder="Ingresa la hora de inicio"
                    minuteStep={10}
                    value={scheduleStartTime}
                    showNow={false}
                  />
                </Form.Item>
                <Form.Item
                  label="Hora final"
                  name="to"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor, ingresa la hora de inicio',
                    },
                  ]}
                  hasFeedback
                >
                  <ScheduleStyles.TimeSelector
                    onChange={(e) => {
                      onChangeInterval(e, 'end');
                    }}
                    format="HH:mm"
                    placeholder="Ingresa la hora final"
                    minuteStep={10}
                    value={scheduleEndTime}
                    showNow={false}
                  />
                </Form.Item>
                <Row justify="end" gutter={10}>
                  <Col>
                    <Button
                      danger
                      onClick={() => setAddScheduleModalOpen(false)}
                    >
                      Cancelar
                    </Button>
                  </Col>
                  <Col>
                    <Button htmlType="submit" type="primary">
                      Agregar horario
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Spin>
      </Modal>
      <Modal
        open={deleteScheduleModal}
        title="Eliminar horario"
        onOk={() => {
          deleteSchedule(selectedSchedule ? selectedSchedule._id : undefined);
        }}
        onCancel={() => {
          setDeleteScheduleModal(false);
        }}
        okText="Confirmar"
        cancelText="Cancelar"
      >
        <Row>
          <Col xs={24}>
            <p>
              <b>¿Confirmas que deseas eliminar el horario?</b>
            </p>
            <p>Esta acción no se puede deshacer.</p>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};
