import { useCallback, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { FrontendConfig } from '../interfaces/config';
import { useAppDispatch, useAppSelector } from './reduxHooks';
import { doGetFrontConfig } from 'store/slices/configSlice';
import { SliceStatus } from 'constants/enums/slices';

const GetFrontendConfig = () => {
  const dispatch = useAppDispatch();
  const { config, status: frontConfigStateStatus } = useAppSelector(
    (state) => state.config
  );
  const [frontendConfig, setFrontendConfig] = useState<FrontendConfig>();

  const getFrontendConfigs = useCallback(() => {
    if (frontConfigStateStatus === SliceStatus.Empty) {
      dispatch(doGetFrontConfig());
    } else if (frontConfigStateStatus === SliceStatus.Fulfilled) {
      if (config) {
        setFrontendConfig(config);
      } else {
        Sentry.captureMessage(
          'No se pudo cargar la configuración del frontend'
        );
      }
    }
  }, [dispatch, config, frontConfigStateStatus]);

  useEffect(() => {
    getFrontendConfigs();
  }, [getFrontendConfigs]);

  return frontendConfig;
};

export default GetFrontendConfig;
