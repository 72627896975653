import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

export const BackButton = () => {
  const navigate = useNavigate();

  return (
    <Button className="back-page" onClick={() => navigate(-1)}>
      <ArrowLeftOutlined /> Regresar
    </Button>
  );
};
