import { Drawer, Button, Form, Row, Col, Alert, FormInstance } from 'antd';
import 'styles/sass/drawers.scss';
import { CustomFormItem } from 'interfaces/form.interfaces';
import RenderFormItem from 'components/forms/RenderFormItem';

interface FormDrawerProps<T> {
  open: boolean;
  textButton?: string | React.ReactNode;
  title: string;
  width: number;
  confirmText: string | React.ReactNode;
  cancelText: string | React.ReactNode;
  formItems?: CustomFormItem[];
  formMode: 'create' | 'edit';
  loading?: boolean;
  dataCy?: string;
  onConfirm: (values: T) => void;
  onOpen: () => void;
  onClose: () => void;
  form: FormInstance;
}

export const FormDrawer = <T,>({
  open,
  textButton,
  title,
  width,
  confirmText,
  onConfirm,
  cancelText,
  formItems,
  formMode,
  loading,
  dataCy,
  onOpen,
  onClose,
  form,
}: FormDrawerProps<T>) => {
  return (
    <>
      {textButton && (
        <Button onClick={onOpen} type="primary" data-cy={`${dataCy}-main-btn`}>
          {textButton}
        </Button>
      )}
      <Drawer
        title={title}
        placement="right"
        width={width}
        open={open}
        rootClassName="drawer-wrapper"
        onClose={onClose}
        data-cy={`${dataCy}-drawer`}
      >
        <Form
          layout="vertical"
          onFinish={onConfirm}
          form={form}
          data-cy={`${dataCy}-form`}
        >
          {formItems ? (
            <>
              {formItems.map((item: CustomFormItem, index: number) => (
                <div key={`form-item-${index}`}>
                  {(formMode === 'create' && item.visibleOnCreate) ||
                  (formMode === 'edit' && item.visibleOnEdit) ? (
                    <RenderFormItem
                      key={`form-item-${item.name}`}
                      name={item.name}
                      label={item.label}
                      type={item.type}
                      form={form}
                      visibleOnCreate={item.visibleOnCreate}
                      visibleOnEdit={item.visibleOnEdit}
                      hasFeedback={item.hasFeedback}
                      rules={item.rules}
                      dataCy={`${dataCy}-${item.name}-field`}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              ))}
            </>
          ) : (
            <Alert
              type="info"
              className="mb-5"
              message={
                <p>
                  Para renderizar el formulario, envía la propiedad formItems
                </p>
              }
            />
          )}
          <Row justify="end" gutter={16}>
            <Col>
              <Button data-cy={`${dataCy}-cancel-btn`} onClick={onClose}>
                {cancelText}
              </Button>
            </Col>
            <Col>
              <Button
                data-cy={`${dataCy}-confirm-btn`}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {confirmText}
              </Button>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};
