export const authPaths = {
  basePath: '/auth',
  login: 'login',
  logout: 'logout',
  confirm: 'confirm',
  lock: 'lock',
  forgotPassword: 'forgot-password',
  newPassword: 'new-password',
};

export const globalPaths = {
  basePath: '/',
  careers: 'carreras',
  cycles: 'ciclos',
  subjects: 'materias',
  groups: 'grupos',
  groupsSchedule: 'grupos/horario-grupo',
  holidays: 'dias-festivos',
  users: 'usuarios',
  holidaysList: 'lista-dias-festivos',
  forbidden: '/Forbidden',
  notFoundPage: '/not-found',
  uploadPhotos: 'fotos',
};

export const reportPaths = {
  basePath: '/reportes',
  checks: 'chequeos',
  events: 'eventos',
  incidences: 'incidencias',
};

export const incidencePaths = {
  basePath: '/incidencias',
  new: 'nueva',
  catalogue: 'catalogo',
  catalogueNew: 'nuevo',
  catalogueEdit: 'editar',
  reconcile: 'conciliar',
};

export const usersPaths = {
  basePath: '/usuarios',
  editUser: 'editar',
  userDetail: 'detalle',
  userContracts: 'contratos',
  contractSchedules: 'contrato/horario',
  userCreateContract: 'contratos/nuevo',
  newUser: 'nuevo',
  uploadUsers: 'subir',
};

export const contractsPaths = {
  basePath: '/contratos',
  contractDetail: 'detalle',
  editContract: 'editar',
  uploadContracts: 'subir',
};

export const devicesPaths = {
  basePath: '/dispositivos',
  deviceDetail: ':sn',
};

export const kardexPaths = {
  base: '/kardex',
  self: 'personal',
};

export const genericPaths = {
  employeeSelfChecks: 'mis-chequeos',
};
