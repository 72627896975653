import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar } from 'antd';
import { BaseForm } from 'components/common/forms/BaseForm/BaseForm';
import notificationHook from 'hooks/notificationHook';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useResponsive } from 'hooks/useResponsive';
import { Dates } from 'constants/Dates';
import { doLogin } from 'store/slices/authSlice';
import * as Auth from 'components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './LockForm.styles';
import { loginFormInitValues } from 'constants/initialValues';
import statusMessages from 'statusMessages';
import { ApiError } from 'types';

interface LockFormData {
  password: string;
}

const initValues = {
  password: loginFormInitValues.password,
};

export const LockForm: React.FC = () => {
  const navigate = useNavigate();
  const { mobileOnly } = useResponsive();
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState(false);
  const [dateState, setDateState] = useState(new Date());
  const user = useAppSelector((state) => state.auth.user);
  const fullName = `${user?.name}`;

  const currentDateInUTC = dateState.toUTCString();
  const currentTime = Dates.format(currentDateInUTC, 'h:mm A');
  const currentDate = Dates.format(currentDateInUTC, 'dddd, MMMM D, YYYY');

  useEffect(() => {
    const interval = setInterval(() => setDateState(new Date()), 10 * 1000);
    return () => clearInterval(interval);
  }, []);

  const handleSubmit = ({ password }: LockFormData) => {
    setLoading(true);
    dispatch(doLogin({ email: user?.email || '', password }))
      .unwrap()
      .then(() => {
        navigate(-1);
      })
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          type: 'error',
          message: statusMessages.user[
            currentStatus ? currentStatus : 500
          ] as string,
        });
        setLoading(false);
      });
  };

  return (
    <div className="form-wrapper">
      <BaseForm
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark="optional"
        initialValues={initValues}
      >
        <S.ContentWrapper>
          <S.Time>{currentTime}</S.Time>
          <S.Date>{currentDate}</S.Date>
          <S.AvatarCircle>
            <Avatar
              src={user?.profile_picture}
              alt="user avatar"
              size={mobileOnly ? 59 : 77}
            />
          </S.AvatarCircle>
          <S.Name>{fullName}</S.Name>
        </S.ContentWrapper>
        <S.FormItem
          label={`Contraseña`}
          name="password"
          rules={[{ required: true, message: `Campo requerido` }]}
        >
          <Auth.FormInputPassword placeholder={`Contraseña`} />
        </S.FormItem>
        <BaseForm.Item noStyle>
          <Auth.SubmitButton
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            {`Ingresar`}
          </Auth.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </div>
  );
};
