import styled from 'styled-components';
import { Checkbox, Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { BaseForm } from 'components/common/forms/BaseForm/BaseForm';
import { Input as CommonInput } from 'components/common/inputs/Input/Input';
import { InputPassword as CommonInputPassword } from 'components/common/inputs/InputPassword/InputPassword';
import { FONT_SIZE, FONT_WEIGHT, media } from 'styles/themes/constants';

export const FormCheckbox = styled(Checkbox)`
  display: flex;
  padding-left: 0.125rem;

  & .ant-checkbox-inner {
    border-radius: 3px;
    transform: scale(1.375);
  }

  & .ant-checkbox-input {
    transform: scale(1.375);
  }
`;

export const FormItem = styled(BaseForm.Item)`
  margin-bottom: 0.75rem;
  & .ant-form-item-explain-error {
    font-size: ${FONT_SIZE.xs};
  }

  & label {
    font-size: 16px !important;
    line-height: 1.25rem;
    color: white !important;
  }

  &.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
    padding-right: 1.5rem;
  }
`;

export const FormInput = styled(CommonInput)`
  color: var(--text-main-color);
  background: white;
  border-color: var(--secondary-01-color);
  & input.ant-input {
    background: white;
  }
`;

export const FormInputPassword = styled(CommonInputPassword)`
  background: white;
  border-color: var(--secondary-01-color);
  & input.ant-input {
    color: var(--text-main-color);
    background: white;
  }
`;

export const ActionsWrapper = styled.div`
  margin-bottom: 2rem;
  color: red !important;
`;

export const SubmitButton = styled(Button)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  background: var(--primary-01-color);
  margin: 0 auto !important;
  display: flex;
  &:hover {
    background: var(--primary-02-color) !important;
  }
`;

export const SocialButton = styled(Button)`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
`;

export const FooterWrapper = styled.div`
  margin-top: 1.25rem;
  text-align: center;
`;

export const BackIcon = styled(LeftOutlined)`
  font-size: 0.75rem;
  margin-right: 0.75rem;
`;

export const BackWrapper = styled.div`
  font-size: ${FONT_SIZE.md};
  font-weight: ${FONT_WEIGHT.semibold};
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1.25rem;
`;

export const SocialIconWrapper = styled.div`
  display: flex;
  margin-right: 0.8125rem;
  @media only screen and ${media.xs} {
    margin-right: 0.625rem;
  }

  @media only screen and ${media.md} {
    margin-right: 0.8125rem;
  }
`;
