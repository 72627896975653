import dayjs from 'dayjs';
import { Col, DatePicker, Row, Select } from 'antd';
import { months } from 'constants/global';
import { KardexHeaderProps } from 'interfaces/kardex';
import 'styles/sass/kardex.scss';

const currentMonth = dayjs().month() + 1;

export const KardexHeader = ({
  employee,
  onYearChange,
  year,
  mode,
  setNewDate,
}: KardexHeaderProps) => {
  const item = (label: string, description: string) => (
    <Row className="item-container">
      <Col xs={24} className="item-label">
        {label}
      </Col>
      <Col xs={24} className="item-description">
        {description}
      </Col>
    </Row>
  );

  const onChangeMonth = (value: number) => {
    const date = new Date();
    date.setMonth(value - 1);
    setNewDate(date);
  };

  const weeklyOnYearChange = (value: dayjs.Dayjs) => {
    const yearDate = value.year();
    const date = new Date();
    date.setFullYear(yearDate);
    setNewDate(date);
  };

  return (
    <div className="card-header">
      <Row className="description-card" align="middle" gutter={24}>
        <Col className="cell-card" xs={2}>
          {item('No. Empleado', employee.idEmployee ?? '0')}
        </Col>
        <Col className="cell-card" xs={10}>
          {item('Nombre', `${employee.lastName ?? ''} ${employee.name}`)}
        </Col>
        <Col className="cell-card" xs={2}>
          {item('Horario', `-`)}
        </Col>
        {mode === 'weekly' && (
          <Col xs={3}>
            <Select
              options={months}
              className="common-select"
              defaultValue={currentMonth}
              onChange={(value) => {
                onChangeMonth(value);
              }}
            />
          </Col>
        )}
        <Col xs={3}>
          {mode === 'yearly' ? (
            <DatePicker
              className="picker"
              picker="year"
              defaultValue={year}
              onChange={onYearChange}
            />
          ) : (
            <DatePicker
              className="picker"
              onChange={(value) => {
                weeklyOnYearChange(value);
              }}
              picker="year"
              defaultValue={year}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};
