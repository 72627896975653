import { httpApi } from 'api/http.api';
import { AxiosResponse } from 'axios';
import { Subject, SearchSubject } from 'types/subjects';
import { ResponseGeneral } from 'types';

const controller = `subject`;

//GET
export const getAll = (
  payload?: SearchSubject
): Promise<AxiosResponse<Subject[]>> =>
  httpApi
    .get<Subject[]>(`${controller}/`, { params: payload ? { ...payload } : {} })
    .then((res) => res);

//PUT
export const editSubject = (
  payload: Subject
): Promise<AxiosResponse<Subject>> =>
  httpApi.put<Subject>(`${controller}/`, { ...payload }).then((res) => res);

//POST
export const createSubject = (
  payload: Subject
): Promise<AxiosResponse<Subject>> =>
  httpApi.post<Subject>(`${controller}/`, { ...payload }).then((res) => res);

//DELETE
export const remove = (id: string): Promise<AxiosResponse<ResponseGeneral>> =>
  httpApi.delete<ResponseGeneral>(`${controller}/${id}`).then((res) => res);
