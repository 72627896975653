import { Month } from 'interfaces/kardex';

export const months: Month[] = [
  {
    name: 'Enero',
    monthNumber: '01',
  },
  {
    name: 'Febrero',
    monthNumber: '02',
  },
  {
    name: 'Marzo',
    monthNumber: '03',
  },
  {
    name: 'Abril',
    monthNumber: '04',
  },
  {
    name: 'Mayo',
    monthNumber: '05',
  },
  {
    name: 'Junio',
    monthNumber: '06',
  },
  {
    name: 'Julio',
    monthNumber: '07',
  },
  {
    name: 'Agosto',
    monthNumber: '08',
  },
  {
    name: 'Septiembre',
    monthNumber: '09',
  },
  {
    name: 'Octubre',
    monthNumber: '10',
  },
  {
    name: 'Noviembre',
    monthNumber: '11',
  },
  {
    name: 'Diciembre',
    monthNumber: '12',
  },
];

export const monthDays = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
];

export const exampleIncidence = [
  {
    date: '2024-01-01',
    text: 'TEXTO',
    color: 'green',
  },
  {
    date: '2024-06-01',
    text: 'TEXTO',
    color: 'green',
  },
  {
    date: '2024-07-10',
    text: 'TEXTO',
    color: 'red',
  },
  {
    date: '2024-05-18',
    text: 'TEXTO',
    color: 'yellow',
  },
];
