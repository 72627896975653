import User from 'types/user';
import { EmployeeRole } from './enums/roles';

export const employeeInitialState: User = {
  _id: '',
  idEmployee: '',
  email: '',
  name: 'string',
  lastName: 'string',
  status: false,
  role: EmployeeRole,
  currentSchedule: [],
  phone: '',
  token: '',
};
