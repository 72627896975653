import { LAYOUT } from 'styles/themes/constants';
import { media } from 'styles/themes/constants';
import { Layout } from 'antd';
import styled, { css } from 'styled-components';

interface IHeader {
  $isTwoColumnsLayoutHeader: boolean;
}

export const Header = styled(Layout.Header)<IHeader>`
  line-height: 1.5;

  @media only screen and ${media.md} {
    padding: ${LAYOUT.desktop.paddingVertical}
      ${LAYOUT.desktop.paddingHorizontal};
    height: ${LAYOUT.desktop.headerHeight} !important;
  }

  @media only screen and ${media.md} {
    ${(props) =>
      props?.$isTwoColumnsLayoutHeader &&
      css`
        padding: 0;
      `}
  }
`;
