import { Holiday } from 'interfaces/holidays.interfaces';
import { httpApi } from './http.api';
import { ResponseGeneral } from 'types';

const controller = `holiday`;

export const getHolidays = async (year?: number): Promise<Holiday[]> => {
  let requestUrl = `${controller}`;
  if (year) {
    requestUrl = `${controller}/${year}`;
  }
  return httpApi.get<Holiday[]>(requestUrl, {}).then((res) => res?.data);
};

export const newHoliday = (payload: Holiday): Promise<Holiday> =>
  httpApi
    .post<Holiday>(`${controller}`, { ...payload })
    .then((res) => res?.data);

export const deleteHoliday = (id: string): Promise<ResponseGeneral> =>
  httpApi
    .delete<ResponseGeneral>(`${controller}/${id}`, {})
    .then((res) => res?.data);

export const editHoliday = (payload: Holiday): Promise<Holiday> =>
  httpApi
    .put<Holiday>(`${controller}`, { ...payload })
    .then((res) => res?.data);
