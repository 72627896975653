import { httpApi } from 'api/http.api';
import {
  EmployeeIncidences,
  Incidence,
  IncidenceCategory,
  ReconcileIncidencesPayload,
  IncidencesResponseType,
} from 'types/incidences';
import { ReportFilters } from 'types/reports';
import { ResponseGeneral } from 'types';
import {
  GetEmployeeIncidencesProps,
  GetEmployeeIncidencesByDateRangeProps,
} from 'interfaces/employee.interfaces';
import { AxiosResponse } from 'axios';

const controller = `incidences`;

export const createOne = (
  values: Incidence
): Promise<AxiosResponse<Incidence>> =>
  httpApi.post<Incidence>(`${controller}`, values).then((res) => res);

export const getIncidencesList = (
  filters: ReportFilters,
  page: number,
  limit: number
): Promise<AxiosResponse<IncidencesResponseType>> => {
  const params = new URLSearchParams({
    page: page.toString(),
    limit: limit.toString(),
  }).toString();

  return httpApi
    .post<IncidencesResponseType>(`${controller}/getIncidences?${params}`, {
      ...filters,
    })
    .then((res) => res);
};

export const updateOne = (id: string): Promise<AxiosResponse<Incidence>> =>
  httpApi.put<Incidence>(`${controller}/${id}`, {}).then((res) => res);

export const removeOne = (id: string): Promise<AxiosResponse<Incidence>> =>
  httpApi.delete<Incidence>(`${controller}/${id}`, {}).then((res) => res);

///// Categories

export const createCategory = (
  values: IncidenceCategory
): Promise<AxiosResponse<IncidenceCategory>> =>
  httpApi
    .post<IncidenceCategory>(`${controller}/category`, values)
    .then((res) => res);

export const getCategories = (): Promise<AxiosResponse<IncidenceCategory[]>> =>
  httpApi
    .get<IncidenceCategory[]>(`${controller}/category`, {})
    .then((res) => res);

export const getCategory = (
  id: string
): Promise<AxiosResponse<IncidenceCategory>> =>
  httpApi
    .get<IncidenceCategory>(`${controller}/category/${id}`, {})
    .then((res) => res);

export const updateCategory = (
  values: IncidenceCategory,
  id: string
): Promise<AxiosResponse<IncidenceCategory>> =>
  httpApi
    .put<IncidenceCategory>(`${controller}/category/${id}`, values)
    .then((res) => res);

export const removeCategory = (
  id: string
): Promise<AxiosResponse<IncidenceCategory>> =>
  httpApi
    .delete<IncidenceCategory>(`${controller}/category/${id}`, {})
    .then((res) => res);

export const reconcileIncidences = (
  payload: ReconcileIncidencesPayload
): Promise<AxiosResponse<ResponseGeneral>> =>
  httpApi
    .post<ResponseGeneral>(`${controller}/reconcileIncidences`, {
      ...payload,
    })
    .then((res) => res);

export const getEmployeeIncidencesByYear = ({
  employeeId,
  year,
}: GetEmployeeIncidencesProps): Promise<AxiosResponse<EmployeeIncidences[]>> =>
  httpApi
    .get<
      EmployeeIncidences[]
    >(`${controller}/get-employee-incidences-by-year/${year}/${employeeId}`, {})
    .then((res) => res);

export const getEmployeeWeeklyIncidences = (
  payload: GetEmployeeIncidencesByDateRangeProps
): Promise<AxiosResponse<EmployeeIncidences[]>> =>
  httpApi
    .post<
      EmployeeIncidences[]
    >(`${controller}/get-employee-weekly-incidences`, { ...payload })
    .then((res) => res);
