import { Popover } from 'antd';
import { WeeklyKardexIncidence } from 'types/incidences';
import dayjs from 'dayjs';

interface CalendarEventPopoverProps {
  event: WeeklyKardexIncidence;
}

const CalendarEventPopover = ({ event }: CalendarEventPopoverProps) => {
  const content = (
    <div>
      <p>{event.title}</p>
      <p>
        {dayjs(event.start).format('HH:mm')} -{' '}
        {dayjs(event.end).format('HH:mm')}
      </p>
    </div>
  );

  return (
    <Popover content={content}>
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {event.title}
      </div>
    </Popover>
  );
};

export default CalendarEventPopover;
