import { ColumnsType } from 'antd/es/table';
import { Button } from 'antd';
import { TableDeletePopOver } from 'components/TableDeletePopOver/TableDeletePopOver';
import { DrawerModeListColumnProps } from 'interfaces/global.interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { globalPaths } from 'constants/routePaths';
import { useNavigate } from 'react-router-dom';
import { Group } from 'types/groups';
import { TableEditButton } from 'components/TableEditButton/TableEditButton';

export const GroupsListColumns = ({
  onDelete,
  form,
  onOpenEditDrawer,
  setItemToEditId,
}: DrawerModeListColumnProps) => {
  const dataCy = 'groups-table';
  const navigate = useNavigate();

  const openDrawer = (formValues: Group) => {
    setItemToEditId(formValues._id);
    form.setFieldsValue(formValues);
    onOpenEditDrawer(true);
  };

  const columns: ColumnsType<Group> = [
    {
      title: 'Grado',
      dataIndex: 'grade',
      key: 'grade',
      width: 100,
    },
    {
      title: 'Grupo',
      dataIndex: 'name',
      key: 'name',
      width: 100,
    },
    {
      title: 'Carrera',
      dataIndex: 'careerTitle',
      key: 'careerTitle',
      width: 100,
    },
    {
      title: 'Especialidad',
      dataIndex: 'specialty',
      key: 'specialty',
      width: 100,
    },
    {
      title: 'Ciclo',
      dataIndex: 'cycleCode',
      key: 'cycleCode',
      width: 100,
    },
    {
      title: 'Opciones',
      align: 'center',
      width: 100,
      render: (_, record: Group, index) => (
        <div className="option-buttons" id={`drawer-${record.name}`}>
          <TableEditButton
            dataCy={`edit-group-${record.code}`}
            onClick={() => {
              openDrawer(record);
            }}
          />
          <TableDeletePopOver
            id={record._id}
            deleteHandler={onDelete}
            dataCy={`${dataCy}-delete-btn-${index}`}
          />
          <Button
            onClick={() =>
              navigate(`/${globalPaths.groupsSchedule}/${record._id}`)
            }
          >
            <FontAwesomeIcon icon={faEye} />
          </Button>
        </div>
      ),
    },
  ];
  return columns;
};
