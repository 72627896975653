export default {
  201: 'Grupo creado con éxito',
  202: 'Grupo editado con éxito',
  404: 'No se ha podido encontrar el grupo',
  409: 'No se ha podido eliminar porque el grupo tiene un horario asignado',
  411: 'No se encontró la especialidad en ninguna carrera',
  412: 'No existe ciclo activo',
  421: 'La fecha no es válida.',
  427: 'La clave ya se encuentra registrada',
  428: 'El turno especificado no es válido',
  431: 'No se encontró el grupo',
};
