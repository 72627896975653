import { Button, Form } from 'antd';
import { GroupScheduleModal } from './GroupScheduleModal';
import { GroupScheduleFeedack } from './GroupScheduleFeedback';
import { GroupsScheduleSubjectsProps } from 'interfaces/schedule';
import { formatScheduleIntervalDate } from 'libs/dateFormaterPetition';
import { Subject } from 'types/subjects';
import { useAppDispatch } from 'hooks/reduxHooks';
import { globalPaths } from 'constants/routePaths';
import dayjs from 'dayjs';
import { useState } from 'react';
import { ApiError } from './../../types';
import { doCreateGroupSchedule } from 'store/slices/groupScheduleSlice';
import {
  compareNewSchedules,
  compareExistingSchedules,
} from 'hooks/compareOverlappedSchedules';
import notificationHook from 'hooks/notificationHook';
import statusMessages from 'statusMessages';
import {
  GroupSchedule,
  ScheduleDay,
  ScheduleComparison,
} from 'types/group-schedule';

export const GroupsScheduleSubjects = ({
  subjects,
  group,
  groupSchedules,
  userOptions,
  isPublished,
}: GroupsScheduleSubjectsProps) => {
  const dispatch = useAppDispatch();
  const [isComplete] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [subjectToAssign, setSubjectToAssign] = useState<Subject>();
  const [conflictingExistingSchedules, setConflictingExistingSchedules] =
    useState<ScheduleDay[]>([]);
  const [conflictingNewSchedules, setConflictingNewSchedules] = useState<
    ScheduleComparison[]
  >([]);
  const [form] = Form.useForm();

  const handleCloseFeedback = () => setShowFeedbackModal(false);

  const showModal = (subject: Subject) => {
    if (!isPublished) {
      setSubjectToAssign(subject);
      setIsModalOpen(true);
    } else {
      notificationHook({
        message: 'No se puede modificar un horario ya publicado',
        type: 'warning',
      });
    }
  };

  const onNewGroupSchedule = async (values: GroupSchedule) => {
    if (!values.schedule || values.schedule.length === 0) {
      notificationHook({
        message: statusMessages.groupSchedule[409] as string,
        type: 'error',
      });
      return;
    }

    values.schedule = values.schedule.map((item) => ({
      ...item,
      initialHour: formatScheduleIntervalDate(dayjs(item.initialHour)),
      endHour: formatScheduleIntervalDate(dayjs(item.endHour)),
    }));

    const overlappedExistingSchedules = compareExistingSchedules(
      values,
      groupSchedules
    );
    const overlappedNewSchedules = compareNewSchedules(values);

    if (
      overlappedExistingSchedules.length > 0 ||
      overlappedNewSchedules.length > 0
    ) {
      setConflictingExistingSchedules(overlappedExistingSchedules);
      setConflictingNewSchedules(overlappedNewSchedules);
      setShowFeedbackModal(true);
      return;
    }

    if (subjectToAssign && group) {
      values.subjectId = subjectToAssign._id;
      values.subjectName = subjectToAssign.name;
      values.group = group._id;
      values.career = group.career;
      values.cycle = group.cycle;
    }
    dispatch(doCreateGroupSchedule(values))
      .unwrap()
      .then(() => {
        form.resetFields();
        notificationHook({
          type: 'success',
          message: '¡Se ha creado el horario de grupo con éxito!',
        });
      })
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          message: statusMessages.groupSchedule[currentStatus ?? 500] as string,
          type: 'error',
        });
      })
      .finally(() => {
        setIsModalOpen(false);
      });
  };

  const handleCancel = () => setIsModalOpen(false);

  return (
    <>
      <h4 className="sub-title">Materias sin asignar:</h4>
      <p className="my-2">
        Da clic en una materia para asignarle un horario y docente
      </p>
      <div className="unassigned-subjects">
        {subjects?.map((subject) => (
          <Button
            data-cy={`subject-${subject.code}`}
            key={subject.code}
            onClick={() => showModal(subject)}
            type="default"
            className={isComplete ? 'subject incomplete' : 'subject complete'}
          >
            {subject.name}
          </Button>
        ))}
        <GroupScheduleModal
          isModalOpen={isModalOpen}
          modalTitle={subjectToAssign?.name}
          userOptions={userOptions}
          form={form}
          handleSubmit={onNewGroupSchedule}
          handleCancel={handleCancel}
        />
      </div>
      <Button href={`/${globalPaths.subjects}`} className="btn-link p-0">
        Crear materia
      </Button>
      <GroupScheduleFeedack
        conflictingExistingSchedules={conflictingExistingSchedules}
        conflictingNewSchedules={conflictingNewSchedules}
        showFeedbackModal={showFeedbackModal}
        handleCloseFeedback={handleCloseFeedback}
      />
    </>
  );
};
