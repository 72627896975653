import { Col, Popover, Row } from 'antd';
import {
  getPassedMinutesFromDate,
  getTextualPassedTime,
} from 'hooks/getPassedMinutesFromDate';
import { LastActivityDataProps } from 'interfaces/device.interfaces';
import { dateForDisplay } from 'libs/dateFormaterPetition';
import '../../styles/sass/popover.scss';

export const LastActivityData = ({ date, children }: LastActivityDataProps) => {
  return (
    <div id="last-device-activity-info">
      <Popover
        content={
          <Row justify={'center'} align={'middle'}>
            <Col xl={24} className="text-center tooltip">
              <p>
                <b>Última actividad: </b> {dateForDisplay(date.toString())}{' '}
                (hace {getTextualPassedTime(getPassedMinutesFromDate(date))}).
              </p>
            </Col>
          </Row>
        }
      >
        {children}
      </Popover>
    </div>
  );
};
