import { ColumnsType } from 'antd/es/table';
import { Career } from 'types/careers';
import { Tag } from 'antd';
import { TableDeletePopOver } from 'components/TableDeletePopOver/TableDeletePopOver';
import { DrawerModeListColumnProps } from 'interfaces/global.interfaces';
import { TableEditButton } from 'components/TableEditButton/TableEditButton';

export const ColumnsCareer = ({
  onDelete,
  form,
  onOpenEditDrawer,
  setItemToEditId,
}: DrawerModeListColumnProps) => {
  const dataCy = 'careers-table';
  const openDrawer = (formValues: Career) => {
    setItemToEditId(formValues._id);
    form.setFieldsValue(formValues);
    onOpenEditDrawer(true);
  };

  const columns: ColumnsType<Career> = [
    {
      title: 'Clave',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Carreras',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Especialidades',
      dataIndex: 'specialties',
      key: 'specialties',
      render: (specialties: string[]) => (
        <>
          {specialties.map((specialty, index) => (
            <Tag key={index} color="blue" className="mb-2">
              {specialty}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: 'Opciones',
      align: 'center',
      width: 100,
      render: (_, record: Career, index) => (
        <div className="option-buttons" id={`drawer-${record.title}`}>
          <TableEditButton
            dataCy={`edit-career-${record.code}`}
            onClick={() => {
              openDrawer(record);
            }}
          />
          <TableDeletePopOver
            id={record._id}
            deleteHandler={onDelete}
            dataCy={`${dataCy}-delete-btn-${index}`}
          />
        </div>
      ),
    },
  ];
  return columns;
};
