import { httpApi } from 'api/http.api';
import { Group, UpdateGroupPayload, searchGroup } from 'types/groups';
import { ResponseGeneral } from 'types';

const controller = `group`;

//GET
export const getAllGroups = (payload: searchGroup): Promise<Group[]> =>
  httpApi
    .get<Group[]>(`${controller}/`, { params: payload ? { ...payload } : {} })
    .then((res) => res?.data);

//DELETE
export const removeGroup = (id: string): Promise<ResponseGeneral> =>
  httpApi
    .delete<ResponseGeneral>(`${controller}/${id}`)
    .then((res) => res?.data);

//PUT
export const editGroup = ({
  payload,
  id,
}: UpdateGroupPayload): Promise<Group> =>
  httpApi
    .put<Group>(`${controller}/${id}`, { ...payload })
    .then((res) => res?.data);

//POST
export const newGroup = (payload: Group): Promise<Group> =>
  httpApi
    .post<Group>(`${controller}/`, { ...payload })
    .then((res) => res?.data);
