import { ReloadOutlined, UserOutlined } from '@ant-design/icons';
import {
  faCameraRetro,
  faFingerprint,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Space, Skeleton } from 'antd';
import { addCommand } from 'api/devices.api';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useEffect, useState } from 'react';
import { doClear, doList } from 'store/slices/devicesSlice';
import * as Sentry from '@sentry/react';
import notificationHook from 'hooks/notificationHook';
import { ApiError } from 'types';
import statusMessages from 'statusMessages';
import { SliceStatus } from 'constants/enums/slices';

type RegistersDataProps = {
  serialNumber: string;
};
type RegistersDataType = {
  users: number;
  fingerPrints: number;
  bioPhotos: number;
};

const RegisteredCheckerData = ({ serialNumber }: RegistersDataProps) => {
  const dispatch = useAppDispatch();
  const { devices, status: deviceSliceStatus } = useAppSelector(
    (state) => state.device
  );
  const [registers, setRegisters] = useState<RegistersDataType>({
    users: 0,
    fingerPrints: 0,
    bioPhotos: 0,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    setIsLoading(false);
    if (devices) {
      // Buscamos el dispositivo con ese serialNumber
      const deviceFound = devices.find(
        (device) => device.serialNumber === serialNumber
      );
      if (deviceFound) {
        setRegisters({
          users: deviceFound.properties.UserCount ?? 0,
          fingerPrints: deviceFound.properties.FPCount ?? 0,
          bioPhotos: deviceFound.properties.FaceCount ?? 0,
        });
      }
    }
  }, [devices, dispatch, serialNumber]);

  const reloadData = () => {
    setIsLoading(true);
    if (deviceSliceStatus === SliceStatus.Fulfilled) {
      addCommand({ serialNumber: serialNumber, command: 'info' })
        .then((data) => {
          notificationHook({
            type: 'success',
            message: data.data.message.toString(),
          });
        })
        .catch((error) => {
          const currentStatus = (error as ApiError).statusCode;
          notificationHook({
            type: 'error',
            message: statusMessages.devices[
              currentStatus ? currentStatus : 500
            ] as string,
          });
          setIsLoading(false);
          Sentry.captureException(error);
        });
      dispatch(doClear());
      setIsLoading(false);
      return;
    }
    dispatch(doList());
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <Skeleton active paragraph={{ rows: 0 }} />
      ) : (
        <Space>
          <span title="Usuarios Registrados">
            <Card bordered size="small">
              <UserOutlined />
              <div>{registers.users}</div>
            </Card>
          </span>
          <span title="Huellas Registradas">
            <Card bordered size="small">
              <FontAwesomeIcon icon={faFingerprint} />
              <div>{registers.fingerPrints}</div>
            </Card>
          </span>
          <span title="Rostros Registrados">
            <Card bordered size="small">
              <FontAwesomeIcon icon={faCameraRetro} />
              <div>{registers.bioPhotos}</div>
            </Card>
          </span>
          <span title="Actualizar">
            <Button
              className="outlined-icon"
              icon={<ReloadOutlined />}
              onClick={reloadData}
            />
          </span>
        </Space>
      )}
    </>
  );
};

export default RegisteredCheckerData;
