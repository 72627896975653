import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSubject, editSubject, getAll, remove } from 'api/subjects.api';
import { SliceStatus } from 'constants/enums/slices';
import { Subject, SearchSubject } from 'types/subjects';

export interface SubjectSlice {
  subjects: Subject[];
  subjectStatus: SliceStatus;
  listSubjectStatus: SliceStatus;
}

const initialState: SubjectSlice = {
  subjects: [],
  subjectStatus: SliceStatus.Empty,
  listSubjectStatus: SliceStatus.Empty,
};

export const doSubjectList = createAsyncThunk(
  'subject/list',
  async (searchPayload: SearchSubject, { rejectWithValue }) => {
    try {
      return await getAll(searchPayload);
    } catch (error: unknown) {
      return await rejectWithValue(error);
    }
  }
);

export const doCreateSubject = createAsyncThunk(
  'subject/create',
  async (SubjectCreatePayload: Subject, { rejectWithValue }) => {
    try {
      await createSubject(SubjectCreatePayload);
      return await getAll();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const doEditSubject = createAsyncThunk(
  'subject/edit',
  async (SubjectEditPayload: Subject, { rejectWithValue }) => {
    try {
      await editSubject(SubjectEditPayload);
      return await getAll();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const doRemoveSubject = createAsyncThunk(
  'subject/remove',
  async (id: string, { rejectWithValue }) => {
    try {
      await remove(id);
      return await getAll();
    } catch (error: unknown) {
      return await rejectWithValue(error);
    }
  }
);

const subjectSlice = createSlice({
  name: 'subjects',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doSubjectList.pending, (state) => {
      state.listSubjectStatus = SliceStatus.Updating;
    });
    builder.addCase(doSubjectList.fulfilled, (state, action) => {
      state.subjects = action.payload.data;
      state.listSubjectStatus = SliceStatus.Fulfilled;
    });
    builder.addCase(doSubjectList.rejected, (state) => {
      state.listSubjectStatus = SliceStatus.Error;
    });
    builder.addCase(doCreateSubject.pending, (state) => {
      state.subjectStatus = SliceStatus.Updating;
    });
    builder.addCase(doCreateSubject.fulfilled, (state, action) => {
      state.subjects = action.payload.data;
      state.subjectStatus = SliceStatus.Fulfilled;
    });
    builder.addCase(doCreateSubject.rejected, (state) => {
      state.subjectStatus = SliceStatus.Error;
    });
    builder.addCase(doEditSubject.pending, (state) => {
      state.subjectStatus = SliceStatus.Updating;
    });
    builder.addCase(doEditSubject.fulfilled, (state, action) => {
      state.subjects = action.payload.data;
      state.subjectStatus = SliceStatus.Fulfilled;
    });
    builder.addCase(doEditSubject.rejected, (state) => {
      state.subjectStatus = SliceStatus.Error;
    });
    builder.addCase(doRemoveSubject.pending, (state) => {
      state.subjectStatus = SliceStatus.Updating;
    });
    builder.addCase(doRemoveSubject.fulfilled, (state, action) => {
      state.subjects = action.payload.data;
      state.subjectStatus = SliceStatus.Fulfilled;
    });
    builder.addCase(doRemoveSubject.rejected, (state) => {
      state.subjectStatus = SliceStatus.Error;
    });
  },
});

export default subjectSlice.reducer;
