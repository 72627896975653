import dayjs from 'dayjs';
import { Alert, Col, Divider, Row } from 'antd';
import 'dayjs/locale/es';
dayjs.locale('es');
type ModalReportConentProps = {
  startDate: dayjs.Dayjs | null;
  endDate: dayjs.Dayjs | null;
  employee?: string;
  contractType?: string;
};

const ModalReportContent = ({
  startDate,
  endDate,
  employee,
  contractType,
}: ModalReportConentProps) => {
  const WithoutFiltersNotice = () => (
    <>
      <p>
        El Reporte se descargará desde incio de año hasta hoy:{' '}
        <b>{dayjs().endOf('day').format('DD/MMM/YYYY')}</b>
      </p>
      <div className="my-5">
        <Alert
          type="info"
          description="Para obtener un reporte más específico, aplica una búsqueda antes
              de proceder a la descarga."
          showIcon
        />
      </div>
    </>
  );

  const SelectedDateRangeNotice = () => (
    <div className="mt-5">
      {startDate && endDate && (
        <Row>
          <Col className="primary-02-txt" xs={4}>
            <p>
              <b>Fechas:</b>
            </p>
          </Col>
          <Col xs={20}>
            Del{' '}
            <strong>{startDate.startOf('day').format('DD/MMM/YYYY')}</strong>{' '}
            hasta <strong> {endDate.endOf('day').format('DD/MMM/YYYY')}</strong>
          </Col>
        </Row>
      )}
    </div>
  );

  const SelectedEmployeeNotice = () => (
    <div className="mt-5">
      {employee && (
        <Row>
          <Col xs={5}>
            <p className="primary-02-txt">
              <b>Empleado:</b>
            </p>
          </Col>
          <Col xs={17}>
            <p>{employee}</p>
          </Col>
        </Row>
      )}
    </div>
  );

  const SelectedContractTypeNotice = () => (
    <div className="mt-5">
      {contractType && (
        <Row>
          <Col xs={7}>
            <p className="primary-02-txt">
              <b>Tipo de contrato:</b>
            </p>
          </Col>
          <Col xs={14}>
            <p>{contractType}</p>
          </Col>
        </Row>
      )}
    </div>
  );

  return (
    <div>
      <p>El reporte se descargará con las siguientes opciones:</p>
      <Divider />
      <>
        {!startDate && !endDate && !employee ? (
          <WithoutFiltersNotice />
        ) : (
          <>
            {startDate && endDate && <SelectedDateRangeNotice />}
            {employee && <SelectedEmployeeNotice />}
            {contractType && <SelectedContractTypeNotice />}
          </>
        )}
      </>
    </div>
  );
};

export default ModalReportContent;
