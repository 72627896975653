import { PageTitle } from 'components/common/PageTitle/PageTitle';
import IncidenceCategoryNewForm from 'components/incidences/category/IncidenceCategoryNewForm';

const IncidenceCategoryNewPage = () => {
  return (
    <>
      <PageTitle>Crear tipo incidencia</PageTitle>
      <IncidenceCategoryNewForm />
    </>
  );
};

export default IncidenceCategoryNewPage;
