import { Button, Col, Result, Row, Skeleton } from 'antd';
import { PageTitle } from 'components/common/PageTitle/PageTitle';
import { ReconcileIncidences } from 'components/incidences/ReconcileIncidences';
import { globalPaths } from 'constants/routePaths';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import User from 'types/user';
import * as Sentry from '@sentry/react';
import { getOne } from 'api/user.api';

const ReconcileIncidencesPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { employeeId } = params;
  const [employee, setEmployee] = useState<User>();
  const [loading, setLoading] = useState(false);

  const getEmployee = useCallback(async () => {
    setLoading(true);
    if (employeeId) {
      try {
        const employee = await getOne(employeeId);
        if (employee && employee.data) {
          setEmployee(employee.data);
        }
      } catch (e) {
        Sentry.captureMessage(
          `No fue posible obtener la información del empleado ${employeeId}: ${e as Error}`
        );
      } finally {
        setLoading(false);
      }
    }
  }, [employeeId]);

  useEffect(() => {
    getEmployee();
  }, [getEmployee]);

  return (
    <>
      <PageTitle>{`Conciliar incidencias`}</PageTitle>
      {loading && !employee && (
        <Row>
          <Col>
            <Skeleton loading />
          </Col>
        </Row>
      )}
      {employee && !loading && <ReconcileIncidences employee={employee} />}
      {!employee && !loading && (
        <Result
          status="error"
          title="Hubo un error al cargar la información necesaria para conciliar las incidencias"
          subTitle="Por favor vuelve a intentarlo más tarde."
          extra={[
            <Button
              type="primary"
              key="back"
              onClick={() => navigate(`${globalPaths.basePath}`)}
            >
              Regresar
            </Button>,
            <Button key="reload" onClick={() => window.location.reload()}>
              Reintentar
            </Button>,
          ]}
        />
      )}
    </>
  );
};

export default ReconcileIncidencesPage;
