import { CustomFormItem, FormQuestionTypes } from 'interfaces/form.interfaces';
import { missingFieldRule } from 'constants/global';

export const groupFormItems: CustomFormItem[] = [
  {
    name: 'name',
    label: 'Nombre del grupo',
    type: FormQuestionTypes.input,
    visibleOnCreate: true,
    visibleOnEdit: true,
    rules: missingFieldRule,
  },
  {
    name: 'code',
    label: 'Clave única',
    type: FormQuestionTypes.input,
    visibleOnCreate: true,
    visibleOnEdit: true,
    rules: missingFieldRule,
  },
  {
    name: 'grade',
    label: 'Grado',
    type: FormQuestionTypes.numberInput,
    visibleOnCreate: true,
    visibleOnEdit: true,
    rules: missingFieldRule,
  },
  {
    name: 'career',
    label: 'Carrera',
    type: FormQuestionTypes.selectCareers,
    visibleOnCreate: true,
    visibleOnEdit: true,
    rules: missingFieldRule,
  },
  {
    name: 'specialty',
    label: 'Especialidad',
    type: FormQuestionTypes.selectSingleCareerSpecialty,
    visibleOnCreate: true,
    visibleOnEdit: true,
    rules: missingFieldRule,
  },
  {
    name: 'cycle',
    label: 'Ciclo',
    type: FormQuestionTypes.selectCycles,
    visibleOnCreate: true,
    visibleOnEdit: true,
    rules: missingFieldRule,
  },
];
