import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Form, Input, Row, Select, Spin, Switch } from 'antd';
import type { FormInstance } from 'antd/es/form';
import { ApiError, User } from '../../types';
import { useAppDispatch } from 'hooks/reduxHooks';
import { doCreateOne, doList } from 'store/slices/userSlice';
import { usersPaths } from 'constants/routePaths';
import * as Sentry from '@sentry/react';
import { AdminRole, EmployeeRole, SupportRole } from 'constants/enums/roles';
import notificationHook from 'hooks/notificationHook';
import statusMessages from 'statusMessages';
import { BackButton } from 'components/common/BackButton/BackButton';

export const UserNew = () => {
  const dispatch = useAppDispatch();
  const { Option } = Select;
  const [userStatus, setUserStatus] = useState(false);
  const navigate = useNavigate();
  const formRef = React.useRef<FormInstance>(null);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(doList());
  }, [dispatch]);

  const isValidId = (value: string) => {
    const validator = /^[1-9][0-9]*$/;
    return validator.test(value);
  };
  const onFinish = (values: User) => {
    values.status = userStatus;
    submit(values);
  };

  const submit = async (values: User) => {
    values.status = userStatus;
    setLoading(true);
    dispatch(doCreateOne(values))
      .unwrap()
      .then(() => {
        notificationHook({
          type: 'success',
          message: 'Empleado creado con éxito',
        });
        setLoading(false);
        navigate(usersPaths.basePath);
      })
      .catch((error: unknown) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          type: 'error',
          message: statusMessages.user[
            currentStatus ? currentStatus : 500
          ] as string,
        });
        Sentry.captureException(error as Error);
        setLoading(false);
      });
  };

  return (
    <div id="new-employee-container">
      <h2 className="title">Nuevo empleado</h2>
      <BackButton />
      <Spin spinning={loading}>
        <Row justify="center">
          <Col xs={10}>
            <Form
              ref={formRef}
              onFinish={onFinish}
              autoComplete="off"
              form={form}
              labelCol={{ span: 6 }}
            >
              <Form.Item
                name="idEmployee"
                label="ID del empleado"
                rules={[
                  {
                    required: true,
                    message: 'Debes ingresar un ID válido',
                    type: 'string',
                  },
                  {
                    validator: (_: unknown, value: string) => {
                      if (!isValidId(value)) {
                        return Promise.reject(
                          new Error(
                            'Ingresa un ID de sólo números, el ID no debe comenzar con 0'
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                hasFeedback
              >
                <Input
                  data-cy="employeeId"
                  placeholder="Ingresa ID del empleado"
                  type="number"
                />
              </Form.Item>
              <Form.Item
                name="name"
                label="Nombre(s)"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingresa el/los nombre(s)',
                    type: 'string',
                  },
                ]}
                hasFeedback
              >
                <Input
                  data-cy="name"
                  placeholder="Ingresa el/los nombre(s)"
                  id="name"
                />
              </Form.Item>
              <Form.Item
                name="lastName"
                label="Apellido(s)"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingresa el/los apellido(s)',
                    type: 'string',
                  },
                ]}
                hasFeedback
              >
                <Input
                  data-cy="lastName"
                  placeholder="Ingresa el/los apellido(s)"
                  id="lastName"
                />
              </Form.Item>
              <Form.Item
                name="email"
                label="Correo electrónico"
                rules={[
                  {
                    required: true,
                    message: 'Ingresa un correo electrónico válido',
                    type: 'email',
                  },
                ]}
                hasFeedback
              >
                <Input
                  data-cy="email"
                  placeholder="Ingresa el correo electrónico"
                  id="email"
                />
              </Form.Item>
              <Form.Item name="status" label="Estatus" valuePropName="checked">
                <Row align="middle">
                  <Col>
                    <Switch
                      onChange={(e: boolean) => {
                        setUserStatus(e);
                      }}
                      id="status"
                      data-cy="status-switch"
                    />
                  </Col>
                  <Col className="ml-2">
                    {userStatus && userStatus === true ? (
                      <span className="active-text">Activo</span>
                    ) : (
                      <span className="inactive-text">Inactivo</span>
                    )}
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item name="role" label="Rol" hasFeedback>
                <Select
                  data-cy="role-select"
                  placeholder="Selecciona los permisos"
                  id="permissions"
                >
                  <Option data-cy={`${AdminRole}-role`} value={AdminRole}>
                    Administrador
                  </Option>
                  <Option data-cy={`${SupportRole}-role`} value={SupportRole}>
                    Soporte
                  </Option>
                  <Option data-cy={`${EmployeeRole}-role`} value={EmployeeRole}>
                    Empleado
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="phone"
                label="Número de teléfono"
                rules={[
                  {
                    pattern: /^([0-9]{10})$/,
                    message:
                      'El número telefónico debe ser válido y contener 10 dígitos.',
                  },
                ]}
                hasFeedback
              >
                <Input
                  data-cy="phone"
                  placeholder="Ingresa el número de teléfono"
                  id="phone"
                  type="number"
                />
              </Form.Item>
              <Form.Item>
                <Row justify="end" gutter={8}>
                  <Col>
                    <Button
                      onClick={() => navigate(usersPaths.basePath)}
                      id="cancelBtn"
                      type="default"
                      data-cy="cancelBtn"
                    >
                      Regresar
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      data-cy="saveBtn"
                      htmlType="submit"
                      id="saveBtn"
                      type="primary"
                    >
                      Agregar empleado
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};
