import { httpApi } from 'api/http.api';
import { AxiosResponse } from 'axios';
import { ReportFilters } from 'types/reports';

const controller = `reports`;

export type ResponseExcelType = {
  succesMsg: string;
  failedMsg: string;
};

export const getChecksReport = (
  payload: ReportFilters
): Promise<AxiosResponse<string | Blob>> =>
  httpApi
    .post<string | Blob>(`${controller}/checksReport`, payload, {
      responseType: 'blob',
    })
    .then((res) => res);

export const getEventsReport = (
  payload: ReportFilters
): Promise<AxiosResponse<string | Blob>> =>
  httpApi
    .post<string | Blob>(`${controller}/eventsReport`, payload, {
      responseType: 'blob',
    })
    .then((res) => res);

export const getIncidencesReport = (
  payload: ReportFilters
): Promise<AxiosResponse<string | Blob>> =>
  httpApi
    .post<string | Blob>(`${controller}/incidencesReport`, payload, {
      responseType: 'blob',
    })
    .then((res) => res);
