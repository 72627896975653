import dayjs from 'dayjs';
import { getFormattedDate } from './formatDate';
import { EmployeeIncidences, WeeklyKardexIncidence } from 'types/incidences';

export const getInitialWeekRange = (date: Date) => {
  const startOfWeek = dayjs(date).startOf('week').toDate();
  const endOfWeek = dayjs(date).endOf('week').toDate();
  const from = getFormattedDate(startOfWeek);
  const to = getFormattedDate(endOfWeek);
  return {
    from,
    to,
  };
};

export const getWeekRange = (dateRange: Date[]) => {
  const startOfWeek = dateRange[0];
  const endOfWeek = dateRange[dateRange.length - 1];
  const from = getFormattedDate(startOfWeek);
  const to = getFormattedDate(endOfWeek);
  return {
    from,
    to,
  };
};

export const getWeeklyKardexIncidences = (incidences: EmployeeIncidences[]) => {
  // Create events as react-big-calendar requests
  const WeeklyKardexIncidences: WeeklyKardexIncidence[] = [];
  incidences.forEach((incidence) => {
    WeeklyKardexIncidences.push({
      title: incidence.category.abbr,
      start: new Date(incidence.startDate),
      end: new Date(incidence.endDate),
      color: incidence.category.color,
      txtColor: incidence.category.txtColor,
    });
  });
  return WeeklyKardexIncidences;
};
