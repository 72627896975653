import { Card, Col, Row } from 'antd';
import { getTodayFormattedDate, getTodayWeekdayName } from 'hooks/formatDate';
import { DashboardEmployeesListProps } from 'interfaces/employee.interfaces';
import { EmployeeWithScheduleOfToday } from 'types/user';
import { capitalize } from 'utils';
import { EmployeeRow } from './EmployeeRow';

export const Employees = ({
  todaysScheduleByEmployee,
}: DashboardEmployeesListProps) => {
  const CardTitle = () => (
    <Row align="middle" justify="space-between">
      <Col>
        <h3 className="sub-title">Empleados</h3>
      </Col>
      <Col className="text-end">
        <p className="date">
          {capitalize(getTodayWeekdayName())}{' '}
          <b>{capitalize(getTodayFormattedDate({ format: 'dd/MM/yyyy' }))}</b>
        </p>
      </Col>
    </Row>
  );

  return (
    <div id="employees">
      <Card title={<CardTitle />} className="card vertical-card">
        <Row justify="center">
          <Col xl={24}>
            {todaysScheduleByEmployee.map(
              (todayEmployeeSchedule: EmployeeWithScheduleOfToday) => (
                <div
                  className="employee-row"
                  key={`employee-${todayEmployeeSchedule.employee._id}`}
                >
                  <EmployeeRow
                    todaysEmployeeSchedules={todayEmployeeSchedule}
                  />
                </div>
              )
            )}
          </Col>
        </Row>
      </Card>
    </div>
  );
};
