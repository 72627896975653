import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getAllUsers,
  getOne,
  udpateOne,
  createOne,
  deleteUser,
} from 'api/user.api';
import { User } from 'types';
import { UserUpdatePayloadType } from 'types/user';
import { SliceStatus } from 'constants/enums/slices';

export interface UserSlice {
  users: User[];
  status: SliceStatus;
  lastUserSearch: string;
}

const initialState: UserSlice = {
  users: [],
  status: SliceStatus.Empty,
  lastUserSearch: '',
};

export const doCreateOne = createAsyncThunk(
  'user/new',
  async (User: User, { rejectWithValue }) => {
    try {
      await createOne(User);
      return await getAllUsers();
    } catch (error) {
      return await rejectWithValue(error);
    }
  }
);

export const doGetOne = createAsyncThunk(
  'user/getone',
  async (UserId: string, { rejectWithValue }) => {
    try {
      return await getOne(UserId);
    } catch (error) {
      return await rejectWithValue(error);
    }
  }
);

export const doUpdateOne = createAsyncThunk(
  'user/update',
  async (UserUpdatePayload: UserUpdatePayloadType, { rejectWithValue }) => {
    try {
      await udpateOne(UserUpdatePayload);
      return await getAllUsers();
    } catch (error) {
      return await rejectWithValue(error);
    }
  }
);

export const doList = createAsyncThunk(
  'user/list',
  async (filter: string | undefined, { rejectWithValue }) => {
    try {
      return await getAllUsers(filter);
    } catch (error) {
      return await rejectWithValue(error);
    }
  }
);

export const doDelete = createAsyncThunk(
  'user/delete',
  async (userId: string, { rejectWithValue }) => {
    try {
      await deleteUser(userId);
      return await getAllUsers();
    } catch (error) {
      return await rejectWithValue(error);
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLastSearch: (state, action) => {
      state.lastUserSearch = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(doList.pending, (state) => {
      state.status = SliceStatus.Updating;
    });
    builder.addCase(doList.fulfilled, (state, action) => {
      state.users = action.payload.data;
      state.status = SliceStatus.Fulfilled;
    });
    builder.addCase(doUpdateOne.fulfilled, (state, action) => {
      state.users = action.payload.data;
      state.status = SliceStatus.Fulfilled;
    });
  },
});

export const { setLastSearch } = userSlice.actions;

export default userSlice.reducer;
