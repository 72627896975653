import { Modal, List } from 'antd';
import { day } from 'constants/schedule';
import { FeedbackModalProps } from 'interfaces/schedule';

export const GroupScheduleFeedack = ({
  conflictingExistingSchedules,
  conflictingNewSchedules,
  showFeedbackModal,
  handleCloseFeedback,
}: FeedbackModalProps) => {
  return (
    <Modal
      open={showFeedbackModal}
      title="No se pudieron crear los horarios"
      onCancel={handleCloseFeedback}
      onOk={handleCloseFeedback}
      cancelText="Cerrar"
      okText="Aceptar"
      data-cy="group-schedule-feedback"
    >
      {conflictingExistingSchedules.length > 0 && (
        <>
          <p>Los siguientes horarios se solapan con alguno ya existente:</p>
          <List
            dataSource={conflictingExistingSchedules}
            renderItem={(schedule) => (
              <List.Item>
                <List.Item.Meta
                  title={`Día: ${day[schedule.dayOfWeek]}`}
                  description={`Hora de inicio: ${schedule.initialHour}, Hora de fin: ${schedule.endHour}`}
                />
              </List.Item>
            )}
          />
        </>
      )}
      {conflictingNewSchedules.length > 0 && (
        <>
          <p>Los siguientes nuevos horarios se solapan entre sí:</p>
          <List
            dataSource={conflictingNewSchedules}
            renderItem={(schedule) => (
              <List.Item>
                <List.Item.Meta
                  title={`Día: ${day[schedule.dayOfWeek]}`}
                  description={`Horario 1: ${schedule.firstSchedule}, Horario 2: ${schedule.secondSchedule}`}
                />
              </List.Item>
            )}
          />
        </>
      )}
    </Modal>
  );
};
