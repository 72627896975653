import { Col, ColorPicker, ColorPickerProps, Divider, Row, theme } from 'antd';
import { generate, green, presetPalettes, red, cyan } from '@ant-design/colors';
import { Color } from 'antd/es/color-picker';

interface HorizontalColorPickerProps {
  onChange: (value: Color, hex: string) => void;
  defaultValue: string;
}

type Presets = Required<ColorPickerProps>['presets'][number];

const genPresets = (presets = presetPalettes) =>
  Object.entries(presets).map<Presets>(([label, colors]) => ({
    label,
    colors,
  }));

export const HorizontalColorPicker = ({
  onChange,
  defaultValue,
}: HorizontalColorPickerProps) => {
  const { token } = theme.useToken();

  const presets = genPresets({
    primary: generate(token.colorPrimary),
    red,
    green,
    cyan,
  });

  const customPanelRender: ColorPickerProps['panelRender'] = (
    _,
    { components: { Picker, Presets } }
  ) => (
    <Row justify="space-between" wrap={false}>
      <Col span={12}>
        <Presets />
      </Col>
      <Divider type="vertical" style={{ height: 'auto' }} />
      <Col flex="auto">
        <Picker />
      </Col>
    </Row>
  );

  return (
    <ColorPicker
      onChange={onChange}
      format="hex"
      defaultValue={defaultValue}
      presets={presets}
      styles={{ popupOverlayInner: { width: 500 } }}
      panelRender={customPanelRender}
    />
  );
};
