import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createOne, getAll, updateOne } from 'api/contract.api';
import { SliceStatus } from 'constants/enums/slices';
import Contract, { UpdateContract } from 'types/contract';

export interface ContractSlice {
  contracts: Contract[];
  status: SliceStatus;
}

const initialState: ContractSlice = {
  contracts: [],
  status: SliceStatus.Empty,
};

export const doCreateOne = createAsyncThunk(
  'contract/new',
  async (contract: Contract, { rejectWithValue }) => {
    try {
      await createOne(contract);
      return await getAll();
    } catch (error) {
      return await rejectWithValue(error);
    }
  }
);

export const doList = createAsyncThunk(
  'contract/list',
  async (_, { rejectWithValue }) => {
    try {
      return await getAll();
    } catch (error) {
      return await rejectWithValue(error);
    }
  }
);

export const doUpdateOne = createAsyncThunk(
  'contract/update',
  async (contract: UpdateContract, { rejectWithValue }) => {
    try {
      const response = await updateOne(contract);
      await getAll();
      return response;
    } catch (error) {
      return await rejectWithValue(error);
    }
  }
);

const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doList.pending, (state) => {
      state.status = SliceStatus.Updating;
    });
    builder.addCase(doList.fulfilled, (state, action) => {
      state.contracts = action.payload.data;
      state.status = SliceStatus.Fulfilled;
    });
  },
});

export default contractSlice.reducer;
