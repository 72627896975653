import { PageTitle } from 'components/common/PageTitle/PageTitle';
import { ForgotPasswordForm } from 'components/auth/ForgotPasswordForm/ForgotPasswordForm';

const ForgotPasswordPage = () => {
  return (
    <>
      <PageTitle>Recuperar Contraseña</PageTitle>
      <ForgotPasswordForm />
    </>
  );
};

export default ForgotPasswordPage;
