import authReducer from './authSlice';
import userReducer from 'store/slices/userSlice';
import contractSlice from 'store/slices/contractSlice';
import devicesSlice from 'store/slices/devicesSlice';
import themeSlice from './themeSlice';
import contractKindSlice from './contractKindSlice';
import dashboardSlice from './dashboardSlice';
import employeeSlice from './employeeSlice';
import incidenceSlice from './incidenceSlice';
import configSlice from './configSlice';
import cyclesSlice from './cyclesSlice';
import careersSlice from './careersSlice';
import holidaySlice from './holidaySlice';
import groupsSlice from './groupsSlice';
import subjectsSlice from './subjectsSlice';
import groupScheduleSlice from './groupScheduleSlice';

const store = {
  auth: authReducer,
  user: userReducer,
  contract: contractSlice,
  contractKind: contractKindSlice,
  device: devicesSlice,
  theme: themeSlice,
  dashboard: dashboardSlice,
  employee: employeeSlice,
  incidence: incidenceSlice,
  config: configSlice,
  cycle: cyclesSlice,
  career: careersSlice,
  subject: subjectsSlice,
  holiday: holidaySlice,
  group: groupsSlice,
  groupSchedule: groupScheduleSlice,
};

export default store;
