import { Form, Row, Col, Skeleton, Descriptions } from 'antd';
import { useEffect, useState } from 'react';
import { User } from '../../types';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { doList as userDoList } from 'store/slices/userSlice';
import { SliceStatus } from 'constants/enums/slices';
import * as Sentry from '@sentry/react';

interface IUserData {
  userId: string;
}

export const ShowEmployeeData = ({ userId }: IUserData) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [employee, setEmployee] = useState<User>();
  const dispatch = useAppDispatch();
  const { users, status: userStateStatus } = useAppSelector(
    (state) => state.user
  );

  useEffect(() => {
    setLoading(true);
    if (userStateStatus === SliceStatus.Empty) {
      dispatch(userDoList());
    } else if (userStateStatus === SliceStatus.Fulfilled && userId) {
      const currentUser: User | undefined = users
        .filter((c) => c && c._id === userId)
        .at(0);
      if (currentUser) {
        form.setFieldsValue(currentUser);
        setEmployee(currentUser);
      } else {
        Sentry.captureMessage('Not able to find a employee to show the data');
      }
    }
    setLoading(false);
  }, [userId, userStateStatus, dispatch, users, form]);

  return (
    <>
      {loading && (
        <Row>
          <Col xs={24}>
            <Skeleton active />
          </Col>
        </Row>
      )}
      {!loading && employee && (
        <>
          <Row id="employee-detail-table">
            <Col xs={12}>
              <h3 className="sub-title mb-5">Información del empleado</h3>
              <Descriptions title="" layout="horizontal" bordered>
                <Descriptions.Item label="ID del empleado" span={3}>
                  {form.getFieldValue('idEmployee')}
                </Descriptions.Item>
                <Descriptions.Item label="Nombre(s)" span={3}>
                  {form.getFieldValue('name')}
                </Descriptions.Item>
                <Descriptions.Item label="Apellido(s)" span={3}>
                  {form.getFieldValue('lastName')}
                </Descriptions.Item>
                <Descriptions.Item label="Correo electrónico" span={3}>
                  {form.getFieldValue('email')}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
