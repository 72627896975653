import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Form, Input, Row, Select, Spin, Switch } from 'antd';
import { ApiError, User } from '../../types';
import { useAppDispatch } from 'hooks/reduxHooks';
import { doUpdateOne } from 'store/slices/userSlice';
import { UserUpdatePayloadType } from 'types/user';
import { usersPaths } from 'constants/routePaths';
import * as Sentry from '@sentry/react';
import { AdminRole, EmployeeRole, SupportRole } from 'constants/enums/roles';
import { RouteParam } from 'types/routeParam';
import notificationHook from 'hooks/notificationHook';
import statusMessages from 'statusMessages';
import { BackButton } from 'components/common/BackButton/BackButton';

interface UserEditProps {
  user: User;
}

export const UserEdit = ({ user }: UserEditProps) => {
  const dispatch = useAppDispatch();

  const [userStatus, setUserStatus] = useState(user.status);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { Option } = Select;
  const { id } = useParams<RouteParam>();

  useEffect(() => {
    form.setFieldValue('supervisor', user.supervisor?._id);
  }, [form, user]);

  const isValidId = (value: string) => {
    const validator = /^[1-9][0-9]*$/;
    return validator.test(value);
  };
  const onFinish = (values: User) => {
    submit(values);
  };

  const submit = async (values: UserUpdatePayloadType) => {
    if (id) {
      setLoading(true);
      values._id = id;
      values.status = userStatus;
      await dispatch(doUpdateOne(values))
        .unwrap()
        .then(() => {
          notificationHook({
            type: 'success',
            message: 'Empleado actualizado con éxito !',
          });
          setLoading(false);
          navigate(usersPaths.basePath);
        })
        .catch((error) => {
          Sentry.captureException(error);
          const currentStatus = (error as ApiError).statusCode;
          notificationHook({
            type: 'error',
            message: statusMessages.user[
              currentStatus ? currentStatus : 500
            ] as string,
          });
          setLoading(false);
        });
    }
  };

  return (
    <div id="edit-employee-container">
      <h2 className="title">Editar empleado</h2>
      <BackButton />
      <Spin spinning={loading}>
        <Row justify="center">
          <Col xs={10}>
            <Form
              form={form}
              onFinish={onFinish}
              autoComplete="off"
              initialValues={user}
              labelCol={{ span: 6 }}
            >
              <Form.Item
                name="idEmployee"
                label="ID del empleado"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingrese el ID del empleado',
                    type: 'string',
                  },
                  {
                    validator: (_, value) => {
                      if (!isValidId(value)) {
                        return Promise.reject(
                          new Error(
                            'Ingresa un ID válido, el ID no debe comenzar con 0'
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                hasFeedback
              >
                <Input
                  type="number"
                  data-cy="employeeId"
                  placeholder="Ingresa ID del empleado"
                  id="employeeId"
                />
              </Form.Item>
              <Form.Item
                name="name"
                label="Nombre(s)"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingresa el/los nombre(s)',
                    type: 'string',
                  },
                ]}
                hasFeedback
              >
                <Input
                  data-cy="name"
                  placeholder="Ingresa el/los nombre(s)"
                  id="name"
                />
              </Form.Item>
              <Form.Item
                name="lastName"
                label="Apellido(s)"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingresa el/los apellido(s)',
                    type: 'string',
                  },
                ]}
                hasFeedback
              >
                <Input
                  data-cy="lastName"
                  placeholder="Ingresa el/los apellido(s)"
                  id="lastName"
                />
              </Form.Item>
              <Form.Item
                name="email"
                label="Correo electrónico"
                rules={[
                  {
                    required: true,
                    message: 'Ingresa un correo electrónico válido',
                    type: 'email',
                  },
                ]}
                hasFeedback
              >
                <Input
                  data-cy="email"
                  placeholder="Ingresa el correo electrónico"
                  id="email"
                />
              </Form.Item>
              <Form.Item name="status" label="Estatus" valuePropName="checked">
                <Row align="middle">
                  <Col>
                    <Switch
                      onChange={(e: boolean) => {
                        setUserStatus(e);
                      }}
                      defaultChecked={user.status}
                      id="status"
                      data-cy="status-switch"
                    />
                  </Col>
                  <Col className="ml-2">
                    {userStatus && userStatus === true ? (
                      <span className="active-text">Activo</span>
                    ) : (
                      <span className="inactive-text">Inactivo</span>
                    )}
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item name="role" label="Rol" hasFeedback>
                <Select
                  data-cy="role-select"
                  placeholder="Selecciona los permisos"
                  id="permissions"
                >
                  <Option data-cy={`${AdminRole}-role`} value={AdminRole}>
                    Administrador
                  </Option>
                  <Option data-cy={`${SupportRole}-role`} value={SupportRole}>
                    Soporte
                  </Option>
                  <Option data-cy={`${EmployeeRole}-role`} value={EmployeeRole}>
                    Empleado
                  </Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="phone"
                label="Número de teléfono"
                rules={[
                  {
                    pattern: /^([0-9]{10})$/,
                    message:
                      'El número telefónico debe ser válido y contener 10 dígitos.',
                  },
                ]}
                hasFeedback
              >
                <Input
                  data-cy="phone"
                  placeholder="Ingresa el número de teléfono"
                  id="phone"
                  type="number"
                />
              </Form.Item>
              <Form.Item>
                <Row justify="end" gutter={10}>
                  <Col>
                    <Button
                      onClick={() => navigate(usersPaths.basePath)}
                      id="cancelBtn"
                      type="default"
                      data-cy="cancelBtn"
                    >
                      Regresar
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      data-cy="saveBtn"
                      htmlType="submit"
                      id="saveBtn"
                      type="primary"
                    >
                      Guardar
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};
