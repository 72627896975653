import { PageTitle } from 'components/common/PageTitle/PageTitle';
import { LockForm } from 'components/auth/LockForm/LockForm';

const LockPage = () => {
  return (
    <>
      <PageTitle>{`Pantalla Bloqueada `}</PageTitle>
      <LockForm />
    </>
  );
};

export default LockPage;
