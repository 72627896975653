import { toZonedTime, format } from 'date-fns-tz';
import { parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import type { Dayjs } from 'dayjs';

export const formateDatetoPetitionHHmm = (date: string): string => {
  //Recives `HH:mm:ss`
  //Return  date on `HH:mm:ssZ`
  return date + 'Z';
};
export const removeZOnHourtoDisplay = (hour: string): string => {
  //Recives  'HH:mm:ssZ'
  const temp = hour.split(':');
  return `${temp[0]}:${temp[1]}`;
};

export const dateForDisplay = (dateISO: string, onlyDate?: boolean): string => {
  let formattedDate: string;
  //Revices Date on ISO string
  //Return
  const mexicoTimezone = 'America/Chihuahua';
  // parseamos la fecha ISO y la convertimos a hora local de México
  const fechaLocal = toZonedTime(parseISO(dateISO), mexicoTimezone);
  // formateamos la fecha local a un string con el formato deseado
  if (onlyDate) {
    formattedDate = format(fechaLocal, 'dd/MMMM/yyyy', {
      timeZone: mexicoTimezone,
      locale: es,
    });
  } else {
    formattedDate = format(fechaLocal, 'dd/MMMM/yyyy HH:mm:ss', {
      timeZone: mexicoTimezone,
      locale: es,
    });
  }

  return formattedDate;
};

export const formatScheduleIntervalDate = (dayjsDate: Dayjs) => {
  const ISODate = dayjsDate.toISOString();
  const date = new Date(ISODate);
  const localDate = date.toLocaleString('es-Mx', {
    timeZone: 'America/Chihuahua',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  });
  const timeFormat = formateDatetoPetitionHHmm(localDate);
  return timeFormat;
};
