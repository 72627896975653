import { Button, Col, DatePicker, DatePickerProps, Row } from 'antd';
import { ErrorResult } from 'components/common/ErrorResult';
import { LoadingCard } from 'components/common/LoadingCard';
import { PageTitle } from 'components/common/PageTitle/PageTitle';
import { HolidayCard } from 'components/holidays/HolidayCard';
import { SliceStatus } from 'constants/enums/slices';
import { orderHolidaysByMonth } from 'hooks/holidaysHooks';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { HolidaysByMonth } from 'interfaces/holidays.interfaces';
import { useEffect, useState } from 'react';
import { doListHolidays } from 'store/slices/holidaySlice';
import dayjs from 'dayjs';

const currentYear = dayjs().year();

export const HolidaysListPage = () => {
  const [holidaysByMonth, setHolidaysByMonth] = useState<HolidaysByMonth[]>();
  const dispatch = useAppDispatch();
  const { holidaysList, holidaysListStatus } = useAppSelector(
    (state) => state.holiday
  );
  const [year, setYear] = useState(dayjs(`${currentYear}`));

  if (holidaysListStatus === SliceStatus.Empty) {
    dispatch(doListHolidays(year.year()));
  }
  useEffect(() => {
    const orderedHolidays = orderHolidaysByMonth(holidaysList);
    setHolidaysByMonth(orderedHolidays);
  }, [holidaysList]);

  const ListOfHolidays = (holidaysByMonth: HolidaysByMonth[]) => (
    <Row className="mt-6" gutter={[20, 20]}>
      {holidaysByMonth.map((monthHolidays, index) => (
        <Col xs={6} key={`holiday-${index}`}>
          <HolidayCard
            month={monthHolidays.month}
            holidays={monthHolidays.holidays}
          />
        </Col>
      ))}
    </Row>
  );

  const onYearChange: DatePickerProps['onChange'] = (date, dateString) => {
    if (date) {
      const selectedYear = dayjs(dateString as string);
      setYear(selectedYear);
      dispatch(doListHolidays(selectedYear.year()));
    }
  };

  const setCurrentYear = () => {
    const actualYear = dayjs(`${currentYear}`);
    setYear(actualYear);
    dispatch(doListHolidays(actualYear.year()));
  };

  return (
    <div id="holidays-list-page">
      <PageTitle>Días festivos</PageTitle>
      <Row align="middle">
        <Col xs={6}>
          <h1 className="title">Lista de días festivos</h1>
        </Col>
        <Col xs={18}>
          <Row align="middle" gutter={16}>
            <Col xs={4}>
              <DatePicker picker="year" value={year} onChange={onYearChange} />
            </Col>
            <Col>
              <Button className="btn-link" onClick={setCurrentYear}>
                Año actual
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {holidaysListStatus === SliceStatus.Updating && <LoadingCard />}
      {holidaysListStatus === SliceStatus.Fulfilled &&
        holidaysByMonth &&
        ListOfHolidays(holidaysByMonth)}
      {holidaysListStatus === SliceStatus.Error && (
        <ErrorResult
          dataCy={'holidays-list-error'}
          title="No se pudo obtener la lista de días festivos"
        />
      )}
    </div>
  );
};
