import {
  AdminRole,
  EmployeeRole,
  SupportRole,
  UserRoles,
} from 'constants/enums/roles';
import { globalPaths, devicesPaths, kardexPaths } from 'constants/routePaths';

export const redirectAfterLogin = (role: UserRoles) => {
  switch (role) {
    case AdminRole:
      return globalPaths.basePath;
    case SupportRole:
      return devicesPaths.basePath;
    case EmployeeRole:
      return `${kardexPaths.base}/${kardexPaths.self}`;
  }
};
