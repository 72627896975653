import { Button, Col, Modal, Row, Spin, Table, TableProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { getIncidencesList, removeOne } from 'api/incidence.api';
import { Incidence } from 'types/incidences';
import { incidencePaths } from 'constants/routePaths';
import { ReportFilter } from 'components/reports/elements/ReportFilter';
import dayjs from 'dayjs';
import {
  getEndOfDate,
  getStartEndOfMonth,
  getStartEndOfMonthDayjs,
  getStartOfDate,
} from 'hooks/getStartEndOfDate';
import ModalReportContent from 'components/reports/checks/ModalReportContent';
import { ReportFilters } from 'types/reports';
import { dateForDisplay } from 'libs/dateFormaterPetition';
import { getIncidencesReport } from 'api/reports.api';
import { defaultContractType } from 'constants/ReportConstants';
import * as Sentry from '@sentry/react';
import { pageSize } from 'constants/global';
import notificationHook from 'hooks/notificationHook';
import { ApiError } from 'types';
import statusMessages from 'statusMessages';
import { TableDeletePopOver } from 'components/TableDeletePopOver/TableDeletePopOver';

type IncidencesListProps = {
  incidences: Incidence[];
  pages: number;
};

const IncidencesList = ({ incidences, pages }: IncidencesListProps) => {
  const navigate = useNavigate();
  const [incidencesData, setIncidencesData] = useState<Incidence[]>([]);
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(null);
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(null);
  const [employee, setEmployee] = useState('');
  const [contractType, setContractType] = useState(defaultContractType);
  const [loadingTable, setLoadingTable] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const { startOfMonth, endOfMonth } = getStartEndOfMonth();
  const { dayjsStartOfMonth, dayjsEndOfMonth } = getStartEndOfMonthDayjs();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const tableProps: TableProps<Incidence> = {
    bordered: true,
    tableLayout: 'fixed',
    size: 'small',
    pagination: {
      position: ['bottomCenter'],
      pageSize: pageSize,
      total: totalPages,
      onChange: (page) => {
        setCurrentPage(page);
      },
    },
    className: 'table-ps',
  };

  useEffect(() => {
    setIncidencesData(incidences);
    setTotalPages(pages);
  }, [incidences, pages]);

  useEffect(() => {
    setStartDate(dayjsStartOfMonth);
    setEndDate(dayjsEndOfMonth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilterOptions = useCallback(() => {
    const filterOptions: ReportFilters = {
      from: startDate ? getStartOfDate(startDate) : startOfMonth,
      to: endDate ? getEndOfDate(endDate) : endOfMonth,
      employee: employee !== '' ? employee : undefined,
      contractType: contractType !== '' ? contractType : undefined,
    };
    return filterOptions;
  }, [contractType, employee, endDate, endOfMonth, startDate, startOfMonth]);

  const search = useCallback(() => {
    setLoadingTable(true);
    const filterOptions = getFilterOptions();
    getIncidencesList(filterOptions, currentPage, pageSize).then((res) => {
      setIncidencesData(res.data.data);
      setTotalPages(res.data.total);
      setLoadingTable(false);
    });
  }, [getFilterOptions, currentPage]);

  const updateIncidences = () => {
    search();
  };

  useEffect(() => {
    search();
  }, [startDate, endDate, employee, contractType, search]);

  const showModal = () => {
    setOpen(true);
  };

  const deleteIncidence = async (id: string) => {
    try {
      await removeOne(id);
      updateIncidences();
    } catch (error: unknown) {
      Sentry.captureException(error);
      const currentStatus = (error as ApiError).statusCode;
      notificationHook({
        message: statusMessages.incidence[
          currentStatus ? currentStatus : 500
        ] as string,
        type: 'error',
      });
    }
  };

  const handleStartDateChange = (date: dayjs.Dayjs | null) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: dayjs.Dayjs | null) => {
    setEndDate(date);
  };

  const handleEmployeeChange = (employee: string) => {
    setEmployee(employee);
  };

  const handleContractTypeChange = (value: string) => {
    setContractType(value);
  };

  const columns: ColumnsType<Incidence> = [
    {
      title: 'ID Empleado',
      key: `idEmployee`,
      dataIndex: 'idEmployee',
      render: (_, record) => <>{record.idEmployee ?? 'No Registrado'}</>,
    },
    {
      title: 'Apellido(s)',
      key: 'lastName',
      dataIndex: 'lastName',
      render: (_, record) => <>{record.lastName ?? 'No Registrado'}</>,
    },
    {
      title: 'Nombre(s)',
      key: 'name',
      dataIndex: 'name',
      render: (_, record) => <>{record.name ?? 'No Registrado'}</>,
    },
    {
      title: 'Tipo de contrato',
      key: 'contractType',
      dataIndex: 'contractType',
    },
    {
      title: 'Fecha de inicio',
      key: 'startDate',
      dataIndex: 'startDate',
      render: (startDate) =>
        startDate ? dateForDisplay(startDate, true) : 'Sin fecha vigente',
    },
    {
      title: 'Fecha de finalización',
      key: 'endDate',
      dataIndex: 'endDate',
      render: (endDate) =>
        endDate ? dateForDisplay(endDate, true) : 'Sin fecha vigente',
    },
    {
      title: 'Categoria',
      key: 'category',
      dataIndex: 'category',
    },
    {
      title: 'Opciones',
      key: 'options',
      align: 'center',
      dataIndex: 'options',
      render: (_, record) => (
        <div className="option-buttons">
          <TableDeletePopOver id={record._id} deleteHandler={deleteIncidence} />
        </div>
      ),
    },
  ];

  const generateIncidentsReport = () => {
    setConfirmLoading(true);
    try {
      const filterOptions = getFilterOptions();
      getIncidencesReport(filterOptions).then((data) => {
        setConfirmLoading(false);
        setOpen(false);
        const fileName = `Reporte_incidencias_${filterOptions.contractType}_${filterOptions.from}_al_${filterOptions.to}.xlsx`;
        const archivoUrl = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement('a');
        link.href = archivoUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      Sentry.captureException(error);
      setConfirmLoading(false);
      setOpen(false);
      const currentStatus = (error as ApiError).statusCode;
      notificationHook({
        message: statusMessages.reports[
          currentStatus ? currentStatus : 500
        ] as string,
        type: 'error',
      });
    }
  };

  const resetFilters = () => {
    setStartDate(dayjsStartOfMonth);
    setEndDate(dayjsEndOfMonth);
    setEmployee('');
    setContractType(defaultContractType);
  };

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <h2 className="title">Reporte de incidencias</h2>
        </Col>
        <Col>
          <Button
            id="btnNewIncidece"
            type="primary"
            onClick={() => {
              navigate(`${incidencePaths.basePath}/${incidencePaths.new}`);
            }}
          >
            Crear incidencia
          </Button>
        </Col>
      </Row>
      <ReportFilter
        startDate={startDate}
        endDate={endDate}
        employee={employee}
        contractType={contractType}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        updateData={updateIncidences}
        resetFilters={resetFilters}
        showModal={showModal}
        handleEmployeeChange={handleEmployeeChange}
        handleContractTypeChange={handleContractTypeChange}
      />
      <Spin spinning={loadingTable} tip="Cargando...">
        <Table
          {...tableProps}
          dataSource={incidencesData}
          columns={columns}
          loading={loadingTable}
          id="incidenceTable"
          rowKey={'_id'}
        />
      </Spin>
      <Modal
        title="Descarga de Reporte"
        open={open}
        onOk={generateIncidentsReport}
        okText="Descargar"
        cancelText="Cancelar"
        destroyOnClose
        confirmLoading={confirmLoading}
        onCancel={() => {
          setOpen(false);
        }}
      >
        <ModalReportContent
          startDate={startDate}
          endDate={endDate}
          employee={employee}
          contractType={contractType}
        />
      </Modal>
    </>
  );
};

export default IncidencesList;
