import { PageTitle } from 'components/common/PageTitle/PageTitle';
import { useNavigate } from 'react-router-dom';
import ChecksList from 'components/reports/checks/ChecksList';
import { useEffect, useState } from 'react';
import { Col, Row, Skeleton, Result, Button } from 'antd';
import { getStartEndOfMonth } from 'hooks/getStartEndOfDate';
import { defaultContractType } from 'constants/ReportConstants';
import { getChecksList } from 'api/checks.api';
import { AllChecksResponseType } from 'types/checks';
import { globalPaths } from 'constants/routePaths';
import { defaultPaginationOptions } from 'constants/global';

const ChecksPage = () => {
  const navigate = useNavigate();
  const { startOfMonth, endOfMonth } = getStartEndOfMonth();
  const [checksData, setChecksData] = useState<AllChecksResponseType[] | null>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const { page, limit } = defaultPaginationOptions;

  const firstSearch = () => {
    setIsLoading(true);
    const filterOptions = {
      from: startOfMonth,
      to: endOfMonth,
      contractType: defaultContractType,
    };
    getChecksList(filterOptions, page, limit)
      .then((res) => {
        if (res.data.data != undefined) {
          setChecksData(res.data.data);
        } else {
          setChecksData([]);
        }
        setTotalPages(res.data.total);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setChecksData(null);
        setIsError(true);
      });
  };

  useEffect(() => {
    firstSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageTitle>{`Reporte de chequeos`}</PageTitle>
      {isLoading ? (
        <Row>
          <Col xs={24}>
            <Skeleton active />
          </Col>
        </Row>
      ) : (
        <>
          {!isError ? (
            <>
              {checksData && (
                <ChecksList checks={checksData} pages={totalPages} />
              )}
            </>
          ) : (
            <Result
              status="error"
              title="Hubo un error al cargar los chequeos"
              subTitle="Por favor vuelve a intentarlo más tarde."
              extra={[
                <Button
                  type="primary"
                  key="back"
                  onClick={() => navigate(globalPaths.basePath)}
                >
                  Regresar
                </Button>,
                <Button key="reload" onClick={() => window.location.reload()}>
                  Reintentar
                </Button>,
              ]}
            />
          )}
        </>
      )}
    </>
  );
};

export default ChecksPage;
