import { compareDesc } from 'date-fns';

export const isPastDate = (date: Date) => {
  const now = new Date();
  let isPastDate = false;
  const compareDates = compareDesc(now, date);
  if (compareDates === -1) {
    isPastDate = true;
  }
  return isPastDate;
};
