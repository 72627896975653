import { Descriptions, Tag, Row, Col, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import Contract, { ContractSchedule } from 'types/contract';
import { dataWeekDays } from 'constants/WeekFormat';
import { formatDate } from 'hooks/formatDate';
import { contractsPaths } from 'constants/routePaths';
import { ShowEmployeeData } from '../ShowEmployeeData';
import { BackButton } from 'components/common/BackButton/BackButton';

interface ContractDetailProps {
  contract: Contract;
  schedules: ContractSchedule[];
}

export const ContractDetail = ({
  contract,
  schedules,
}: ContractDetailProps) => {
  const navigate = useNavigate();
  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <h2 className="title">Detalle del contrato</h2>
        </Col>
        <Col>
          <Button
            type="primary"
            htmlType="submit"
            id="editContractBtn"
            onClick={() =>
              navigate(
                `${contractsPaths.basePath}/${contractsPaths.editContract}/${contract._id}`
              )
            }
          >
            Editar contrato
          </Button>
        </Col>
      </Row>

      <BackButton />
      <div id="detail-contract-container">
        <ShowEmployeeData userId={contract.userId ?? ''} />
        <Row>
          <Col xs={12}>
            <h3 className="sub-title mt-10 mb-5">Información del contrato</h3>
            <Descriptions title="" layout="horizontal" bordered>
              <Descriptions.Item label="Tipo" span={3}>
                {contract.contractType}
              </Descriptions.Item>
              <Descriptions.Item label="Consecutivo/clave" span={3}>
                {contract.contractCode}
              </Descriptions.Item>
              <Descriptions.Item label="Fecha de inicio" span={3}>
                {formatDate(contract.startDate)}
              </Descriptions.Item>
              <Descriptions.Item label="Fecha de fin" span={3}>
                {contract.expirationDate
                  ? formatDate(contract.expirationDate)
                  : 'Sin fecha de expiración definida'}
              </Descriptions.Item>
              {contract.hours && (
                <Descriptions.Item label="Número de horas" span={3}>
                  {contract.hours}
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Estado" span={3}>
                {!contract.status ? 'Caducado' : 'Activo'}
              </Descriptions.Item>
              {schedules.length > 0 && dataWeekDays ? (
                <>
                  {schedules.map(({ dayOfWeek, to, from }, i) => (
                    <Descriptions.Item
                      key={`${i}-${dayOfWeek}`}
                      label={dataWeekDays[dayOfWeek - 1].dayName}
                      span={3}
                    >
                      <>
                        Desde:
                        <Tag color="cyan-inverse">{from.split(':00Z')[0]}</Tag>
                        <br />
                        Hasta:{' '}
                        <Tag color="cyan-inverse">{to.split(':00Z')[0]}</Tag>
                      </>
                    </Descriptions.Item>
                  ))}
                </>
              ) : (
                <Descriptions.Item label="Sin horario." span={3}>
                  No hay dias para mostrar.
                </Descriptions.Item>
              )}
            </Descriptions>
          </Col>
        </Row>
        <div
          style={{
            width: '40%',
            marginLeft: '60%',
          }}
        ></div>
      </div>
    </>
  );
};
