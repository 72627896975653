export default {
  404: 'No se han podido encontrar los horarios del grupo',
  408: 'Existen horarios que se solapan con otros horarios en un contrato activo',
  409: 'Debe existir al menos un día asignado para poder crear el horario',
  411: 'No se han podido encontrar la carrera indicada',
  419: 'No se ha encontrado la materia especificada',
  423: 'No se ha podido encontrar la carrera indicada',
  424: 'No se ha podido encontrar el docente indicado',
  428: 'No se pueden realizar acciones en un horario ya publicado',
  431: 'No se ha podido encontrar el grupo para asignar horarios',
};
