import { ApiErrorData, httpApi } from 'api/http.api';
import {
  AddTagPayload,
  Device,
  RemoveTagPayload,
  ResponseQueueAWS,
  UserInDeviceRequest,
  UserInDeviceResponse,
} from 'types/devices';
import { CommandsType, ResponeCommandType } from '../types/commands';
import { AxiosResponse } from 'axios';
import { ResponseGeneral } from 'types';

const controller = `checker`;

export const getAll = (): Promise<AxiosResponse<Device[]>> =>
  httpApi.get<Device[]>(`${controller}`).then((res) => res);

export const addCommand = (
  payload: CommandsType
): Promise<AxiosResponse<ResponeCommandType>> =>
  httpApi
    .post<ResponeCommandType>(`${controller}/command/`, { ...payload })
    .then((res) => res);

export const bulkCommand = (
  payload: CommandsType[]
): Promise<AxiosResponse<ResponeCommandType>> =>
  httpApi
    .post<ResponeCommandType>(`${controller}/bulkCommand/`, { ...payload })
    .then((res) => res);

export const getCurrentAwS = (): Promise<AxiosResponse<ResponseQueueAWS[]>> =>
  httpApi
    .get<ResponseQueueAWS[]>(`${controller}/currentCommands/`)
    .then((res) => res);

export const getUserInDevice = (
  payload: UserInDeviceRequest
): Promise<AxiosResponse<UserInDeviceResponse>> =>
  httpApi
    .post<UserInDeviceResponse>(`${controller}/userindevice/`, { ...payload })
    .then((res) => res);

export const purgeAwsQ = (): Promise<AxiosResponse<ApiErrorData>> =>
  httpApi.get<ApiErrorData>(`${controller}/purgeQueue/`).then((res) => res);

export const deleteDevice = (
  serialNumber: string
): Promise<AxiosResponse<Device>> =>
  httpApi
    .delete<Device>(`${controller}/${serialNumber}`, {})
    .then((res) => res);

export const changeDeviceStatus = (
  id: string
): Promise<AxiosResponse<ResponseGeneral>> =>
  httpApi.put<ResponseGeneral>(`${controller}/${id}`, {}).then((res) => res);

export const addDeviceTag = (
  payload: AddTagPayload
): Promise<AxiosResponse<AddTagPayload>> =>
  httpApi
    .post<AddTagPayload>(`${controller}/addTag`, { ...payload })
    .then((res) => res);

export const removeDeviceTag = (
  payload: RemoveTagPayload
): Promise<AxiosResponse<RemoveTagPayload>> =>
  httpApi
    .post<RemoveTagPayload>(`${controller}/removeTag`, { ...payload })
    .then((res) => res);
