import {
  Card,
  Col,
  Row,
  Descriptions,
  Divider,
  Form,
  Button,
  Spin,
  Result,
} from 'antd';
import { getStartOfDate } from 'hooks/getStartEndOfDate';
import * as GlobalStyles from 'pages/Global.styles';
import { User } from 'types';
import { ReconcileIncidencesPayload } from 'types/incidences';
import { Dayjs } from 'dayjs';
import { reconcileIncidences } from 'api/incidence.api';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';

interface ReconcileIncidencesProps {
  employee: User;
}

export const ReconcileIncidences = ({ employee }: ReconcileIncidencesProps) => {
  const [reconcilingState, setReconcilingState] = useState<
    'initial' | 'success' | 'error' | 'proccessing'
  >('initial');
  const navigate = useNavigate();

  const onReconcileIncidences = async (values: { startDate: Dayjs }) => {
    try {
      setReconcilingState('proccessing');
      const newDate = getStartOfDate(values.startDate);
      const payload: ReconcileIncidencesPayload = {
        employee: employee._id,
        applicationDate: newDate,
      };
      await reconcileIncidences(payload);
      setReconcilingState('success');
    } catch (e) {
      setReconcilingState('error');
    }
  };

  const SuccessReconcilingResult = () => (
    <Card>
      <Result
        status="success"
        title="¡Éxito!"
        subTitle="Las incidencias se han conciliado exitosamente."
        extra={[
          <Button type="primary" key="back" onClick={() => navigate(-1)}>
            Aceptar
          </Button>,
        ]}
      />
    </Card>
  );

  const ErrorReconcilingResult = () => (
    <Card>
      <Result
        status="error"
        title="¡Error!"
        subTitle="No fue posible conciliar las incidencias. Por favor, inténtalo de nuevo más tarde."
        extra={[
          <Button type="primary" key="back" onClick={() => navigate(-1)}>
            Aceptar
          </Button>,
          <Button key="reload" onClick={() => window.location.reload()}>
            Reintentar
          </Button>,
        ]}
      />
    </Card>
  );

  return (
    <div id="reconcile-incidences">
      <Row>
        <Col xs={24}>
          <GlobalStyles.Title>Conciliar incidencias</GlobalStyles.Title>
        </Col>
      </Row>
      {reconcilingState === 'initial' || reconcilingState === 'proccessing' ? (
        <Row>
          <Col xs={24}>
            <Spin
              spinning={reconcilingState === 'proccessing' ? true : false}
              tip="Conciliando incidencias, por favor espere..."
              indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
            >
              <Card>
                <Divider>Datos para reconciliar incidencias</Divider>
                <Row>
                  <Col xs={24}>
                    <Descriptions layout="horizontal" bordered>
                      <Descriptions.Item
                        span={3}
                        label="Nombre del empleado"
                      >{`${employee.name} ${employee.lastName}`}</Descriptions.Item>
                      <Descriptions.Item span={3} label="No. de empleado">
                        {employee.idEmployee}
                      </Descriptions.Item>
                      <Descriptions.Item span={3} label="Correo electrónico">
                        {employee.email}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
                <Divider>Reconciliar incidencias</Divider>
                <Row style={{ marginBottom: '20px' }}>
                  <Col xs={24}>
                    <p>
                      Selecciona la fecha de inicio a partir de la cual se deben
                      conciliar las incidencias.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24}>
                    <Form onFinish={onReconcileIncidences}>
                      <Form.Item
                        name="startDate"
                        label="Fecha de inicio"
                        rules={[
                          {
                            type: 'date',
                            required: true,
                            message: 'Por favor, selecciona la fecha de incio',
                          },
                        ]}
                        hasFeedback
                      >
                        <GlobalStyles.LargeDatePicker />
                      </Form.Item>
                      <Row justify="end">
                        <Col>
                          <Button type="primary" htmlType="submit">
                            Conciliar incidencias
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </Card>
            </Spin>
          </Col>
        </Row>
      ) : (
        <>
          {reconcilingState === 'success' && <SuccessReconcilingResult />}
          {reconcilingState === 'error' && <ErrorReconcilingResult />}
        </>
      )}
    </div>
  );
};
