import {
  Button,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Spin,
  Switch,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RouteParam } from '../../../types/routeParam';
import User from '../../../types/user';
import Contract, { ContractKind } from '../../../types/contract';
import { useAppDispatch } from 'hooks/reduxHooks';
import { doCreateOne } from 'store/slices/contractSlice';
import { doList as contractDoList } from 'store/slices/contractSlice';
import { getActiveContractKinds } from 'api/contract.api';
import { getContractKindTerm } from 'hooks/getContractKindTerm';
import { usersPaths } from 'constants/routePaths';
import { ShowEmployeeData } from '../ShowEmployeeData';
import * as Sentry from '@sentry/react';
import notificationHook from 'hooks/notificationHook';
import statusMessages from 'statusMessages';
import { ApiError } from 'types';
import { BackButton } from 'components/common/BackButton/BackButton';

interface UserNewContract {
  user: User;
}

export const NewContract = ({ user }: UserNewContract) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<RouteParam>();
  const [contractType, setContractType] = useState('');
  const [contractStatus, setContractStatus] = useState(false);
  const formRef = React.useRef<FormInstance>(null);
  const [loading, setLoading] = useState(false);
  const [contractKinds, setContractKinds] = useState<ContractKind[]>();
  const [contractKindTerm, setContractKindTerm] = useState({
    term: '',
    workDayKind: '',
  });

  const onFinish = (values: Contract) => {
    values['userId'] = user._id;
    values['status'] = contractStatus;
    submit(values);
  };

  const getContractKindList = async () => {
    await getActiveContractKinds()
      .then((res) => {
        setContractKinds(res.data);
      })
      .catch((error) => {
        Sentry.captureException(error);
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          type: 'error',
          message: statusMessages.contract[
            currentStatus ? currentStatus : 500
          ] as string,
        });
      });
  };

  useEffect(() => {
    getContractKindList();
  }, []);

  useEffect(() => {
    if (contractKinds && contractType) {
      const contractKind = getContractKindTerm({
        contractKinds,
        kindId: contractType,
      });
      if (contractKind) {
        setContractKindTerm({
          term: contractKind?.term,
          workDayKind: contractKind?.workDayKind,
        });
      }
    }
  }, [contractKinds, contractType]);

  const onChangeContractType = (type: string) => {
    setContractType(type);
  };

  const submit = (values: Contract) => {
    setLoading(true);
    dispatch(doCreateOne(values))
      .unwrap()
      .then(() => {
        notificationHook({
          type: 'success',
          message: 'Contrato creado y asignado con éxito !',
        });
        setLoading(false);
        dispatch(contractDoList());
        navigate(
          `${usersPaths.basePath}/${usersPaths.userContracts}/${user._id}`
        );
      })
      .catch((error) => {
        Sentry.captureException(error);
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          type: 'error',
          message: statusMessages.contract[
            currentStatus ? currentStatus : 500
          ] as string,
        });
        setLoading(false);
      });
  };

  return (
    <div id="employee-contracts-container">
      <h2 className="title">Nuevo contrato</h2>
      <BackButton />
      <Spin spinning={loading}>
        <>
          {id && (
            <>
              <ShowEmployeeData userId={id ?? ''} />
              <Row justify="center" className="mt-5">
                <Col xs={10}>
                  <h2 className="sub-title my-5">Información del contrato</h2>
                  <Form
                    ref={formRef}
                    onFinish={onFinish}
                    autoComplete="off"
                    labelCol={{ span: 10 }}
                  >
                    <Form.Item
                      name="contractKind"
                      label="Selecciona el tipo de contrato"
                      rules={[
                        {
                          required: true,
                          message: 'Por favor selecciona el tipo de contrato',
                          type: 'string',
                        },
                      ]}
                      hasFeedback
                    >
                      <Select
                        placeholder="Seleccionar tipo de contrato"
                        id="contractKindId"
                        data-cy="contractKindId"
                        onChange={(e) => {
                          onChangeContractType(e);
                        }}
                      >
                        {contractKinds && (
                          <>
                            {contractKinds.map((contractKind: ContractKind) => (
                              <Select.Option
                                data-cy={`contractKind-${contractKind._id}`}
                                value={contractKind._id}
                                key={contractKind._id}
                              >
                                {contractKind.name}
                              </Select.Option>
                            ))}
                          </>
                        )}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="contractCode"
                      label="Consecutivo o clave"
                      rules={[
                        {
                          required: true,
                          message:
                            'Por favor ingresa el consecutivo o clave del contrato',
                          type: 'string',
                        },
                      ]}
                      hasFeedback
                    >
                      <Input
                        placeholder="Ingresa el consecutivo o clave"
                        id="contractCode"
                        data-cy="contractCode"
                      />
                    </Form.Item>
                    {contractType && contractType != '' && contractKinds && (
                      <>
                        <Form.Item
                          name="startDate"
                          label="Fecha de inicio"
                          rules={[
                            {
                              required: true,
                              message: 'Por favor ingresa la fecha de inicio',
                              type: 'date',
                            },
                          ]}
                          hasFeedback
                        >
                          <DatePicker id="startDate" data-cy="startDate" />
                        </Form.Item>
                        {contractKindTerm.term === 'temporal' && (
                          <Form.Item
                            name="expirationDate"
                            label="Fecha de fin"
                            rules={[
                              {
                                required: true,
                                message: 'Por favor ingresa la fecha de fin',
                                type: 'date',
                              },
                            ]}
                            hasFeedback
                          >
                            <DatePicker id="endDate" data-cy="endDate" />
                          </Form.Item>
                        )}
                        <Form.Item
                          name="status"
                          label="Estatus del contrato"
                          valuePropName="checked"
                        >
                          <Row align="middle" gutter={10}>
                            <Col>
                              <Switch
                                onChange={(e: boolean) => {
                                  setContractStatus(e);
                                }}
                                defaultChecked={contractStatus}
                                id="status"
                                data-cy="status"
                              />
                            </Col>
                            <Col>
                              {contractStatus && contractStatus == true ? (
                                <span className="active-text">Activo</span>
                              ) : (
                                <span className="inactive-text">Inactivo</span>
                              )}
                            </Col>
                          </Row>
                        </Form.Item>
                      </>
                    )}
                    <Form.Item>
                      <Row justify="end" gutter={10}>
                        <Col>
                          <Button
                            onClick={() =>
                              navigate(
                                `${usersPaths.basePath}/${usersPaths.userContracts}/${id}`
                              )
                            }
                            id="cancelBtn"
                            data-cy="cancelBtn"
                          >
                            Cancelar
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            htmlType="submit"
                            id="saveBtn"
                            type="primary"
                            data-cy="saveBtn"
                          >
                            Guardar
                          </Button>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </>
          )}
        </>
      </Spin>
    </div>
  );
};
