import { Col, Popover, Row, Table, Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

type ColumnPopoverProps = {
  title: string;
  description: string;
};

export const UploadContractsInfo = () => {
  const ColumnPopover = ({ title, description }: ColumnPopoverProps) => (
    <div style={{ cursor: 'pointer' }}>
      <Popover title={<p>{description}</p>}>
        {title} <QuestionCircleOutlined style={{ fontSize: '14px' }} />
      </Popover>
    </div>
  );

  const data = [
    {
      key: '1',
      employeeId: '00000',
      dayOfWeek: '1',
      inHour: '0900',
      outHour: '1700',
      contract: 'C2024058',
      contractType: 'Temporal',
    },
  ];

  const columns = [
    {
      title: (
        <ColumnPopover title="No. Empleado" description="Número de empleado" />
      ),
      dataIndex: 'employeeId',
      key: 'employeeId',
    },
    {
      title: (
        <ColumnPopover
          title="Día de la semana"
          description="Lunes= 1 |
      Viernes = 5"
        />
      ),
      dataIndex: 'dayOfWeek',
      key: 'dayOfWeek',
    },
    {
      title: (
        <ColumnPopover
          title="Entrada"
          description="Formato militar | Ej.
        9am = 0900"
        />
      ),
      dataIndex: 'inHour',
      key: 'inHour',
    },
    {
      title: (
        <ColumnPopover
          title="Salida"
          description="Formato militar | Ej.
        5pm = 1700"
        />
      ),
      dataIndex: 'outHour',
      key: 'outHour',
    },
    {
      title: (
        <ColumnPopover
          title="Contrato"
          description="Número consecutivo o clave:
         Ejemplo: C2024058
         * Puede estar vacio"
        />
      ),
      dataIndex: 'contract',
      key: 'contract',
    },
  ];

  const downloadTemplate = () => {
    const link = document.createElement('a');
    const docName = 'prosinergia-plantilla-subir-contratos.xlsx';
    link.href = `/${docName}`;
    link.setAttribute('download', `${docName}`);
    document.body.appendChild(link);
    link.click();
  };
  return (
    <div id="card-example-contracts">
      <Row justify="space-between" align="middle">
        <Col>
          <h3>Formato</h3>
        </Col>
        <Col>
          <Button onClick={downloadTemplate} type="primary">
            Descargar Plantilla
          </Button>
        </Col>
      </Row>

      <p>Recuerda que el excel debe de tener este formato </p>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        className="table-ps"
        bordered
        tableLayout="fixed"
        size="small"
      />
    </div>
  );
};
