import { PageTitle } from 'components/common/PageTitle/PageTitle';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Result, Row, Skeleton } from 'antd';
import Contract, { ContractSchedule } from 'types/contract';
import { ContractDetail } from 'components/employees/Contracts/ContractDetail';
import { getOne } from 'api/contract.api';
import { getContractSchedules } from 'api/schedule.api';

const ContractDetailPage = () => {
  const params = useParams();
  const [contract, setContract] = useState<Contract | null>();
  const [contractSchedules, setContractSchedules] = useState<
    ContractSchedule[] | null
  >();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const contractId = params.id;

  const getContractDetail = useCallback(() => {
    if (contractId) {
      setLoading(true);
      getOne(contractId)
        .then((res) => {
          setContract(res.data);
          setLoading(false);
        })
        .catch(() => {
          setContract(null);
          setLoading(false);
        });
    }
  }, [contractId]);

  const getContractSchedulesList = useCallback(() => {
    if (contractId) {
      getContractSchedules(contractId)
        .then((res) => {
          setContractSchedules(res.data);
          setLoading(false);
        })
        .catch(() => {
          setContractSchedules(null);
          setLoading(false);
        });
    }
  }, [contractId]);

  useEffect(() => {
    getContractDetail();
    getContractSchedulesList();
  }, [getContractDetail, getContractSchedulesList]);

  return (
    <>
      <PageTitle>{`Contrato - Detalle`}</PageTitle>
      {loading && (
        <Row>
          <Col xs={24}>
            <Skeleton active />
          </Col>
        </Row>
      )}
      {!loading && contract && contractSchedules && (
        <ContractDetail contract={contract} schedules={contractSchedules} />
      )}
      {!loading && !contract && !contractSchedules && (
        <Result
          status="error"
          title="Hubo un error al cargar el contrato del empleado"
          subTitle="Por favor vuelve a intentarlo más tarde."
          extra={[
            <Button type="primary" key="back" onClick={() => navigate(-1)}>
              Regresar
            </Button>,
            <Button key="reload" onClick={() => window.location.reload()}>
              Reintentar
            </Button>,
          ]}
        />
      )}
    </>
  );
};

export default ContractDetailPage;
