import { CountIncidencesByCategoryProps } from 'interfaces/kardex';
import { EmployeeIncidences } from 'types/incidences';

export const countIncidencesByCategory = ({
  categoryAbbr,
  incidences,
}: CountIncidencesByCategoryProps) => {
  try {
    const requestedCategoryIncidences = incidences.filter(
      (incidence: EmployeeIncidences) => {
        return incidence.category.abbr === categoryAbbr;
      }
    );
    return requestedCategoryIncidences.length;
  } catch (error) {
    throw new Error(
      `No fue posible contar las incidencias de la categoría: ${categoryAbbr}`
    );
  }
};
