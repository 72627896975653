import { Button, Col, Popover, Row, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { incidencePaths } from 'constants/routePaths';
import { ColumnsType, TableProps } from 'antd/es/table';
import { DeleteFilled } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { IncidenceCategory } from 'types/incidences';
import { getCategories, removeCategory } from 'api/incidence.api';
import * as Sentry from '@sentry/react';
import { IncidenceCategoryPreview } from './IncidenceCategoryPreview';
import notificationHook from 'hooks/notificationHook';
import statusMessages from 'statusMessages';
import { ApiError } from 'types';
import { TableEditButton } from 'components/TableEditButton/TableEditButton';

type IncidencesCatalogueProps = {
  categories: IncidenceCategory[];
};

const IncidenceCategories = ({ categories }: IncidencesCatalogueProps) => {
  const navigate = useNavigate();
  const [incidenceReasonsData, setIncidenceReasonsData] = useState<
    IncidenceCategory[]
  >([]);
  const tableProps: TableProps<IncidenceCategory> = {
    bordered: true,
    pagination: { position: ['bottomCenter'] },
    tableLayout: 'fixed',
    size: 'small',
    className: 'table-ps',
  };

  useEffect(() => {
    setIncidenceReasonsData(categories);
  }, [categories]);

  const UpdateIncidentReasonsList = () => {
    getCategories().then((data) => setIncidenceReasonsData(data.data));
  };

  const deleteIncidentReason = async (id: string) => {
    try {
      await removeCategory(id);
      UpdateIncidentReasonsList();
      notificationHook({
        type: 'success',
        message: 'Se ha eliminado la categoria de incidencia con éxito',
      });
    } catch (error: unknown) {
      Sentry.captureException(error);
      const currentStatus = (error as ApiError).statusCode;
      notificationHook({
        message: statusMessages.incidence[
          currentStatus ? currentStatus : 500
        ] as string,
        type: 'error',
      });
    }
  };

  const columns: ColumnsType<IncidenceCategory> = [
    {
      title: 'Incidencia',
      key: 'abbr',
      dataIndex: 'abbr',
    },
    {
      title: 'Descripción',
      key: 'desc',
      dataIndex: 'desc',
    },
    {
      title: 'Color',
      key: 'color',
      dataIndex: 'color',
      render: (_, record) => (
        <Row justify="center">
          <Col>
            <IncidenceCategoryPreview
              text={record.abbr}
              bgColor={record.color}
              txtColor={record.txtColor}
            />
          </Col>
        </Row>
      ),
    },
    {
      title: 'Opciones',
      key: 'options',
      render: (_, record) => (
        <div className="option-buttons">
          <TableEditButton
            id={record._id}
            onClick={() => {
              navigate(`${incidencePaths.catalogueEdit}/${record._id}`);
            }}
          />
          <div title="Eliminar">
            <Popover
              content={
                record.protected ? (
                  <p>No es posible eliminar este tipo de incidencia</p>
                ) : (
                  <Button
                    disabled={record.protected}
                    danger
                    type="primary"
                    onClick={() => {
                      deleteIncidentReason(record._id);
                    }}
                  >
                    Confirmar
                  </Button>
                )
              }
              trigger="click"
              placement="left"
            >
              <Button id={record._id} className="error-txt">
                <DeleteFilled />
              </Button>
            </Popover>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <h2 className="title">Catálogo de tipos de incidencias</h2>
        </Col>
        <Col>
          <Button
            id="btnNewIncident"
            type="primary"
            onClick={() => {
              navigate(
                `${incidencePaths.basePath}/${incidencePaths.catalogue}/${incidencePaths.catalogueNew}`
              );
            }}
          >
            Crear tipo
          </Button>
        </Col>
      </Row>

      <Table
        {...tableProps}
        columns={columns}
        dataSource={incidenceReasonsData}
        rowKey={'_id'}
      />
    </>
  );
};

export default IncidenceCategories;
