import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Table,
  TableProps,
  Tag,
  Switch,
  Checkbox,
  Space,
  Badge,
} from 'antd';
import * as S from '../../pages/Global.styles';
import {
  CheckerConfigsType,
  CheckerPropertiesType,
  DeviceTag,
  Device,
  RemoveTagPayload,
} from 'types/devices';
import { dateForDisplay } from 'libs/dateFormaterPetition';
import type { ColumnsType } from 'antd/es/table';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import RegisteredCheckerData from './RegisteredCheckerData';
import CommandsList from './DeviceCommands/CommandsList';
import { useState } from 'react';
import { ModalUserContent } from './DeviceCommands/ModalUserContent';
import { Link } from 'react-router-dom';
import { AddLabel } from './AddTag';
import { removeDeviceTag, changeDeviceStatus } from 'api/devices.api';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { doList } from 'store/slices/devicesSlice';
import * as Sentry from '@sentry/react';
import { getPassedMinutesFromDate } from 'hooks/getPassedMinutesFromDate';
import { maxDeviceIdleMinutes } from 'constants/global';
import 'styles/sass/devices.scss';
import { LastActivityData } from './LastActivityData';
import openNotification from 'hooks/notificationHook';
import type { CheckboxProps } from 'antd';
import notificationHook from 'hooks/notificationHook';
import statusMessages from 'statusMessages';
import { ApiError } from 'types';
import { TableEditButton } from 'components/TableEditButton/TableEditButton';

export const DevicesList = () => {
  const [openModal, setOpenModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteTagModal, setShowDeleteTagModal] = useState(false);
  const [titleModal, setTitleModal] = useState<Device | undefined>();
  const [selectedDevice, setSelectedDevice] = useState<Device>();
  const [tagToDelete, setTagToDelete] = useState<DeviceTag>();
  const [removingTag, setRemovingTag] = useState(false);

  const [showInactiveDevices, setShowInactiveDevices] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const dispatch = useAppDispatch();

  const { devices } = useAppSelector((state) => state.device);

  const showModal = (record: Device) => {
    if (record) {
      setTitleModal(record);
      setOpenModal(true);
    }
  };

  const handleCancel = () => {
    setOpenModal(false);
  };

  const editDevice = (device: Device) => {
    setSwitchChecked(device.status);
    setSelectedDevice(device);
    setShowEditModal(true);
  };

  const cancelEditDevice = () => {
    setSelectedDevice(undefined);
    setShowEditModal(false);
  };

  const tableProps: TableProps<Device> = {
    bordered: true,
    pagination: { position: ['bottomCenter'] },
    tableLayout: 'fixed',
    size: 'small',
    sticky: true,
    className: 'table-ps',
  };
  const columns: ColumnsType<Device> = [
    {
      title: 'Número de serie',
      key: 'noSerie',
      align: 'center',
      render: (record: Device) => (
        <>
          <div>
            {record.configs && (
              <Link to={`/dispositivos/${record.configs.SerialNumber}`}>
                {record.configs.SerialNumber ?? ''}
              </Link>
            )}
          </div>
          {record.tags &&
            record.tags.map((tag: DeviceTag) => (
              <Tag className="tag" key={tag._id} color={tag.tagColor}>
                {tag.tagName}
              </Tag>
            ))}
        </>
      ),
    },
    {
      title: 'Estatus',
      align: 'center',
      responsive: ['lg', 'xl', 'xxl'],
      render: (record: Device) => (
        <div>
          {record.status == true ? (
            <Space>
              <Badge status="success" />
              Activo
            </Space>
          ) : (
            <Space>
              <Badge status="error" />
              Inactivo
            </Space>
          )}
        </div>
      ),
    },
    {
      title: 'Modelo',
      key: 'deviceName',
      align: 'center',
      dataIndex: 'configs',
      render: (configs: CheckerConfigsType) => <>{configs.DeviceName ?? ''}</>,
      sorter: (a, b) =>
        Number(a.configs.DeviceName) - Number(b.configs.DeviceName),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'IP de Dispositivo',
      key: 'ipDevice',
      align: 'center',
      dataIndex: 'properties',
      responsive: ['lg', 'xl', 'xxl'],
      render: (properties: CheckerPropertiesType) => (
        <>{properties.IPAddress ?? ''}</>
      ),
    },
    {
      title: 'Online',
      key: 'online',
      align: 'center',
      width: 90,
      responsive: ['lg', 'xl', 'xxl'],
      render: (record: Device) => (
        <>
          {getPassedMinutesFromDate(record.updatedAt) > maxDeviceIdleMinutes ? (
            <LastActivityData
              date={record.updatedAt}
              children={
                <CloseCircleOutlined className="status-icon offline-status-icon" />
              }
            />
          ) : (
            <CheckCircleOutlined className="status-icon online-status-icon" />
          )}
        </>
      ),
    },
    {
      title: 'Última actividad',
      key: 'date',
      dataIndex: 'updatedAt',
      render: (date) => dateForDisplay(date),
      sorter: (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      sortDirections: ['descend', 'ascend'],
      responsive: ['lg', 'xl', 'xxl'],
    },
    {
      title: 'Registros',
      key: 'date',
      width: 300,
      render: (data, record) => (
        <RegisteredCheckerData serialNumber={record.serialNumber} />
      ),
    },
    {
      title: 'Acciones',
      key: 'actions',
      align: 'center',
      width: 150,
      render: (a, record) => (
        <div className="option-buttons">
          <span title="Modificar Usuarios">
            <Button
              icon={<UserAddOutlined />}
              onClick={() => {
                showModal(record);
              }}
            />
          </span>
          <span title="Editar">
            <TableEditButton
              onClick={() => {
                editDevice(record);
              }}
            />
          </span>
        </div>
      ),
    },
  ];

  const deleteTag = async (tagId: string) => {
    setRemovingTag(true);
    if (!selectedDevice) {
      notificationHook({
        type: 'error',
        message:
          'Error al eliminar la etiqueta. No se pudo obtener la información del dipositivo.',
      });
      Sentry.captureMessage(`Error al eliminar la etiqueta, tagId: ${tagId}`);
      return;
    }
    const payload: RemoveTagPayload = {
      deviceId: selectedDevice._id,
      tagId,
    };
    await removeDeviceTag(payload)
      .then(() => {
        setRemovingTag(false);
        dispatch(doList());
        notificationHook({
          type: 'success',
          message: 'Etiqueta eliminada con éxito.',
        });
      })
      .catch((error) => {
        setRemovingTag(false);
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          type: 'error',
          message: statusMessages.devices[
            currentStatus ? currentStatus : 500
          ] as string,
        });
        Sentry.captureException(error);
      });
  };

  const handleChangeDeviceStatus = async (
    deviceId: string,
    checked: boolean
  ) => {
    openNotification({
      type: 'warning',
      placement: 'top',
      message: 'Cambiar estatus',
      description: `¿Confirmas que deseas ${selectedDevice?.status ? 'Desactivar' : 'Activar'} el dispositivo?`,
      duration: null,
      showConfirmBtn: true,
      onConfirm: async () => {
        await changeDeviceStatus(deviceId);
        setSwitchChecked(checked);
      },
      onClose: () => {
        setSwitchChecked(!checked);
      },
      confirmBtnLabel: `${selectedDevice?.status ? 'Desactivar' : 'Activar'}`,
    });
  };

  const handleShowInactiveDevices: CheckboxProps['onChange'] = (e) => {
    setShowInactiveDevices(e.target.checked);
  };

  const handleSwitchChange = (checked: boolean) => {
    if (selectedDevice) {
      handleChangeDeviceStatus(selectedDevice._id, checked);
    }
  };

  return (
    <>
      <h2 className="title mb-5">Registro de Dispositivos</h2>
      <Row>
        <Col xs={24}>
          <CommandsList />
          <Row justify="end" gutter={16} className="mb-4 mt-8">
            <Col>
              <Checkbox onChange={handleShowInactiveDevices}>
                Mostrar Inactivos
              </Checkbox>
            </Col>
          </Row>
          <Table
            {...tableProps}
            dataSource={devices.filter((d) => showInactiveDevices || d.status)}
            columns={columns}
            id="devicesTable"
            rowKey={'_id'}
          />
        </Col>
      </Row>
      <Modal
        title="Administrar usuarios"
        open={openModal}
        destroyOnClose
        width="90vw"
        okButtonProps={{ disabled: true, style: { display: 'none' } }}
        onCancel={handleCancel}
      >
        <ModalUserContent deviceInfo={titleModal} closeModal={handleCancel} />
      </Modal>
      <Modal
        title="Editar dispositivo"
        open={showEditModal}
        destroyOnClose
        onCancel={cancelEditDevice}
        width="50vw"
        footer={null}
      >
        {selectedDevice ? (
          <Form labelCol={{ span: 5 }}>
            <Form.Item label="Número de serie">
              <Input disabled value={selectedDevice.serialNumber} />
            </Form.Item>
            <Form.Item label="Nombre del dispositivo">
              <Input disabled value={selectedDevice.configs.DeviceName} />
            </Form.Item>
            <Form.Item label="IP del dispositivo">
              <Input disabled value={selectedDevice.properties.IPAddress} />
            </Form.Item>
            <Form.Item label="Etiquetas">
              {selectedDevice.tags && selectedDevice.tags.length > 0 ? (
                <>
                  {selectedDevice.tags.map((tag: DeviceTag) => (
                    <Tag
                      key={tag._id}
                      color={tag.tagColor}
                      closable
                      onClose={(e) => {
                        e.preventDefault();
                        setTagToDelete(tag);
                        setShowDeleteTagModal(true);
                      }}
                    >
                      {tag.tagName}
                    </Tag>
                  ))}
                </>
              ) : (
                <S.LightGreyText>Sin etiquetas</S.LightGreyText>
              )}
            </Form.Item>
            <Form.Item label="Activar dispositivo">
              <Switch
                onChange={handleSwitchChange}
                checked={switchChecked}
                id="status"
                data-cy="status-switch"
                disabled={!selectedDevice}
              />
            </Form.Item>

            <AddLabel deviceId={selectedDevice._id} />
          </Form>
        ) : (
          <h5>No fue posible obtener la información del dispositivo</h5>
        )}
      </Modal>
      <Modal
        title="¿Confirmas que deseas remover la etiqueta?"
        open={showDeleteTagModal}
        okText="Confirmar"
        cancelText="Cancelar"
        confirmLoading={removingTag}
        onOk={() => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          deleteTag(tagToDelete!._id);
        }}
        onCancel={() => {
          setShowDeleteTagModal(false);
        }}
      >
        <Row>
          <Col xs={24}>
            {tagToDelete && selectedDevice && (
              <p>
                Al confirmar, se eliminará la etiqueta{' '}
                <Tag color={tagToDelete.tagColor}>{tagToDelete.tagName}</Tag>
                del dispositivo <b>{selectedDevice.configs.SerialNumber}</b>
              </p>
            )}
          </Col>
        </Row>
      </Modal>
    </>
  );
};
