import { Col, Row } from 'antd';
import 'styles/sass/incidences.scss';

interface IncidenceCategoryPreviewProps {
  text: string;
  bgColor: string;
  txtColor: string;
}

export const IncidenceCategoryPreview = ({
  text,
  bgColor,
  txtColor,
}: IncidenceCategoryPreviewProps) => {
  return (
    <div id="incidence-category-preview">
      <Row
        align="middle"
        justify="center"
        className="container"
        style={{ backgroundColor: bgColor }}
      >
        <Col xs={24}>
          <p style={{ color: txtColor }}>{text}</p>
        </Col>
      </Row>
    </div>
  );
};
