import { avatarColors } from 'constants/global';

export interface Color {
  backgroundColor: string;
}
export const randomAvatarColor = () => {
  const randomColorIndex = Math.floor(Math.random() * avatarColors.length);
  const style = {
    backgroundColor: avatarColors[randomColorIndex],
  };
  return style;
};
