import { Button, Col, Form, Input, Row, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { incidencePaths } from 'constants/routePaths';
import { useEffect, useState } from 'react';
import { onInputHandlerToUpperCase } from 'libs/toUpperCase';
import { IncidenceCategory } from 'types/incidences';
import { updateCategory } from 'api/incidence.api';
import * as Sentry from '@sentry/react';
import { HorizontalColorPicker } from 'components/common/Color/HorizontalColorPicker';
import { IncidenceCategoryPreview } from './IncidenceCategoryPreview';
import notificationHook from 'hooks/notificationHook';
import statusMessages from 'statusMessages';
import { ApiError } from 'types';
import { BackButton } from 'components/common/BackButton/BackButton';

type IncidenceCategoryEditProps = {
  category: IncidenceCategory;
};

const IncidenceCategoryEditForm = ({
  category,
}: IncidenceCategoryEditProps) => {
  const navigate = useNavigate();
  const requiredRule = { required: true, message: 'Este campo es obligatorio' };
  const [loading, setLoading] = useState(false);
  const [abbreviation, setAbbreviation] = useState(category.abbr);
  const [backgroundColor, setBackgroundColor] = useState(category.color);
  const [textColor, setTextColor] = useState(category.txtColor);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(category);
  }, [category, form]);

  const onFinish = (values: IncidenceCategory) => {
    values.color = backgroundColor;
    values.txtColor = textColor;
    setLoading(true);
    updateCategory(values, category._id)
      .then(() => {
        notificationHook({
          type: 'success',
          message: 'Categoria de incidencia editado con éxito !',
        });
        setLoading(false);
        navigate(`${incidencePaths.basePath}/${incidencePaths.catalogue}`);
      })
      .catch((error: unknown) => {
        Sentry.captureException(error);
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          message: statusMessages.incidence[
            currentStatus ? currentStatus : 500
          ] as string,
          type: 'error',
        });
        setLoading(false);
      });
  };

  const onChangeColor = (hex: string, field: 'bg' | 'txt') => {
    if (field === 'bg') {
      setBackgroundColor(hex);
    } else {
      setTextColor(hex);
    }
  };

  const onAbbreviationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAbbreviation(e.target.value);
  };

  return (
    <>
      <h2 className="title">Editar categoría de incidencia</h2>
      <BackButton />
      <Row justify="center" className="mt-5">
        <Col xs={10}>
          <Spin spinning={loading} tip="Editando categoria...">
            <Form
              form={form}
              onFinish={onFinish}
              autoComplete="off"
              labelCol={{ span: 6 }}
            >
              <Form.Item name="abbr" label="Incidencia" rules={[requiredRule]}>
                <Input
                  type="text"
                  disabled={category.protected}
                  onInput={onInputHandlerToUpperCase}
                  onChange={onAbbreviationChange}
                />
              </Form.Item>
              <Form.Item name="desc" label="Descripción" rules={[requiredRule]}>
                <Input
                  type="text"
                  disabled={category.protected}
                  onInput={onInputHandlerToUpperCase}
                />
              </Form.Item>
              <Row align="middle" justify="start">
                <Col xs={18} className="ml-10">
                  <Form.Item
                    name="color"
                    label="Color de fondo"
                    rules={[requiredRule]}
                    className="mb-0 py-2"
                  >
                    <HorizontalColorPicker
                      onChange={(_, hex: string) => {
                        onChangeColor(hex, 'bg');
                      }}
                      defaultValue={backgroundColor ?? '#FFFFFF'}
                    />
                  </Form.Item>
                  <Form.Item
                    name="txtColor"
                    label="Color de texto"
                    rules={[requiredRule]}
                    className="mb-0 py-2"
                  >
                    <HorizontalColorPicker
                      onChange={(_, hex: string) => {
                        onChangeColor(hex, 'txt');
                      }}
                      defaultValue={textColor ?? '#FFFFFF'}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <IncidenceCategoryPreview
                    text={abbreviation}
                    bgColor={backgroundColor}
                    txtColor={textColor}
                  />
                </Col>
              </Row>
              <Row justify="end" gutter={10} className="mt-10">
                <Col>
                  <Form.Item>
                    <Button
                      onClick={() =>
                        navigate(
                          `${incidencePaths.basePath}/${incidencePaths.catalogue}`
                        )
                      }
                      id="cancelBtn"
                    >
                      Cancelar
                    </Button>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <Button htmlType="submit" type="primary">
                      Guardar
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Col>
      </Row>
    </>
  );
};

export default IncidenceCategoryEditForm;
