import { UserAddOutlined } from '@ant-design/icons';
import { Button, Descriptions, Modal, Space, Table } from 'antd';
import { CardComponent } from 'components/card/CardComponent';
import { useState } from 'react';
import { CheckerEmployee, Device } from 'types/devices';
import { ModalUserContent } from './DeviceCommands/ModalUserContent';
import { addCommand } from 'api/devices.api';
import { ModalGetBiometricContent } from './DeviceCommands/ModalGetBiometricContent';

interface DetailProps {
  device: Device;
}

//DATA QUERY tablename=user,fielddesc=*,filter =*

export const DeviceDetail = ({ device }: DetailProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalBio, setOpenModalBio] = useState(false);

  const showModal = () => {
    setOpenModal(true);
  };
  const showModalBio = () => {
    setOpenModalBio(true);
  };

  const handleCancel = () => {
    setOpenModal(false);
    setOpenModalBio(false);
  };

  const queryDataHandler = async () => {
    await addCommand({
      serialNumber: device.serialNumber,
      command: 'queryData',
      tableName: 'user',
    });
  };

  const queryLogHandler = async () => {
    await addCommand({
      serialNumber: device.serialNumber,
      command: 'queryData',
      tableName: 'transaction',
    });
  };

  const queryGetFinger = async (pin: number) => {
    await addCommand({
      serialNumber: device.serialNumber,
      command: 'getFinger',
      employeeId: pin,
    });
  };

  const queryRebootHandeler = async () => {
    await addCommand({
      serialNumber: device.serialNumber,
      command: 'reboot',
    });
  };

  const columns = [
    {
      title: 'PIN',
      dataIndex: 'PIN',
      key: 'pin',
    },
    {
      title: 'Nombre',
      dataIndex: 'Name',
      key: 'name',
    },
    {
      title: 'Opciones',
      dataIndex: 'options',
      key: 'options',
      render: (_text: string, e: CheckerEmployee) => (
        <>
          <Button onClick={() => queryGetFinger(e.PIN)}>
            Obtener Biometricos
          </Button>
        </>
      ),
    },
  ];

  return (
    <div id="device-detail-container">
      <CardComponent
        title="Detalle del dispositivo"
        content={
          <>
            <Descriptions title="" layout="horizontal" bordered>
              <Descriptions.Item label="Número de Serie" span={3}>
                {device.serialNumber}
              </Descriptions.Item>
              <Descriptions.Item label="Nombre del dispositivo" span={3}>
                {device.configs.DeviceName}
              </Descriptions.Item>
              <Descriptions.Item label="Firmware Ver." span={3}>
                {device.properties.FWVersion}
              </Descriptions.Item>
              <Descriptions.Item label="IP" span={3}>
                {device.properties.IPAddress}
              </Descriptions.Item>
              <Descriptions.Item label="Push Ver." span={3}>
                {device.properties.PushVersion}
              </Descriptions.Item>
            </Descriptions>
            <Space style={{ marginRight: 6 }}>
              <span title="Actualizar empleados">
                <Button
                  title="Actualizar empleados"
                  icon={
                    <UserAddOutlined
                      style={{ color: 'darkgreen' }}
                      onClick={() => {
                        showModal();
                      }}
                    />
                  }
                />
              </span>
            </Space>
            <Space style={{ marginRight: 6 }}>
              <Button
                title="descarga biometricos"
                onClick={() => showModalBio()}
              >
                Descargar biometricos
              </Button>
            </Space>
            <Space style={{ marginRight: 6 }}>
              <Button title="reinicia" onClick={() => queryRebootHandeler()}>
                Reiniciar
              </Button>
            </Space>
            <Space style={{ marginRight: 6 }}>
              <span>
                <Button onClick={() => queryDataHandler()}>
                  Descargar Empleados
                </Button>
              </span>
            </Space>
            {/* <Space style={{ marginRight: 6 }}>
              <span><Button onClick={() => queryBiometricHandler()}>Descargar Biometricos</Button></span>
            </Space> */}
            <Space style={{ marginRight: 6 }}>
              <span>
                <Button onClick={() => queryLogHandler()}>
                  Descargar Logs
                </Button>
              </span>
            </Space>
            <Table dataSource={device.usersRegistered} columns={columns} />
          </>
        }
      />
      <Modal
        title="Actualizar empleados"
        open={openModal}
        destroyOnClose
        width="90vw"
        okButtonProps={{ disabled: true, style: { display: 'none' } }}
        onCancel={handleCancel}
      >
        <ModalUserContent deviceInfo={device} closeModal={handleCancel} />
      </Modal>
      <Modal
        title="Obtener biometricos del dispositivo"
        open={openModalBio}
        destroyOnClose
        width="90vw"
        okButtonProps={{ disabled: true, style: { display: 'none' } }}
        onCancel={handleCancel}
      >
        <ModalGetBiometricContent
          deviceInfo={device}
          closeModal={handleCancel}
        />
      </Modal>
    </div>
  );
};
