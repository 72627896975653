import { Col, Row } from 'antd';
import { ErrorResult } from 'components/common/ErrorResult';
import { LoadingCard } from 'components/common/LoadingCard';
import { PageTitle } from 'components/common/PageTitle/PageTitle';
import { ActiveContracts } from 'components/dashboard/ActiveContracts';
import { Devices } from 'components/dashboard/Devices';
import { Employees } from 'components/dashboard/employees/Employees';
import { SliceStatus } from 'constants/enums/slices';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useCallback, useEffect } from 'react';
import {
  doGetDashboardContractKindsCount,
  doGetDashboardDevicesList,
  doGetDashboardEmployeesList,
} from 'store/slices/dashboardSlice';
import 'styles/sass/dashboard.scss';
import * as Sentry from '@sentry/react';
import notificationHook from 'hooks/notificationHook';
import statusMessages from 'statusMessages';
import { ApiError } from 'types';

const DashboardPage = () => {
  const dispatch = useAppDispatch();
  const {
    employeesList,
    employeesListStatus,
    contractKindsCount,
    contractKindsCountSliceStatus,
    devicesList,
    devicesListSliceStatus,
  } = useAppSelector((state) => state.dashboard);

  const loadAllDashboardInfo = useCallback(async () => {
    dispatch(doGetDashboardContractKindsCount())
      .unwrap()
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        Sentry.captureException(error);
        notificationHook({
          type: 'error',
          message: statusMessages.contract[
            currentStatus ? currentStatus : 500
          ] as string,
        });
      });

    dispatch(doGetDashboardDevicesList())
      .unwrap()
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        Sentry.captureException(error);
        notificationHook({
          type: 'error',
          message: statusMessages.devices[
            currentStatus ? currentStatus : 500
          ] as string,
        });
      });

    dispatch(doGetDashboardEmployeesList())
      .unwrap()
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        Sentry.captureException(error);
        notificationHook({
          type: 'error',
          message: statusMessages.user[
            currentStatus ? currentStatus : 500
          ] as string,
        });
      });
  }, [dispatch]);

  useEffect(() => {
    loadAllDashboardInfo();
  }, [loadAllDashboardInfo]);

  const isUpdating = [
    employeesListStatus,
    contractKindsCountSliceStatus,
    devicesListSliceStatus,
  ].includes(SliceStatus.Updating);

  return (
    <>
      <PageTitle>{`Dashboard`}</PageTitle>
      <h2 className="title">Dashboard</h2>
      <Row className="mt-8">
        <Col xl={24} xxl={18}>
          {isUpdating ? (
            <LoadingCard />
          ) : (
            <>
              {contractKindsCount && (
                <ActiveContracts contractKinds={contractKindsCount} />
              )}
              <Row className="mt-5" gutter={16}>
                <Col xl={12}>
                  {employeesList && (
                    <Employees todaysScheduleByEmployee={employeesList} />
                  )}
                </Col>
                <Col xl={12}>
                  {devicesList && <Devices devices={devicesList} />}
                </Col>
              </Row>
              {!contractKindsCount ||
                (!devicesList && (
                  <ErrorResult title="No fue posible cargar el dashboard" />
                ))}
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default DashboardPage;
