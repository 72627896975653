export const maxDeviceIdleMinutes = 30;

export const avatarColors = [
  '#7a69f6',
  '#45e16a',
  '#ff9591',
  '#fdb0ae',
  '#faad14',
  '#389e0d',
  '#b7eb8f',
];

export const pageSize = 10;

export const defaultPaginationOptions = {
  page: 1,
  limit: 10,
};

export const months = [
  { value: 1, label: 'Enero' },
  { value: 2, label: 'Febrero' },
  { value: 3, label: 'Marzo' },
  { value: 4, label: 'Abril' },
  { value: 5, label: 'Mayo' },
  { value: 6, label: 'Junio' },
  { value: 7, label: 'Julio' },
  { value: 8, label: 'Agosto' },
  { value: 9, label: 'Septiembre' },
  { value: 10, label: 'Octubre' },
  { value: 11, label: 'Noviembre' },
  { value: 12, label: 'Diciembre' },
];

export const missingFieldRule = [
  { required: true, message: 'Campo obligatorio' },
];
