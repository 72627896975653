import { DeleteFilled } from '@ant-design/icons';
import { Button, Popover } from 'antd';

type TableDeletePopOverProps = {
  id: string;
  deleteHandler: (id: string) => void;
  disabled?: boolean;
  dataCy?: string;
};

export const TableDeletePopOver = ({
  deleteHandler,
  id,
  disabled,
  dataCy,
}: TableDeletePopOverProps) => {
  return (
    <div id="delete-button">
      <Popover
        content={
          <Button
            data-cy={`${dataCy}-confirm`}
            danger
            type="primary"
            onClick={() => {
              deleteHandler(id);
            }}
            disabled={disabled}
          >
            Confirmar
          </Button>
        }
        trigger="click"
        placement="left"
      >
        <Button data-cy={dataCy}>
          <DeleteFilled className="delete-icon" />
        </Button>
      </Popover>
    </div>
  );
};
