import { Tag, TimePicker } from 'antd';
import styled from 'styled-components';

export const WeekDay = styled.div`
  width: 100%;
  background-color: #cbcbcb;
  text-align: center;
`;

export const ScheduleTag = styled(Tag)`
  color: black !important;
  font-size: 15px;
`;

export const TimeSelector = styled(TimePicker)`
  width: 100%;
`;

export const AlertContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;
