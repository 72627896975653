import { PageTitle } from 'components/common/PageTitle/PageTitle';
import { UploadUsers } from 'components/employees/UploadUsers';

const UserUploadPage = () => {
  return (
    <>
      <PageTitle>{`Subir empleados`}</PageTitle>
      <UploadUsers />
    </>
  );
};

export default UserUploadPage;
