import dayjs from 'dayjs';
import { Calendar } from 'antd';
import { LittleMonthKardexCalendarProps } from 'interfaces/kardex';
import { useState } from 'react';
import 'styles/sass/kardex.scss';

const CalendarMonth = ({ setNewDate }: LittleMonthKardexCalendarProps) => {
  const [value, setValue] = useState(() => dayjs());

  return (
    <Calendar
      className="select-month-calendar"
      fullscreen={false}
      onChange={(date) => {
        setNewDate(new Date(date.toISOString()));
        setValue(date);
      }}
      value={value}
    />
  );
};

export default CalendarMonth;
