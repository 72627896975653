import { Col, Row } from 'antd';
import { PageTitle } from 'components/common/PageTitle/PageTitle';
import { FormDrawer } from 'components/common/Drawer/FormDrawer';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { doGroupsList, doCreateGroup } from 'store/slices/groupsSlice';
import { useState } from 'react';
import 'styles/sass/tables.scss';
import 'styles/sass/employees/employees-list.scss';
import { GroupsList } from 'components/groups/GroupsList';
import { groupFormItems } from 'constants/forms/groups';
import { SliceStatus } from 'constants/enums/slices';
import { LoadingCard } from 'components/common/LoadingCard';
import { ErrorResult } from 'components/common/ErrorResult';
import { Group } from 'types/groups';
import { useForm } from 'antd/es/form/Form';
import notificationHook from 'hooks/notificationHook';
import statusMessages from 'statusMessages';
import { ApiError } from 'types';

const GroupsPage = () => {
  const dispatch = useAppDispatch();
  const { listGroupStatus, groupStatus } = useAppSelector(
    (state) => state.group
  );
  const [openCreateDrawer, setOpenCreateDrawer] = useState(false);
  const [form] = useForm();

  if (listGroupStatus === SliceStatus.Empty) {
    dispatch(doGroupsList({ career: '', specialty: '' }));
  }

  const onNewGroup = async (values: Group) => {
    dispatch(doCreateGroup(values))
      .unwrap()
      .then(() => {
        form.resetFields();
        notificationHook({
          type: 'success',
          message: '¡Se ha creado el grupo con éxito!',
        });
      })
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          message: statusMessages.group[currentStatus ?? 500] as string,
          type: 'error',
        });
      })
      .finally(() => {
        setOpenCreateDrawer(false);
      });
  };

  return (
    <div id="groups-list">
      <PageTitle>Grupos</PageTitle>
      <Row align="middle" justify="space-between">
        <Col>
          <h2 className="title" data-cy="groups-title">
            Lista de grupos
          </h2>
        </Col>
        <Col>
          <FormDrawer<Group>
            open={openCreateDrawer}
            textButton="Crear grupo"
            title="Crear grupo"
            width={500}
            confirmText="Crear"
            cancelText="Cancelar"
            formMode="create"
            formItems={groupFormItems}
            loading={groupStatus === SliceStatus.Updating}
            onConfirm={onNewGroup}
            onOpen={() => {
              setOpenCreateDrawer(true);
            }}
            onClose={() => {
              setOpenCreateDrawer(false);
            }}
            form={form}
            dataCy="create-group"
          />
        </Col>
      </Row>
      {listGroupStatus === SliceStatus.Updating && <LoadingCard />}
      {listGroupStatus === SliceStatus.Fulfilled && <GroupsList />}
      {listGroupStatus === SliceStatus.Error && (
        <ErrorResult
          dataCy="groups-list-error"
          title="No se pudo obtener la lista de grupos"
        />
      )}
    </div>
  );
};

export default GroupsPage;
