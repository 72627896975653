import { useEffect } from 'react';
import { NewPasswordForm } from 'components/auth/NewPasswordForm/NewPasswordForm';
import { PageTitle } from 'components/common/PageTitle/PageTitle';
import { persistToken } from 'services/localStorage.service';
import { useLocation } from 'react-router-dom';
import { Alert } from 'antd';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { doValidateConfirmToken } from 'store/slices/authSlice';

const NewPasswordPage = () => {
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('t');
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (token) {
      persistToken(token);
      dispatch(doValidateConfirmToken());
    }
  }, [token, dispatch]);
  return (
    <>
      {token && user ? (
        <>
          <PageTitle>{`Nueva contraseña`}</PageTitle>
          <NewPasswordForm />
        </>
      ) : (
        <Alert message="Validando" type="info" />
      )}
    </>
  );
};

export default NewPasswordPage;
