import { PageTitle } from '../../components/common/PageTitle/PageTitle';
import { UserEdit } from '../../components/employees/UserEdit';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { ApiError, User } from './../../types';
import { useEffect, useState } from 'react';
import { doList as userDoList } from 'store/slices/userSlice';
import * as Sentry from '@sentry/react';
import { Button, Col, Result, Row, Skeleton } from 'antd';
import { SliceStatus } from 'constants/enums/slices';
import notificationHook from 'hooks/notificationHook';
import statusMessages from 'statusMessages';

const UserEditPage = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const { users, status: userStateStatus } = useAppSelector(
    (state) => state.user
  );
  const [employee, setEmployee] = useState<User | null>();
  const navigate = useNavigate();

  useEffect(() => {
    if (userStateStatus === SliceStatus.Empty) {
      dispatch(userDoList())
        .unwrap()
        .catch((error) => {
          const currentStatus = (error as ApiError).statusCode;
          notificationHook({
            type: 'error',
            message: statusMessages.user[
              currentStatus ? currentStatus : 500
            ] as string,
          });
        });
    } else if (userStateStatus === SliceStatus.Fulfilled && params.id) {
      const currentUser: User | undefined = users
        .filter((c) => c && c._id === params.id)
        .at(0);
      if (currentUser) {
        setEmployee(currentUser);
      } else {
        notificationHook({
          type: 'error',
          message: statusMessages.user[404] as string,
        });
        Sentry.captureMessage('Not able to find a employee to edit');
      }
    }
  }, [params, userStateStatus, dispatch, employee, users]);

  return (
    <>
      <PageTitle>{`Editar empleado`}</PageTitle>
      {userStateStatus === `updating` && (
        <Row>
          <Col xs={24}>
            <Skeleton active />
          </Col>
        </Row>
      )}
      {(userStateStatus === `fulfilled` && employee === null) ||
      userStateStatus === `error` ? (
        <Result
          status="error"
          title="Hubo un error al cargar al empleado"
          subTitle="Por favor vuelve a intentarlo más tarde."
          extra={[
            <Button type="primary" key="back" onClick={() => navigate(-1)}>
              Regresar
            </Button>,
            <Button key="reload" onClick={() => window.location.reload()}>
              Reintentar
            </Button>,
          ]}
        />
      ) : (
        <>
          {userStateStatus === `fulfilled` && employee && (
            <UserEdit user={employee} />
          )}
        </>
      )}
    </>
  );
};

export default UserEditPage;
