import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { Col, Row, Spin, Tooltip } from 'antd';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import CalendarMonth from './CalendarMonth';
import CalendarEventPopover from './CalendarEventPopover';
import 'styles/sass/kardex.scss';
import { WeeklyKardexCalendarProps } from 'interfaces/kardex';
import { getWeeklyKardexIncidences } from 'hooks/kardexHooks';
import { WeeklyKardexIncidence } from 'types/incidences';
import {
  LoadingOutlined,
  LeftCircleFilled,
  RightCircleFilled,
} from '@ant-design/icons';

dayjs.extend(timezone);

const localizer = dayjsLocalizer(dayjs);

export const KardexWeeklyCalendar = ({
  incidences,
  loading,
  date,
  setNewDate,
}: WeeklyKardexCalendarProps) => {
  const colorizeIncidence = (incidence: WeeklyKardexIncidence) => {
    const backgroundColor = incidence.color;
    const textColor = incidence.txtColor;
    const style = {
      backgroundColor,
      color: textColor,
    };
    return {
      style,
    };
  };

  const formats = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dayFormat: (date: Date, culture: any, localizer: any) =>
      `${localizer.format(date, `dddd D`, culture)}`,
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const messages: any = {
    previous: (
      <Tooltip
        rootClassName="ps-tooltip"
        title="Semana anterior"
        placement="bottom"
      >
        <LeftCircleFilled />
      </Tooltip>
    ),
    next: (
      <Tooltip
        rootClassName="ps-tooltip"
        title="Semana siguiente"
        placement="bottom"
      >
        <RightCircleFilled />
      </Tooltip>
    ),
    today: 'Hoy',
  };

  return (
    <Row id="weeklyCalendar" justify="center" gutter={[16, 16]}>
      <Col xs={24} lg={18}>
        <Spin
          spinning={loading}
          tip="Cargando..."
          indicator={<LoadingOutlined />}
        >
          <Calendar
            localizer={localizer}
            startAccessor="start"
            endAccessor="end"
            view="week"
            views={['week']}
            events={getWeeklyKardexIncidences(incidences)}
            eventPropGetter={colorizeIncidence}
            components={{
              event: CalendarEventPopover,
            }}
            tooltipAccessor={null}
            date={date}
            formats={formats}
            onNavigate={(newDate: Date) => {
              setNewDate(newDate);
            }}
            onView={() => {
              /* Para evitar a que salga un error en consola https://github.com/jquense/react-big-calendar/issues/818*/
            }}
            messages={messages}
          />
        </Spin>
      </Col>
      <Col lg={6}>
        <CalendarMonth setNewDate={(newDate) => setNewDate(newDate)} />
      </Col>
    </Row>
  );
};
export default KardexWeeklyCalendar;
