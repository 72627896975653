import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { Button, Card, Col, Row, Table, TableProps, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { getCurrentAwS } from 'api/devices.api';
import { useEffect, useState } from 'react';
import { ResponseQueueAWS } from 'types/devices';
import { dateForDisplay } from 'libs/dateFormaterPetition';
// import AddCommand from './AddCommand';

const CommandsList = () => {
  const [commands, setCommands] = useState<ResponseQueueAWS[]>([]);
  const [sortedData, setSortedData] = useState<ResponseQueueAWS[]>([]);
  const tableProps: TableProps<ResponseQueueAWS> = {
    bordered: true,
    pagination: {
      position: ['bottomCenter'],
      pageSize: 10,
      pageSizeOptions: [3, 10, 50, 100],
    },
    tableLayout: 'fixed',
    size: 'small',
    className: 'table-ps',
  };
  const UpdateAwsCommands = () => {
    getCurrentAwS().then((data) => {
      setCommands(data.data);
    });
  };
  useEffect(() => {
    setSortedData(
      [...commands].sort((a, b) => {
        const dateA = new Date(a.updatedAt);
        const dateB = new Date(b.updatedAt);
        return dateB.getTime() - dateA.getTime();
      })
    );
  }, [commands]);

  const columns: ColumnsType<ResponseQueueAWS> = [
    {
      title: 'Estado',
      key: 'statusCommands',
      dataIndex: 'status',
      align: 'center',
      width: 100,
      responsive: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
      render: (status) => (
        <>
          {status === 'successful' ? (
            <Tag color="green" title="Exitoso">
              <CheckCircleOutlined /> Exitoso
            </Tag>
          ) : status === 'error' ? (
            <Tag color="red" title="Error">
              <CloseCircleOutlined /> Error
            </Tag>
          ) : (
            <Tag color="darkgoldenrod" title="Pendiente">
              <InfoCircleOutlined />
              Pendiente
            </Tag>
          )}
        </>
      ),
    },
    {
      title: 'ID',
      key: 'cmdId',
      width: 90,
      dataIndex: 'cmdId',
    },
    {
      title: 'No. Serie del Dispositivo',
      key: 'serialNumberCommand',
      dataIndex: 'serialNumber',
      width: 200,
      responsive: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
      align: 'center',
    },
    {
      title: 'Comando',
      key: 'command',
      width: 500,
      responsive: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
      dataIndex: 'command',
      align: 'center',
    },
    {
      title: 'Fila',
      key: 'dataCommand',
      width: 200,
      dataIndex: 'queueStatus',
      responsive: ['xl', 'xxl'],
      align: 'center',
      render: (queueStatus) => <>{queueStatus ?? '-'}</>,
    },
    {
      title: 'Última Actualizacion',
      key: 'updatedAt',
      responsive: ['lg', 'xl', 'xxl'],
      dataIndex: 'updatedAt',
      align: 'center',
      width: 200,
      render: (data) => <>{dateForDisplay(data) ?? '-'}</>,
    },
  ];

  return (
    <>
      <Card
        title={
          <Row justify="space-between" align="middle">
            <Col>
              <h3 className="sub-title">Comandos</h3>
            </Col>
            <Col>
              <Button
                type="default"
                className="outlined-icon"
                icon={<ReloadOutlined />}
                onClick={UpdateAwsCommands}
                title="Actualizar"
              />
            </Col>
          </Row>
        }
        bordered
        size="small"
      >
        <Table
          {...tableProps}
          columns={columns}
          dataSource={sortedData}
          id="commandsTable"
          rowKey={'_id'}
        />
      </Card>
    </>
  );
};

export default CommandsList;
