import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createCycle, editCycle, getAllCycles, remove } from 'api/cycles.api';
import { SliceStatus } from 'constants/enums/slices';
import { Cycle } from 'types/cycle';

export interface CycleSlide {
  cycles: Cycle[];
  cycleStatus: SliceStatus;
  listCycleStatus: SliceStatus;
}

const initialState: CycleSlide = {
  cycles: [],
  cycleStatus: SliceStatus.Empty,
  listCycleStatus: SliceStatus.Empty,
};

export const doCyclesList = createAsyncThunk(
  'cycles/list',
  async (_, { rejectWithValue }) => {
    try {
      return await getAllCycles();
    } catch (error: unknown) {
      return await rejectWithValue(error);
    }
  }
);

export const doCreateCycle = createAsyncThunk(
  'cycle/create',
  async (CycleCreatePayload: Cycle, { rejectWithValue }) => {
    try {
      await createCycle(CycleCreatePayload);
      return await getAllCycles();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const doEditCycle = createAsyncThunk(
  'cycle/edit',
  async (CycleEditPayload: Cycle, { rejectWithValue }) => {
    try {
      await editCycle(CycleEditPayload);
      return await getAllCycles();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const doCyclesRemove = createAsyncThunk(
  'cycles/remove',
  async (id: string, { rejectWithValue }) => {
    try {
      await remove(id);
      return await getAllCycles();
    } catch (error: unknown) {
      return await rejectWithValue(error);
    }
  }
);

const cycleSlice = createSlice({
  name: 'cycles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doCyclesList.pending, (state) => {
      state.listCycleStatus = SliceStatus.Updating;
    });
    builder.addCase(doCyclesList.fulfilled, (state, action) => {
      state.cycles = action.payload.data;
      state.listCycleStatus = SliceStatus.Fulfilled;
    });
    builder.addCase(doCyclesList.rejected, (state) => {
      state.listCycleStatus = SliceStatus.Error;
    });
    builder.addCase(doCreateCycle.pending, (state) => {
      state.cycleStatus = SliceStatus.Updating;
    });
    builder.addCase(doCreateCycle.fulfilled, (state, action) => {
      state.cycles = action.payload.data;
      state.cycleStatus = SliceStatus.Fulfilled;
    });
    builder.addCase(doCreateCycle.rejected, (state) => {
      state.cycleStatus = SliceStatus.Error;
    });
    builder.addCase(doEditCycle.pending, (state) => {
      state.cycleStatus = SliceStatus.Updating;
    });
    builder.addCase(doEditCycle.fulfilled, (state, action) => {
      state.cycles = action.payload.data;
      state.cycleStatus = SliceStatus.Fulfilled;
    });
    builder.addCase(doEditCycle.rejected, (state) => {
      state.cycleStatus = SliceStatus.Error;
    });
    builder.addCase(doCyclesRemove.pending, (state) => {
      state.cycleStatus = SliceStatus.Updating;
    });
    builder.addCase(doCyclesRemove.fulfilled, (state, action) => {
      state.cycles = action.payload.data;
      state.cycleStatus = SliceStatus.Fulfilled;
    });
    builder.addCase(doCyclesRemove.rejected, (state) => {
      state.cycleStatus = SliceStatus.Error;
    });
  },
});

export default cycleSlice.reducer;
