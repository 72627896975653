import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, DatePicker, Form, Row, Select, Spin } from 'antd';
import type { FormInstance } from 'antd/es/form';
import { ApiError, User } from '../../types';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { doList } from 'store/slices/userSlice';
import { Incidence, IncidenceCategory } from 'types/incidences';
import { createOne, getCategories } from 'api/incidence.api';
import { reportPaths } from 'constants/routePaths';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import * as Sentry from '@sentry/react';
import notificationHook from 'hooks/notificationHook';
import statusMessages from 'statusMessages';
import { SliceStatus } from 'constants/enums/slices';
import { BackButton } from 'components/common/BackButton/BackButton';

const { RangePicker } = DatePicker;

export const IncidenceNewForm = () => {
  const dispatch = useAppDispatch();
  const { users, status: UsersListStatus } = useAppSelector(
    (state) => state.user
  );
  const navigate = useNavigate();
  const formRef = React.useRef<FormInstance>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getCategories()
      .then((res) => {
        setCategoryList(res.data);
        setLoading(false);
      })
      .catch(() => {
        setCategoryList(null);
        setLoading(false);
      });
  }, []);

  const [categoryList, setCategoryList] = useState<IncidenceCategory[] | null>(
    []
  );

  if (UsersListStatus === SliceStatus.Empty) {
    dispatch(doList());
  }

  const onFinish = (values: Incidence & { dates: dayjs.Dayjs[] }) => {
    setLoading(true);
    const actualValues = {
      ...values,
      startDate: values.dates[0].toDate(),
      endDate: values.dates[1].toDate(),
      dates: undefined,
    };
    createOne(actualValues)
      .then(() => {
        notificationHook({
          type: 'success',
          message: 'Incidencia creada con éxito !',
        });
        setLoading(false);
        navigate(`${reportPaths.basePath}/${reportPaths.incidences}`);
      })
      .catch((error: unknown) => {
        Sentry.captureException(error);
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          message: statusMessages.incidence[
            currentStatus ? currentStatus : 500
          ] as string,
          type: 'error',
        });
        setLoading(false);
      });
  };

  return (
    <>
      <h2 className="title">Crear incidencia</h2>
      <BackButton />

      <Spin spinning={loading}>
        <div id="new-employee-container">
          <Row justify="center">
            <Col xs={10}>
              <Form
                ref={formRef}
                onFinish={onFinish}
                autoComplete="off"
                labelCol={{ span: 9 }}
              >
                <Form.Item
                  name="employee"
                  label="Empleado"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor seleccione un empleado',
                      type: 'string',
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Seleccionar empleado:"
                    id="user"
                  >
                    {users && users.length > 0 ? (
                      <>
                        {users.map((user: User, key: number) => {
                          if (user.status) {
                            return (
                              <Select.Option
                                key={'user_' + key}
                                value={user._id}
                                id={'user_' + key}
                              >
                                {user.name} {user.lastName}
                              </Select.Option>
                            );
                          }
                        })}
                      </>
                    ) : (
                      <>
                        <Select.Option value={null}>-</Select.Option>
                      </>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="dates"
                  label="Fecha de la incidencia:"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese la fecha',
                    },
                  ]}
                  hasFeedback
                >
                  <RangePicker
                    id={{
                      start: 'startDateIncidence',
                      end: 'endDateIncidence',
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="category"
                  label="Categoría de la incidencia:"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingrese la categoria.',
                      type: 'string',
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Seleccionar categoria:"
                    id="category"
                  >
                    {categoryList &&
                      categoryList.map(
                        (category: IncidenceCategory, index: number) => (
                          <Select.Option
                            value={category._id}
                            key={`reason_${index}`}
                          >
                            {`${category.abbr} - ${category.desc}`}
                          </Select.Option>
                        )
                      )}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="approver"
                  label="Aprobado por"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor seleccione un empleado',
                      type: 'string',
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    placeholder="Seleccionar empleado:"
                    id="approver"
                  >
                    {users && users.length > 0 ? (
                      <>
                        {users.map((user: User, key: number) => (
                          <Select.Option
                            key={'usera_' + key}
                            value={user._id}
                            id={'usera_' + key}
                          >
                            {user.name} {user.lastName}
                          </Select.Option>
                        ))}
                      </>
                    ) : (
                      <>
                        <Select.Option value={null}>-</Select.Option>
                      </>
                    )}
                  </Select>
                </Form.Item>
                <Form.Item name="notes" label="Notas:" hasFeedback>
                  <TextArea />
                </Form.Item>
                <Form.Item>
                  <Row justify="end" gutter={10}>
                    <Col>
                      <Button
                        onClick={() =>
                          navigate(
                            `${reportPaths.basePath}/${reportPaths.incidences}`
                          )
                        }
                        id="cancelBtn"
                      >
                        Regresar
                      </Button>
                    </Col>
                    <Col>
                      <Button htmlType="submit" id="saveBtn" type="primary">
                        Guardar
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      </Spin>
    </>
  );
};
