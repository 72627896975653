import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseForm } from 'components/common/forms/BaseForm/BaseForm';
import notificationHook from 'hooks/notificationHook';
import { useAppDispatch } from 'hooks/reduxHooks';
import { doSetNewPassword } from 'store/slices/authSlice';
import * as S from './NewPasswordForm.styles';
import * as Auth from 'components/layouts/AuthLayout/AuthLayout.styles';
import { RuleObject } from 'antd/es/form';
import { NamePath, StoreValue } from 'antd/es/form/interface';
import { authPaths } from 'constants/routePaths';
import { ApiError } from 'types';
import statusMessages from 'statusMessages';

interface NewPasswordFormData {
  password: string;
  confirmPassword: string;
}

const initStates = {
  password: '',
  confirmPassword: '',
};

export const NewPasswordForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = (values: NewPasswordFormData) => {
    setLoading(true);
    dispatch(doSetNewPassword({ newPassword: values.password }))
      .unwrap()
      .then(() => {
        navigate(`${authPaths.basePath}/${authPaths.login}`);
        notificationHook({
          type: 'success',
          message: `La contraseña ha sido actualizada`,
          description: `Ingresa con tu nueva contraseña`,
        });
      })
      .catch((error) => {
        const currentStatus = (error as ApiError).statusCode;
        notificationHook({
          type: 'error',
          message: statusMessages.auth[
            currentStatus ? currentStatus : 500
          ] as string,
        });
        setLoading(false);
      });
  };

  return (
    <div className="form-wrapper">
      <BaseForm
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark="optional"
        initialValues={initStates}
      >
        <h1 className="title">Recuperar contraseña</h1>
        <S.Description>Ingresa una nueva contraseña</S.Description>
        <Auth.FormItem
          name="password"
          label={`Contraseña`}
          rules={[
            { required: true, message: `Nueva contraseña` },
            {
              min: 6,
              message: 'La contraseña debe tener al menos 6 caracteres',
            },
          ]}
        >
          <Auth.FormInputPassword placeholder={`Contraseña`} />
        </Auth.FormItem>
        <Auth.FormItem
          name="confirmPassword"
          label={`Confirmar contraseña`}
          dependencies={['password']}
          rules={[
            { required: true, message: `Campo requerido` },
            {
              min: 6,
              message: 'La contraseña debe tener al menos 6 caracteres',
            },
            ({
              getFieldValue,
            }: {
              getFieldValue: (name: NamePath) => StoreValue;
            }) => ({
              validator(_: RuleObject, value: string) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(`Las Contraseñas no coinciden`)
                );
              },
            }),
          ]}
          hasFeedback
        >
          <Auth.FormInputPassword placeholder={`Confirmar contraseña`} />
        </Auth.FormItem>
        <BaseForm.Item noStyle>
          <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {`Resetear contraseña`}
          </S.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </div>
  );
};
