import { Cycle } from 'types/cycle';

/**
 * format dates from dayjs to ISO string and creates
 * payload object to create/edit a cycle
 * @param   values  All form values of type Cycle
 * @returns Payload objet to create or edit a cycle
 */
export const generateCyclePayload = async (values: Cycle) => {
  const { dates, ...restValues } = values;
  if (dates) {
    return {
      ...restValues,
      initialDate: dates[0].toISOString(),
      finalDate: dates[1].toISOString(),
    };
  }
  return values;
};
