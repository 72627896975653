import { LoadingOutlined } from '@ant-design/icons';
import { Card, Col, Row, Spin } from 'antd';
import React from 'react';

export const LoadingCard = () => {
  return (
    <div id="loading-card">
      <Spin tip="Cargando..." indicator={<LoadingOutlined />}>
        <Row>
          <Col xs={24}>
            <Card style={{ height: '200px' }} />
          </Col>
        </Row>
      </Spin>
    </div>
  );
};
